import React from 'react';
import './user_list.scss';

function UserItem(props) {
  return (
    <li
      className={`user-list__user${props.hideImage ? '--rounded' : ''}${props.className ? ' ' + props.className : ''}`}
      onClick={(event) => {
        if(props.onClick) {
          props.onClick(event);
        }
      }}
    >

      {!props.hideImage ?
        <div className="user-list__user__picture-container">

          <i className="fas fa-user user-list__user__picture-icon"></i>

        </div>:
        <div className="user-list__user__spacer"></div>
      }

      <p className="user-list__user__name">

        {props.name}

      </p>

      {props.children}

    </li>
  );
}

export {UserItem};

function UserList(props) {
  return (
    <ul className={`user-list ${props.className}`}>

      {props.children}

    </ul>
  );
}

export default UserList;
