import React from 'react';
import { PoseGroup } from 'react-pose';
import './switch_unit.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE, LOCAL_CACHE_DEVICE_IDENTIFIER_KEY} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import UserList, {UserItem} from '../components/user_list';
import {postModel} from '../utils/functions';
import ConfirmationWindow from '../components/confirmation_window';

class SwitchUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      unitToSelect: null,
      unitToRemove: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getUnitOptions() {
    let localCache = localStorage.getItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY);

    if(!localCache) {
      return (
        <p className="switch-unit__not-found-text">Nenhuma unidade vinculada encontrada</p>
      );
    }

    return JSON.parse(localCache).map((entry) => (
      <UserItem
        key={`unit:${entry[0]}`}
        className={`switch-unit__unit-option${this.props.token.unit.id === entry[0] ? '--selected' : ''}`}
        onClick={(event) => this.setState({unitToSelect: this.props.token.unit.id === entry[0] ? null : entry[1]})}
        hideImage={true}
        name={`${entry[1].unit.name}${this.props.token.unit.id === entry[0] ? ' (SELECIONADO)' : ''}`}
      >

        <DefaultMenuButton
          className="switch-unit__user-action-button"
          onClick={(event) => {
            event.stopPropagation();
            this.setState({unitToRemove: entry[1]});
          }}
          text={<i className="fas fa-trash-alt switch-unit__user-action-button__icon"></i>}
          color="red"
        />

      </UserItem>
    ));
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.unitToSelect) {
        return 'Falha ao alterar o vínculo';
      }
      else if(this.state.unitToRemove) {
        return 'Falha ao remover unidade vinculada';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.unitToSelect) {
        return 'Alterando vínculo';
      }
      else if(this.state.unitToRemove) {
        return 'Removendo vínculo';
      }
    }
    else {
      if(this.state.unitToSelect) {
        return 'Alterar vínculo';
      }
      else if(this.state.unitToRemove) {
        return 'Remover vínculo';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.unitToSelect) {
      return 'Deseja realmente alterar a unidade vinculada com a lousa?';
    }
    else if(this.state.unitToRemove) {
      return 'Deseja realmente remover o vínculo da unidade salvo? Para acessar a lousa desta unidade será necessário realizar um novo acesso.';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.unitToSelect) {
      return 'Alterar';
    }
    else if(this.state.unitToRemove) {
      return 'Remover';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.unitToSelect !== null ||
           this.state.unitToRemove !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      unitToSelect: null,
      unitToRemove: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.unitToSelect) {
      this.setState({
        confirmInProgress: true
      });

      const data = {identifier: this.state.unitToSelect.identifier};

      try{
        let response = await postModel(routes.SWITCH_DEVICE_UNIT_POST_API, data, true);

        if(response) {
          window.location.reload();

          this.setState({
            unitToSelect: null,
            confirmInProgress: false
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.unitToRemove) {
      this.setState({
        confirmInProgress: true
      });

      const data = {identifier: this.state.unitToRemove.identifier};

      try{
        let response = await postModel(routes.REMOVE_DEVICE_IDENTIFIER_POST_API, data, true);

        if(response) {
          let localCache = localStorage.getItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY);

          if(localCache) {
            localCache = new Map(JSON.parse(localCache));

            localCache.delete(this.state.unitToRemove.unit.id);

            localStorage.setItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY, JSON.stringify([...localCache.entries()]));
          }

          window.location.reload();

          this.setState({
            unitToRemove: null,
            confirmInProgress: false
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="switch-unit">

            <React.Fragment>

              <h1 className="switch-unit__instructions">

                Selecione a unidade desejada:

              </h1>

              <UserList className="switch-unit__unit-list">

                {this.getUnitOptions()}

              </UserList>

            </React.Fragment>

          </div>

        </DefaultMenuLayout>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </React.Fragment>
    );
  }
}

export default SwitchUnit;
