import React from 'react';
import { PoseGroup } from 'react-pose';
import './start_class.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import DefaultFilterInput from '../components/default_filter_input';
import UserList, {UserItem} from '../components/user_list';
import {getModels, getModel, postModel, getAsLocalDatetime, setUrlParameters} from '../utils/functions';

function getDayId(date) {
  let dayId = date.getDay() - 1;

  if(dayId < 0) {
    dayId = 6;
  }

  return dayId;
}

class StartClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      coaches: [],
      trainings: [],
      training_times: [],
      current_class: null,
      coachNameFilter: "",
      selectedCoaches: [],
      showCoachSelection: true,
      selectedTraining: null,
      selectedClassTime: null,
      selectedStatus: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainingTimes() {
    const today = new Date();

    const parameters = {};

    if(this.props.target_service) {
      parameters.target_service = this.props.target_service;
    }

    return await getModels(setUrlParameters(`${routes.TRAINING_TIMES_GET_API}${getDayId(today)}`, parameters));
  }

  async getCoaches() {
    return await getModels(routes.COACHES_GET_API);
  }

  async getTrainings() {
    if(this.props.isGymService) {
      return [];
    }

    return await getModels(`${routes.TRAINING_CALENDAR_GET_API}?target_service=${this.props.target_service}`);
  }

  async getCurrentTrainngClass() {
    return await getModel(`${routes.CURRENT_TRAINING_CLASS_GET_API}${this.props.target_service}`);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let coaches = this.getCoaches();
    let training_times = this.getTrainingTimes();
    let trainings = this.getTrainings();
    let current_class = this.getCurrentTrainngClass();

    coaches = await coaches;

    if(coaches) {
      update.coaches = coaches;
    }

    training_times = await training_times;

    if(training_times) {
      update.training_times = training_times;
    }

    trainings = await trainings;

    if(trainings) {
      update.trainings = trainings;

      if(update.trainings.length === 1) {
        update.selectedTraining = update.trainings[0];
      }
    }

    current_class = await current_class;

    if(current_class) {
      update.current_class = current_class;
      update.showCoachSelection = false;
      update.selectedCoaches = update.coaches.filter((coach) => current_class.coaches.some((entry) => entry.id === coach.id));
      if(!update.selectedCoaches || update.selectedCoaches.length <= 0) {
        update.selectedCoaches = [{
          id: 0,
          name: "Indisponível"
        }];
      }
      update.selectedTraining = update.trainings.find((training) => training.training_day_id === current_class.training_day_id);
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  onAddCoach(coach) {
    const selectedCoaches = [...this.state.selectedCoaches];
    selectedCoaches.push(coach);

    this.setState({
      selectedCoaches,
      showCoachSelection: false
    });
  }

  onShowCoachSelection() {
    this.setState({showCoachSelection: true});
  }

  getCoachItems() {
    const filteredCoaches = this.state.coaches.filter((coach) => coach.name.toLocaleLowerCase().includes(this.state.coachNameFilter.toLocaleLowerCase()));
    const selectedIds = this.state.selectedCoaches.map((entry) => entry.id);

    if(filteredCoaches.length <= 0) {
      return (
        <p className="start-class__not-found-text">Nenhum professor encontrado que contenha este nome</p>
      );
    }

    return filteredCoaches.map((coach) => (
      <UserItem
        key={`coach:${coach.id}`}
        name={coach.name}
      >

        {selectedIds.includes(coach.id) ? (
          <DefaultMenuButton
            className="start-class__user-action-button"
            text="Selecionado"
            color="blue"
            disabled={true}
          />
        ) : (
          <DefaultMenuButton
            className="start-class__user-action-button"
            onClick={() => this.onAddCoach(coach)}
            text="Selecionar"
            color="blue"
          />
        )}

      </UserItem>
    ));
  }

  getTrainingItems() {
    return this.state.trainings.map((training) => (
      <UserItem
        key={`training_day:${training.training_day_id}`}
        name={`${training.period_name} - ${training.training_day_name} (Fase ${training.repetition_index})`}
        hideImage={true}
      >

        <DefaultMenuButton
          className="start-class__user-action-button"
          onClick={() => this.setState({selectedTraining: training})}
          text="Selecionar"
          color="blue"
        />

      </UserItem>
    ));
  }

  getTrainingTimeOptions() {
    const now = new Date();

    now.setMinutes(now.getMinutes() - 40);

    const timeFormat = new Intl.DateTimeFormat('pt-BR', {hour: '2-digit', minute: '2-digit'});

    const minTime = timeFormat.format(now);

    now.setMinutes(now.getMinutes() + 100);

    const maxTime = timeFormat.format(now);

    const times = this.state.training_times.filter((entry) => entry.target_service === this.props.target_service && entry.time >= minTime && entry.time <= maxTime).map((entry) => (
      <UserItem
        key={`training_time:${entry.id}`}
        name={entry.time}
        hideImage={true}
      >

        <DefaultMenuButton
          className="start-class__user-action-button"
          onClick={() => this.setState({selectedClassTime: entry})}
          text="Selecionar"
          color="blue"
        />

      </UserItem>
    ));

    times.push(
      <UserItem
        key={`training_time:no_time`}
        name="Não vinculado"
        hideImage={true}
      >

        <DefaultMenuButton
          className="start-class__user-action-button"
          onClick={() => this.setState({selectedClassTime: false})}
          text="Selecionar"
          color="blue"
        />

      </UserItem>
    );

    return times
  }

  async createClass() {
    if((!this.state.selectedCoaches || this.state.selectedCoaches.length <= 0) || (!this.state.selectedTraining && !this.props.isGymService)) {
      return;
    }

    this.setState({
      loading: true
    });

    let training_day_id = null;
    let repetition_index = 1;

    if(!this.props.isGymService) {
      training_day_id = this.state.selectedTraining.training_day_id;
      repetition_index = this.state.selectedTraining.repetition_index;
    }

    const data = {
      coach_ids: this.state.selectedCoaches.map((entry) => entry.id),
      training_day_id,
      repetition_index,
      target_service: this.props.target_service,
      class_time: this.state.selectedClassTime !== false ? this.state.selectedClassTime.time : null,
    };

    try {
      await postModel(routes.CURRENT_TRAINING_CLASS_POST_API, data);
    }
    catch(errors) {
      // let warningMessages = [];
      // let highlights = [];

      // if(errors instanceof Array) {
      //   for(let error of errors) {
      //     if(error.code === 103) {
      //       for(let parameter of error.parameters) {
      //         if(parameter.name === 'email') {
      //           warningMessages.push('E-mail já cadastrado');
      //           highlights.push('email');
      //         }
      //       }
      //     }
      //   }
      // }

      // this.setState({
      //   highlights: highlights,
      //   showWarningMessage: true,
      //   warningMessage: `${warningMessages.join('; ')}.`,
      //   loading: false
      // });

      return;
    }

    this.props.history.replace(routes.CURRENT_CLASS_PATH);
  }

  getDatetimeText(isoDatetime) {
    const date = getAsLocalDatetime(isoDatetime, false);

    const dateFormat = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      weekday: 'long'
    };

    let dateText = '';

    // if(this.state.screenWidth > 515) {
    //   dateFormat.weekday = 'short';
    // }

    // if(this.state.screenWidth > 580) {
    //   dateFormat.weekday = 'long';
    // }

    dateText = new Intl.DateTimeFormat('pt-BR', dateFormat).format(date);

    return dateText;
  }

  getSelectedCoachNames() {
    if (this.state.selectedCoaches.length <= 0) {
      return 'Nenhum selecionado';
    }

    let names = '';

    if (this.state.selectedCoaches.length > 1) {
      names = this.state.selectedCoaches.slice(0, this.state.selectedCoaches.length - 1).map((entry) => entry.name).join(', ');
      names += ' e '
    }

    names += this.state.selectedCoaches[this.state.selectedCoaches.length - 1].name;

    return names;
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        bluetoothDevices={this.props.bluetoothDevices}
      >

        <div className="start-class">

          {(this.state.showCoachSelection || this.state.selectedCoaches.length <= 0) ?
            (
              <React.Fragment>

                <h1 className="start-class__instructions">

                  {this.state.selectedCoaches.length > 0 ? 'Selecione outro professor resposável pela aula:' : 'Selecione o professor resposável pela aula:'}

                </h1>

                <div className="start-class__user-select-container">

                  <DefaultFilterInput
                    name="name"
                    label={this.state.screenWidth > 510 ? 'Filtrar professor:' : 'Filtrar:'}
                    placeholder="Nome do professor"
                    autoComplete="off"
                    value={this.state.coachNameFilter}
                    handleInputChange={(event) => this.setState({coachNameFilter: event.target.value})}
                  />

                </div>

                <UserList className="start-class__coach-list">

                  {this.getCoachItems()}

                </UserList>

              </React.Fragment>
            ):
            (
              <React.Fragment>

                <h2 className="start-class__selected-coach">

                  <span className="start-class__selected-coach__label">
                    <u>{this.state.screenWidth > 680 ? 'Professor responsável' : 'Professor'}</u>:
                  </span>

                  {this.getSelectedCoachNames()}

                </h2>

                {this.state.current_class === null &&
                  <DefaultMenuButton
                    className="start-class__add-couach-button"
                    onClick={() => this.onShowCoachSelection()}
                    text={(
                      <React.Fragment>

                        <i className="fas fa-plus start-class__add-couach-button__icon"></i> Adicionar responsável

                      </React.Fragment>
                    )}
                    color="purple"
                  />
                }

                {(!this.props.isGymService && this.state.selectedTraining === null) && this.state.current_class === null ?
                  (
                    <React.Fragment>

                      <hr className="start-class__horizontal-rule"/>

                      <h1 className="start-class__instructions">

                        Selecione o treino desejado:

                      </h1>

                      <UserList className="start-class__training-list">

                        {this.getTrainingItems()}

                      </UserList>

                    </React.Fragment>
                  ):
                  (
                    <React.Fragment>

                      {this.state.current_class === null ?
                        (
                          <React.Fragment>

                            {!this.props.isGymService &&
                              <h2 className="start-class__selected-training">

                                <span className="start-class__selected-training__label">
                                  <u>{this.state.screenWidth > 680 ? 'Treino selecionado' : 'Treino'}</u>:
                                </span>

                                {`${this.state.selectedTraining.period_name} - ${this.state.selectedTraining.training_day_name} (Fase ${this.state.selectedTraining.repetition_index})`}

                              </h2>
                            }

                            {this.state.selectedClassTime === null ?
                              <React.Fragment>

                                <hr className="start-class__horizontal-rule"/>

                                <h1 className="start-class__instructions">

                                  Selecione o horário do treino:

                                </h1>

                                <UserList className="start-class__class-time-list">

                                  {this.getTrainingTimeOptions()}

                                </UserList>

                              </React.Fragment>:
                                <React.Fragment>

                                  {this.state.selectedClassTime !== false ?
                                    <h2 className="start-class__selected-time">

                                      <span className="start-class__selected-time__label">
                                        <u>{this.state.screenWidth > 680 ? 'Horário da aula' : 'Hora'}</u>:
                                      </span>

                                      {this.state.selectedClassTime.time}

                                    </h2>:
                                    null
                                  }

                                  <hr className="start-class__horizontal-rule"/>

                                  <div className="start-class__confirm-container">

                                    <DefaultMenuButton
                                      className="start-class__confirm-button"
                                      onClick={() => this.createClass()}
                                      text="Iniciar aula"
                                      color="green"
                                    />

                                  </div>

                                </React.Fragment>
                            }

                          </React.Fragment>
                        ):
                        <React.Fragment>

                          {!this.props.isGymService &&
                            <h2 className="start-class__selected-training">

                              <span className="start-class__selected-training__label">
                                <u>Nome do treino</u>:
                              </span>

                              {`${this.state.current_class.training_day.name} (Fase ${this.state.current_class.repetition_index})`}

                            </h2>
                          }

                          <h2 className="start-class__selected-training">

                            <span className="start-class__selected-training__label">
                              <u>{this.state.current_class.class_time ? 'Hora da aula' : 'Horário de início'}</u>:
                            </span>

                            {this.state.current_class.class_time || this.getDatetimeText(this.state.current_class.started_at)}

                          </h2>

                          <hr className="start-class__horizontal-rule"/>

                          <div className="start-class__confirm-container">

                            <DefaultMenuButton
                              className="start-class__confirm-button"
                              linkTo={routes.CURRENT_CLASS_PATH}
                              text="Resumir aula"
                              color="blue"
                              replace={true}
                            />

                          </div>
                        </React.Fragment>
                      }

                    </React.Fragment>
                  )
                }

              </React.Fragment>
            )
          }

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default StartClass;
