import React from 'react';
import { PoseGroup } from 'react-pose';
import './early_checkin.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModels} from '../utils/functions';

const NORMAL_TAB = 'normal';
const EXP_TAB = 'experimental';

class EarlyCheckin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabSelected: NORMAL_TAB,
      checkins: [],
      experimental_classes: [],
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getEarlyCheckins() {
    let params = new URLSearchParams(this.props.location.search);

    const service = params.get('service');

    if(service) {
      return await getModels(`${routes.DAILY_EARLY_CHECKIN_STUDENTS_GET_API}${service}`);
    }

    return await getModels(`${routes.DAILY_EARLY_CHECKIN_STUDENTS_GET_API}${this.props.target_service}`);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let checkins = this.getEarlyCheckins();

    checkins = await checkins;

    if(checkins) {
      update.checkins = checkins.count;
      update.experimental_classes = checkins.experimental_classes;

      this.setState(update);

      this.resizeListener = () => this.updateSize();

      window.addEventListener("resize", this.resizeListener);
    }
    else {
      this.props.history.replace(routes.MAIN_PATH);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getList() {
    if(this.state.tabSelected === NORMAL_TAB) {
      if(this.state.checkins.length <= 0) {
        return (<p className="early-checkin__no-entry-text">Nenhum horário cadastrado para o dia</p>);
      }

      return this.state.checkins.map((entry) => (
        <div
          key={`early_checkin:entry:${entry.time}`}
          className="early-checkin__time-entry"
        >

          <h4 className="early-checkin__time-entry__time">
            {entry.time}
          </h4>

          <p className="early-checkin__time-entry__count">
            {entry.count}
          </p>

        </div>
      ));
    }
    else if(this.state.tabSelected === EXP_TAB) {
      if(this.state.experimental_classes.length <= 0) {
        return (<p className="early-checkin__no-entry-text">Nenhuma aula experimental cadastrada para o dia</p>);
      }

      return this.state.experimental_classes.map((entry) => (
        <div
          key={`experimental_classes:entry:${entry.id}`}
          className="early-checkin__experimental-entry"
        >

          <div className="early-checkin__experimental-entry__label-container">

            <h4 className="early-checkin__experimental-entry__name">
              {entry.name}
            </h4>

            <a
              className="early-checkin__experimental-entry__link"
              href={`${window.location.protocol}//${window.location.host.replace('lousa.', '').replace(':3000', ':5000')}${entry.feedback_path}?name=${entry.name}&email=${entry.email}&phone=${entry.phone.replace(/\D/g,"")}&target_service=${entry.target_service}`}
              target="_blank"
              rel="noopener noreferrer"
            >

              <i className="fas fa-link"></i>

            </a>

          </div>

          <p className="early-checkin__experimental-entry__hour">
            {entry.hour}
          </p>

        </div>
      ));
    }

    return [];
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        bluetoothDevices={this.props.bluetoothDevices}
      >

        <div className="early-checkin">

          <nav className="early-checkin__tab-selector">

            <button
              className="early-checkin__tab-selector__button"
              onClick={() => this.setState({tabSelected: NORMAL_TAB})}
              disabled={this.state.tabSelected === NORMAL_TAB}
            >

              Agendamentos

            </button>

            <button
              className="early-checkin__tab-selector__button"
              onClick={() => this.setState({tabSelected: EXP_TAB})}
              disabled={this.state.tabSelected === EXP_TAB}
            >

              Aulas experimentais

            </button>

          </nav>

          <div className="early-checkin__tab-content">

            <div className="early-checkin__list-container">

              {this.getList()}

            </div>

          </div>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default EarlyCheckin;
