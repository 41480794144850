import React from 'react';
import { Link } from 'react-router-dom';
import './default_menu_button.scss';

function DefaultMenuButton(props) {
  return props.linkTo ?
    (
      <Link
        className={`default-menu-button--${props.color || 'black'} ${props.className}`}
        to={props.linkTo}
        target={props.target}
        rel={props.rel}
        replace={props.replace}
      >

        <p className="default-menu-button__outset-text">
          {props.text}
        </p>

      </Link>
    ):
    (
      <button
        className={`default-menu-button--${props.color || 'black'} ${props.className}`}
        onClick={(event) => props.onClick(event)}
        disabled={props.disabled}
      >

        <p className="default-menu-button__outset-text">
          {props.text}
        </p>

      </button>
    );
}

export default DefaultMenuButton;
