import React from 'react';
import {DEFAULT_ERROR_MESSAGE, STATE_API, SIGN_OUT_API} from '../constants'

async function getStateToken(forceReload=false) {
  let cache = 'default';

  if(forceReload) {
    cache = 'reload';
  }

  try {
    let response = await fetch(STATE_API, {cache: cache, credentials: 'same-origin'});

    if(!response.ok) {
      throw await response.json();
    }

    let data = await response.json();

    return data.state;
  }
  catch(error) {
    if(error.errors) {
      throw error.errors;
    }
    else if(error.code) {
      const errors = [error];
      throw errors;
    }
    else {
      console.log(DEFAULT_ERROR_MESSAGE);
    }

    return null;
  }
}

export {getStateToken};

async function signOut() {
  let stateToken = await getStateToken();

  if(stateToken) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'state': stateToken
    });

    try {
      let response = await fetch(SIGN_OUT_API, {
        method: "POST",
        headers: headers,
        cache: 'no-store',
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw Error(DEFAULT_ERROR_MESSAGE);
      }

      return true;
    }
    catch(error) {
      console.log(error);
    }
  }

  return null;
}

export {signOut};

function getAsLocalDatetime(isoString, appendUtcTimezone=true) {
  return new Date(isoString + (appendUtcTimezone ? 'Z' : ''));
}

function getAsLocalDate(isoDateString) {
  const date = new Date(
    parseInt(isoDateString.slice(0, 4)),
    parseInt(isoDateString.slice(5, 7)) - 1,
    isoDateString.slice(8, 10)
  );

  return date;
}

function getAsLocalDateString(isoDateString) {
  return getAsLocalDate(isoDateString).toLocaleDateString();
}

function getLocalDateIsoString(date) {
  return `${date.getFullYear().toLocaleString('pt-BR', {useGrouping: false, minimumIntegerDigits: 4})}-${(date.getMonth()+1).toLocaleString('pt-BR', {minimumIntegerDigits: 2})}-${date.getDate().toLocaleString('pt-BR', {minimumIntegerDigits: 2})}`;
}

function getLocalTimeIsoString(date, includeSeconds=true) {
  const time = `${(date.getHours()).toLocaleString('pt-BR', {minimumIntegerDigits: 2})}:${date.getMinutes().toLocaleString('pt-BR', {minimumIntegerDigits: 2})}`;

  if(includeSeconds) {
    return time + `:${(date.getSeconds()).toLocaleString('pt-BR', {minimumIntegerDigits: 2})}`;
  }

  return time;
}

export {getAsLocalDatetime, getAsLocalDate, getAsLocalDateString, getLocalDateIsoString, getLocalTimeIsoString};

async function deleteModel(path) {
  let stateToken = await getStateToken(true);

  if(stateToken) {
    const headers = new Headers({
      'state': stateToken
    });

    try {
      let response = await fetch(path, {
        method: "DELETE",
        headers: headers,
        cache: 'no-store',
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {deleteModel};

async function postModel(path, data={}, getResponse = false) {
  let stateToken = await getStateToken(true);

  if(stateToken) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'state': stateToken
    });

    const body = JSON.stringify(data);

    try {
      let response = await fetch(path, {
        method: "POST",
        headers: headers,
        cache: 'no-store',
        body: body,
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      if(getResponse) {
        return await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {postModel};

async function patchModel(path, data, getResponse = false) {
  let stateToken = await getStateToken(true);

  if(stateToken) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'state': stateToken
    });

    const body = JSON.stringify(data);

    try {
      let response = await fetch(path, {
        method: "PATCH",
        headers: headers,
        cache: 'no-store',
        body: body,
        credentials: 'same-origin'
      });

      if(!response.ok) {
        throw await response.json();
      }

      if(getResponse) {
        return await response.json();
      }

      return true;
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {patchModel};

async function getModel(path) {
  let stateToken = await getStateToken(true);

  if(stateToken) {
    const headers = new Headers({
      'state': stateToken
    });

    try {
      let response = await fetch(path, {
        method: "GET",
        headers: headers,
        cache: 'no-store',
        credentials: 'same-origin'
      });

      if(!response.ok) {
        if(response.status === 401) {
          window.location.reload();
          return null;
        }

        throw await response.json();
      }

      return await response.json();
    }
    catch(error) {
      if(error.errors) {
        throw error.errors;
      }

      if(error.code) {
        const errors = [error];
        throw errors;
      }

      if(error instanceof TypeError) {
        return;
      }

      console.log(error);
    }
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {getModel};

async function getModels(path) {
  try {
    let response = await fetch(path, {
      method: "GET",
      cache: 'no-store',
      credentials: 'same-origin'
    });

    if(!response.ok) {
      if(response.status === 401) {
        window.location.reload();
        return [];
      }

      throw await response.json();
    }

    return await response.json();
  }
  catch(error) {
    if(error.errors) {
      throw error.errors;
    }

    if(error.code) {
      const errors = [error];
      throw errors;
    }

    console.log(error);
  }

  window.alert(DEFAULT_ERROR_MESSAGE);
  throw Error(DEFAULT_ERROR_MESSAGE);
}

export {getModels};

function setInputCursor(target, position) {
  if (target.setSelectionRange) {
    target.setSelectionRange(position, position);
  }
  else if (target.createTextRange) {
    const range = target.createTextRange();
    range.collapse(true);
    range.moveEnd('character', position);
    range.moveStart('character', position);
    range.select();
  }
}

export {setInputCursor};

function getPhoneText(number) {
  let phone = number.replace(/\D/g,"");

  if(phone.length > 2) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2)}`;
  }
  else if(phone.length > 0) {
    return '(' + phone;
  }

  return phone;
}

export {getPhoneText};

function getCurrencyText(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export {getCurrencyText};

function getCpfText(number) {
  let cpf = number.replace(/\D/g,"");

  cpf = number.replace(/(\d{3})(\d)/,"$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2");

  return cpf;
}

export {getCpfText};

function getRgText(number) {
  let rg = number.replace(/\D/g,"");

  rg = number.replace(/(\d{2})(\d)/,"$1.$2");
  rg = rg.replace(/(\d{3})(\d)/,"$1.$2");
  rg = rg.replace(/(\d{3})(\d+)$/,"$1-$2");

  return rg;
}

export {getRgText};

function getCepText(number) {
  let cep = number.replace(/\D/g,"");

  cep = cep.replace(/(\d{5})(\d+)/,"$1-$2");

  return cep;
}

export {getCepText};

function getAbbreviatedName(name) {
  if(!name || name.length <= 0) {
    return '';
  }

  const nameParts = name.replace(/ +(?= )/g,'').split(' ');

  let finalName = '';

  for (let i=0; i < nameParts.length; ++i) {
    if (i === 0) {
      finalName = nameParts[0];
    }
    else if (i === nameParts.length - 1 || nameParts[i][0] !== nameParts[i][0].toLocaleUpperCase()) {
      finalName += ` ${nameParts[i]}`
    }
    else {
      finalName += ` ${nameParts[i][0]}.`;
    }
  }

  return finalName;
}

export {getAbbreviatedName};

function setUrlParameters(url, parameters) {
  let finalUrl = url;
  let parameterList = [];

  for (const [key, value] of Object.entries(parameters)) {
    if(value) {
      parameterList.push(`${key}=${encodeURIComponent(`${value}`)}`);
    }
  }

  if(parameterList.length > 0) {
    finalUrl += `?${parameterList.join('&')}`;
  }

  return finalUrl;
}

export {setUrlParameters};

function parseTextForIcons(text, identifier, className, ignoreColor=false) {
  if(!text || typeof text !== 'string') {
    return text;
  }

  const iconRe = /\{(?<icon>[a-z1-9_-]+)\|*(?<color>[a-z_-]*)\}/g;

  const output = [];
  let currentPosition = 0;

  text.replace(iconRe, (match, icon, color, offset) => {
    const style = {}

    if (!ignoreColor) {
      if(color === 'green') {
        style.color = '#68b94e';
      }
      else if(color === 'red') {
        style.color = '#ff532c';
      }
      else if(color === 'blue') {
        style.color = '#2b80ff';
      }
      else if(color === 'black') {
        style.color = '#383838';
      }
    }

    if(currentPosition < offset) {
      output.push(
        <span
          key={`${identifier}:${output.length+1}`}
          className={className}
        >

          {text.slice(currentPosition, offset)}

        </span>
      );
    }

    let iconClass;

    switch (icon) {
      case 'up':
        iconClass = 'fas fa-arrow-alt-circle-up'
        break;
      case 'left':
        iconClass = 'fas fa-arrow-alt-circle-left'
        break;
      case 'right':
        iconClass = 'fas fa-arrow-alt-circle-right'
        break;
      case 'down':
        iconClass = 'fas fa-arrow-alt-circle-down'
        break;
      case 'stop':
        iconClass = 'fas fa-stop'
        break;
      case 'stop-circle':
        iconClass = 'far fa-stop-circle'
        break;
      case 'hand':
        iconClass = 'fas fa-hand-paper'
        break;
      case 'pse-icon-1':
        iconClass = 'fas fa-kiss-beam'
        break;
      case 'pse-icon-2':
        iconClass = 'fas fa-grin'
        break;
      case 'pse-icon-3':
        iconClass = 'fas fa-meh'
        break;
      case 'pse-icon-4':
        iconClass = 'fas fa-frown-open'
        break;
      case 'pse-icon-5':
        iconClass = 'fas fa-tired'
        break;
      default:
        iconClass = null;
    }

    if (iconClass !== null) {
      output.push(
        <i
          key={`${identifier}:${output.length+1}`}
          className={`${iconClass} ${className}__icon`}
          style={style}
        ></i>
      );
    }
    else {
      output.push(
        <span
          key={`${identifier}:${output.length+1}`}
          className={className}
        >

          UNDEFINED_ICON

        </span>
      );
    }

    currentPosition = offset + match.length;

    return match;
  });

  if(currentPosition < text.length) {
    output.push(
      <span
        key={`${identifier}:${output.length+1}`}
        className={className}
      >

        {text.slice(currentPosition, text.length)}

      </span>
    );
  }

  return output;
}

export {parseTextForIcons};
