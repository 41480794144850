import React from 'react';
import { PoseGroup } from 'react-pose';
import './last_class.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        DIFFUCULTY_OPTION_EASY,
        DIFFUCULTY_OPTION_NORMAL,
        DIFFUCULTY_OPTION_INTERMEDIATE,
        DIFFUCULTY_OPTION_ADVANCED,
        SERVICE_GYM,
        SERVICE_BIKE,
        SERVICE_PILATES} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModel, patchModel, postModel, getAsLocalDate} from '../utils/functions';
import DefaultInput, {SelectOption} from '../utils/default_input';
import ConfirmationWindow from '../components/confirmation_window';
import OverlayWindow from '../components/overlay_window';


class LastClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      last_class: null,
      dataEntries: [],
      originalDataEntries: [],
      prExerciseAssociations: [],
      onSaveDataEntry: null,
      onUpdateStudentReference: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      studentOverlayShowInputs: false,
      studentOverlaySelected: null,
      studentOverlayReferenceData: null,
      studentOverlayOriginalReferenceData: null,
      supportTableVisible: false,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getLastTrainngClass() {
    if(this.props.useClassId) {
      return await getModel(`${routes.TRAINING_CLASS_GET_API}${this.props.match.params.trainingClassId}`);
    }

    return await getModel(`${routes.LAST_TRAINING_CLASS_GET_API}${this.props.target_service}`);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let last_class = this.getLastTrainngClass();

    last_class = await last_class;

    if(last_class) {
      update.last_class = last_class;
      update.dataEntries = last_class.data_entries.map((entry) => ({...entry}));
      update.originalDataEntries = last_class.data_entries.map((entry) => ({...entry}));

      if(last_class.training_day !== null) {
        const exercise_associations = last_class.training_day.group_associations.flatMap((group) => group.exercise_associations);
        update.prExerciseAssociations = exercise_associations.filter((association) => association.difficult_name === 'PR');
      }

      this.setState(update);

      this.resizeListener = () => this.updateSize();

      window.addEventListener("resize", this.resizeListener);
    }
    else {
      this.props.history.replace(routes.MAIN_PATH);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleDataChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const entriesCopy = this.state.dataEntries.map((entry) => ({...entry}));

    if(name.startsWith('training_data:')) {
      const selection = name.split(':');
      const dataId = parseInt(selection[1]);
      const dataName = selection[2];

      if(!value) {
        value = null;
      }

      const entry = entriesCopy.find((entry) => entry.id === dataId);

      if(dataName === 'minutes') {
        const totalSeconds = entry.total_seconds || 0;

        if(value !== null) {
          value = (parseInt(value)*60) + (totalSeconds % 60);
        }

        entry.total_seconds = value;
      }
      else if(dataName === 'seconds') {
        const totalSeconds = entry.total_seconds || 0;

        if(value !== null) {
          value = parseInt(value) + (Math.floor(totalSeconds / 60)*60);
        }

        entry.total_seconds = value;
      }
      else if(dataName === 'total_repetitions') {
        entry.total_repetitions = parseInt(value);
      }
      else if(dataName === 'difficulty') {
        entry.difficulty = value;
      }
      else if(dataName === 'total_weight') {
        entry.total_weight = parseFloat(value);
      }
    }

    this.setState({
      dataEntries: entriesCopy
    });
  }

  onSetPse(dataId, value) {
    const entriesCopy = this.state.dataEntries.map((entry) => ({...entry}));

    const entry = entriesCopy.find((entry) => entry.id === dataId);

    entry.pse = value;

    this.setState({
      dataEntries: entriesCopy
    });
  }

  getDifficultyOptions() {
    const options = [
      DIFFUCULTY_OPTION_EASY,
      DIFFUCULTY_OPTION_NORMAL,
      DIFFUCULTY_OPTION_INTERMEDIATE,
      DIFFUCULTY_OPTION_ADVANCED,
    ];

    return [
      SelectOption('', 'Selecione uma dificuldade'),
      ...options.map((option) => SelectOption(option, option))
    ];
  }

  hasChanges(entryId) {
    for(let i=0; i < this.state.dataEntries.length; i++) {
      if(entryId === this.state.dataEntries[i].id) {
        if(this.state.dataEntries[i].total_seconds !== this.state.originalDataEntries[i].total_seconds ||
           this.state.dataEntries[i].total_repetitions !== this.state.originalDataEntries[i].total_repetitions ||
           this.state.dataEntries[i].difficulty !== this.state.originalDataEntries[i].difficulty ||
           this.state.dataEntries[i].total_weight !== this.state.originalDataEntries[i].total_weight ||
           this.state.dataEntries[i].pse !== this.state.originalDataEntries[i].pse) {
          return true;
        }
      }
    }

    return false;
  }

  accessTrainingDayReport(student_id, training_day_id) {
    this.props.history.push(`${routes.STUDENT_BASE_PATH}${student_id}${routes.STUDENT_TRAINING_DAY_REPORT}${training_day_id}`);
  }

  getInputs(entry) {
    let minutes = '';
    let seconds = '';

    if(entry.total_seconds) {
      minutes = Math.floor(entry.total_seconds / 60);
      seconds = entry.total_seconds % 60;
    }

    switch (this.props.target_service) {
      case SERVICE_BIKE:
      case SERVICE_PILATES:
        return null;
      case SERVICE_GYM:
        return (
          <React.Fragment>

            {/* <div className="last-class__student__input-wrapper">

              <h4 className="last-class__student__input-label">Dificuldade:</h4>

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:difficulty`}
                type="select"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={entry.difficulty || ''}
                options={this.getDifficultyOptions()}
                isHorizontal={this.state.screenWidth > 580}
              />

            </div> */}

            <div className="last-class__student__input-wrapper">

              <h4 className="last-class__student__input-label">Kilagem média total:</h4>

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:total_weight`}
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={entry.total_weight || ''}
                autoComplete="off"
                suffix="kg"
                onFocus={(event) => event.target.select()}
              />

            </div>

          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>

            <div className="last-class__student__input-wrapper">

              <h4 className="last-class__student__input-label">Dificuldade:</h4>

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:difficulty`}
                type="select"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={entry.difficulty || ''}
                options={this.getDifficultyOptions()}
                isHorizontal={this.state.screenWidth > 580}
              />

            </div>

            <div className="last-class__student__input-wrapper">

              <h4 className="last-class__student__input-label">Tempo:</h4>

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:minutes`}
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={minutes}
                autoComplete="off"
                suffix={this.state.screenWidth > 680 ? 'minutos' : 'min'}
                onFocus={(event) => event.target.select()}
              />

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:seconds`}
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={seconds}
                autoComplete="off"
                suffix={this.state.screenWidth > 680 ? 'segundos' : 'seg'}
                onFocus={(event) => event.target.select()}
              />

            </div>

            <div className="last-class__student__input-wrapper">

              <h4 className="last-class__student__input-label">Repetições:</h4>

              <DefaultInput
                className="last-class__student__input"
                name={`training_data:${entry.id}:total_repetitions`}
                type="number"
                placeholder="-"
                min="0"
                step="1"
                handleInputChange={(event) => this.handleDataChange(event)}
                value={entry.total_repetitions || ''}
                autoComplete="off"
                suffix="reps"
                onFocus={(event) => event.target.select()}
              />
            </div>

          </React.Fragment>
        );
    }
  }

  getStudentList() {
    if(this.state.dataEntries.length <= 0) {
      return (
        <p className="last-class__no-student-text">Aula sem presença de alunos</p>
      );
    }

    return this.state.dataEntries.map((entry) => {
      return (
        <li
          key={`last_class:student:${entry.user_id}`}
          className="last-class__student"
        >

          <div className="last-class__student__picture-container">

            <i className="fas fa-user last-class__student__picture-icon"></i>

          </div>

          <div className="last-class__student__main-container">

            <p className="last-class__student__name">

              {entry.username}

            </p>

            <div className="last-class__student__data-inputs">

              {this.getInputs(entry)}

              {entry.energy_expended !== null &&
                <div className="last-class__student__input-wrapper">

                  <h4 className="last-class__student__input-label--red">Gasto calórico:</h4>

                  <DefaultInput
                    className="last-class__student__input"
                    name={`training_data:${entry.id}:energy_expended`}
                    type="number"
                    placeholder="-"
                    // handleInputChange={(event) => this.handleDataChange(event)}
                    value={entry.energy_expended.toFixed(0)}
                    autoComplete="off"
                    suffix="kcal"
                    disabled
                  />
                </div>
              }

              <div className="last-class__student__input-wrapper--vertical">

                <h4 className="last-class__student__input-label">Percepção Subjetiva de Esforço (PSE):</h4>

                <div className="last-class__overlay-note">

                  <p className="last-class__overlay-note__text">

                      Idealmente a <strong>PSE</strong> deve ser preenchida cerca de <strong>20-30 minutos</strong> após
                      o fim da atividade física. Lembre-se que ela pode ser preenchida também pela <strong>interface do aluno</strong>.

                  </p>

                </div>

                <ul className="last-class__student__scale">

                  <li className="last-class__student__scale__group">

                    <div className="last-class__student__scale__group__image--2">

                      <i className="fas fa-kiss-beam last-class__student__scale__group__image-icon"></i>

                    </div>

                    <div className="last-class__student__scale__group__options">

                      <button
                        className="last-class__student__scale__group__option--1"
                        onClick={() => this.onSetPse(entry.id, 1)}
                        disabled={entry.pse === 1}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          1
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Muito leve
                        </p>

                      </button>

                      <button
                        className="last-class__student__scale__group__option--2"
                        onClick={() => this.onSetPse(entry.id, 2)}
                        disabled={entry.pse === 2}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          2
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Leve
                        </p>

                      </button>

                    </div>

                  </li>

                  <li className="last-class__student__scale__group">

                    <div className="last-class__student__scale__group__image--4">

                      <i className="fas fa-grin last-class__student__scale__group__image-icon"></i>

                    </div>

                    <div className="last-class__student__scale__group__options">

                      <button
                        className="last-class__student__scale__group__option--3"
                        onClick={() => this.onSetPse(entry.id, 3)}
                        disabled={entry.pse === 3}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          3
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Tranquilo
                        </p>

                      </button>

                      <button
                        className="last-class__student__scale__group__option--4"
                        onClick={() => this.onSetPse(entry.id, 4)}
                        disabled={entry.pse === 4}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          4
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Moderado
                        </p>

                      </button>

                    </div>

                  </li>

                  <li className="last-class__student__scale__group">

                    <div className="last-class__student__scale__group__image--6">

                      <i className="fas fa-meh last-class__student__scale__group__image-icon"></i>

                    </div>

                    <div className="last-class__student__scale__group__options">

                      <button
                        className="last-class__student__scale__group__option--5"
                        onClick={() => this.onSetPse(entry.id, 5)}
                        disabled={entry.pse === 5}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          5
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Requer esforço
                        </p>

                      </button>

                      <button
                        className="last-class__student__scale__group__option--6"
                        onClick={() => this.onSetPse(entry.id, 6)}
                        disabled={entry.pse === 6}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          6
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Pouco pesado
                        </p>

                      </button>

                    </div>

                  </li>

                  <li className="last-class__student__scale__group">

                    <div className="last-class__student__scale__group__image--8">

                      <i className="fas fa-frown-open last-class__student__scale__group__image-icon"></i>

                    </div>

                    <div className="last-class__student__scale__group__options">

                      <button
                        className="last-class__student__scale__group__option--7"
                        onClick={() => this.onSetPse(entry.id, 7)}
                        disabled={entry.pse === 7}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          7
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Pesado
                        </p>

                      </button>

                      <button
                        className="last-class__student__scale__group__option--8"
                        onClick={() => this.onSetPse(entry.id, 8)}
                        disabled={entry.pse === 8}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          8
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Muito pesado
                        </p>

                      </button>

                    </div>

                  </li>

                  <li className="last-class__student__scale__group">

                    <div className="last-class__student__scale__group__image--10">

                      <i className="fas fa-tired last-class__student__scale__group__image-icon"></i>

                    </div>

                    <div className="last-class__student__scale__group__options">

                      <button
                        className="last-class__student__scale__group__option--9"
                        onClick={() => this.onSetPse(entry.id, 9)}
                        disabled={entry.pse === 9}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          9
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Extremamente pesado
                        </p>

                      </button>

                      <button
                        className="last-class__student__scale__group__option--10"
                        onClick={() => this.onSetPse(entry.id, 10)}
                        disabled={entry.pse === 10}
                      >

                        <p className="last-class__student__scale__group__option-number">
                          10
                        </p>

                        <p className="last-class__student__scale__group__option-text--flex">
                          Quase impossível
                        </p>

                      </button>

                    </div>

                  </li>

                </ul>

                <p className="last-class__small-note">

                  *A PSE é uma estimativa para quantificar o esforço físico necessário para realizar a atividade
                  proposta e está diretamente relacionada com o cansaço físico persistente depois de sua execução.

                </p>

              </div>

            </div>

          </div>

          {this.state.prExerciseAssociations.length > 0 &&
            <DefaultMenuButton
              className="last-class__student__action-button"
              onClick={() => this.onSelectOverlayStudent(entry)}
              text={(<i className="fas fa-info"></i>)}
            />
          }

          <DefaultMenuButton
            className="last-class__student__action-button"
            onClick={() => this.accessTrainingDayReport(entry.user_id, entry.training_day_id)}
            text={(<i className="fas fa-chart-bar"></i>)}
          />

          {this.hasChanges(entry.id) &&
            <DefaultMenuButton
              className="last-class__student__action-button"
              onClick={() => this.setState({onSaveDataEntry: entry})}
              text="Salvar"
              color="green"
            />
          }

        </li>
      );
    });
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onSaveDataEntry !== null) {
        return 'Falha ao salvar alterações';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Falha ao salvar dados de PR de aluno';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onSaveDataEntry !== null) {
        return 'Salvando alterações';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Salvando dados';
      }
    }
    else {
      if(this.state.onSaveDataEntry !== null) {
        return 'Salvar alterações';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Salvar dados de PR';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onSaveDataEntry !== null) {
      return `Deseja realmente salvar as alterações do resultado do treino para o aluno ${this.state.onSaveDataEntry.username}?`;
    }
    else if(this.state.onUpdateStudentReference) {
      return `Deseja realmente atualizar os dados de PR do aluno?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onSaveDataEntry !== null) {
      return 'Salvar';
    }
    else if(this.state.onUpdateStudentReference) {
      return `Salvar dados`;
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onUpdateStudentReference === true ||
           this.state.onSaveDataEntry !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      onSaveDataEntry: null,
      onUpdateStudentReference: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onSaveDataEntry !== null) {
      this.setState({
        confirmInProgress: true
      });

      const data = {
        difficulty: this.state.onSaveDataEntry.difficulty || null,
        pse: parseInt(this.state.onSaveDataEntry.pse),
        total_repetitions: parseInt(this.state.onSaveDataEntry.total_repetitions),
        total_seconds: parseFloat(this.state.onSaveDataEntry.total_seconds),
        total_weight: parseFloat(this.state.onSaveDataEntry.total_weight),
      };

      try{
        if(await patchModel(`${routes.TRAINING_DATA_PATCH_API}${this.state.onSaveDataEntry.id}`, data)) {
          const originalEntriesCopy = this.state.originalDataEntries.map((entry) => ({...entry}));

          const originalEntry = originalEntriesCopy.find((entry) => entry.id === this.state.onSaveDataEntry.id);

          originalEntry.difficulty = this.state.onSaveDataEntry.difficulty;
          originalEntry.pse = this.state.onSaveDataEntry.pse;
          originalEntry.total_repetitions = this.state.onSaveDataEntry.total_repetitions;
          originalEntry.total_seconds = this.state.onSaveDataEntry.total_seconds;
          originalEntry.total_weight = this.state.onSaveDataEntry.total_weight;

          this.setState({
            originalDataEntries: originalEntriesCopy,
            onSaveDataEntry: null,
            confirmFailed: false,
            confirmInProgress: false,
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onUpdateStudentReference) {
      this.setState({
        confirmInProgress: true
      });

      const data = {reference_data: []};

      const referenceCopy = {...this.state.studentOverlayReferenceData};
      const todayIso = (new Date()).toISOString().slice(0, 10);

      for(let association of this.state.prExerciseAssociations) {
        referenceCopy[association.exercise_id].updated_at = todayIso;

        data.reference_data.push({
          exercise_id: association.exercise_id,
          value: parseFloat(referenceCopy[association.exercise_id].value),
          weight: parseFloat(referenceCopy[association.exercise_id].weight),
          repetition: parseInt(referenceCopy[association.exercise_id].repetition),
        });
      }

      try{
        if(await postModel(`${routes.STUDENT_BASE_API}${this.state.studentOverlaySelected.user_id}${routes.STUDENT_EXERCISE_REFERENCE_POST_API}`, data)) {
          const entriesCopy = this.state.dataEntries.map((entry) => ({...entry}));
          const entry = entriesCopy.find((entry) => entry.id === this.state.studentOverlaySelected.id);

          entry.exercise_reference = {...referenceCopy};

          this.setState({
            onUpdateStudentReference: false,
            confirmInProgress: false,
            studentOverlayOriginalReferenceData: {...referenceCopy},
            dataEntries: entriesCopy
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    return;
  }

  getStudentOverlayActions() {
    let addSaveAction = false;

    if(this.state.studentOverlayReferenceData !== null && this.state.studentOverlayOriginalReferenceData !== null) {
      for(let association of this.state.prExerciseAssociations) {
        if(this.state.studentOverlayReferenceData[association.exercise_id].value !== this.state.studentOverlayOriginalReferenceData[association.exercise_id].value) {
          addSaveAction = true;
        }
      }
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="last-class__overlay__action-button"
          onClick={() => this.setState({
            studentOverlaySelected: null,
            studentOverlayReferenceData: null,
            studentOverlayOriginalReferenceData: null,
          })}
          text="Fechar"
          disabled={this.state.onUpdateStudentReference}
        />

        {addSaveAction &&
          <DefaultMenuButton
            className="last-class__overlay__action-button"
            onClick={() => this.setState({onUpdateStudentReference: true})}
            text="Salvar"
            color="green"
            disabled={this.state.onUpdateStudentReference}
          />
        }

      </React.Fragment>
    );
  }

  getPrPercentage(physicalRecuperationStatus, currentPrPercentage) {
    return Math.round(currentPrPercentage - (10*(10 - physicalRecuperationStatus)/3));
  }

  handleReferenceChange(event) {
    if(this.state.last_class.training_day === null) {
      return;
    }

    const target = event.target;
    let value = target.value;
    const name = target.name;

    const referenceCopy = {...this.state.studentOverlayReferenceData};

    const exercise_associations = this.state.last_class.training_day.group_associations.flatMap((group) => group.exercise_associations);

    if(name.startsWith('exercise_reference:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);

      const association = exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      if(!value) {
        value = null;
      }
      else {
        const prPercentage = this.getPrPercentage(this.state.studentOverlaySelected.physical_recuperation_status, association.difficult_value[this.state.last_class.repetition_index - 1]);

        value = 100 * parseFloat(value) / prPercentage;
      }

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id].value = value;
    }
    else if(name.startsWith('exercise_reference_input:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);
      const parameterName = selection[2];

      const association = exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id][parameterName] = value || null;

      if(referenceCopy[exercise_id].weight && referenceCopy[exercise_id].repetition) {
        referenceCopy[exercise_id].value = (referenceCopy[exercise_id].weight * 100) / (102.78 - (2.78 * referenceCopy[exercise_id].repetition));
      }
    }

    this.setState({
      studentOverlayReferenceData: referenceCopy
    });
  }

  getStudentOverlayData() {
    if(this.state.studentOverlaySelected === null || this.state.studentOverlayReferenceData === null) {
      return null;
    }

    return this.state.prExerciseAssociations.map((association) => {
      const prValue = this.state.studentOverlayReferenceData[association.exercise_id].value;

      const normalMaxPercentage = association.difficult_value[this.state.last_class.repetition_index - 1];
      let maxPercentage = normalMaxPercentage;
      let minPercentage = normalMaxPercentage - 30;

      const prPercentage = this.getPrPercentage(this.state.studentOverlaySelected.physical_recuperation_status, normalMaxPercentage);

      if(association.difficult_intermediate_value) {
        const intermediateMaxPercentage = association.difficult_intermediate_value[this.state.last_class.repetition_index - 1];

        if(intermediateMaxPercentage > 0) {
          maxPercentage = Math.max(intermediateMaxPercentage, maxPercentage);
          minPercentage = Math.min(intermediateMaxPercentage - 30, minPercentage);
        }
      }

      if(association.difficult_advanced_value) {
        const advancedMaxPercentage = association.difficult_advanced_value[this.state.last_class.repetition_index - 1];

        if(advancedMaxPercentage > 0) {
          maxPercentage = Math.max(advancedMaxPercentage, maxPercentage);
          minPercentage = Math.min(advancedMaxPercentage - 30, minPercentage);
        }
      }

      let prText = '';

      // if(prValue) {
      //   prText = prValue*prPercentage*0.01;
      //
      //   prText *= 1000;
      //   prText = Math.round(prText);
      //   prText = prText / 1000;
      // }

      const percentageGraduation = [];

      minPercentage = Math.max(minPercentage, 0);

      const step = Math.ceil((maxPercentage - minPercentage) / 6);
      minPercentage = maxPercentage - (6*step);

      const percentageReference = (Math.floor(((prPercentage - minPercentage) / step))*step) + minPercentage;

      if(!this.state.studentOverlayShowInputs) {
        for(let percentage=maxPercentage; percentage >= minPercentage; percentage -= step) {
          if(percentage < 0) {
            break;
          }

          if(prValue) {
            prText = prValue*percentage*0.01;

            prText *= 10;
            prText = Math.round(prText);
            prText = prText / 10;
          }

          percentageGraduation.push(
            <div
              className={`last-class__overlay__reference__input-container${percentage === percentageReference ? '--selected' : ''}`}
              key={`last_class:exercise_reference:${association.id}:pr_output:${percentage}`}
            >

              <p className="last-class__overlay__reference__pr-input-header">
                <span className="last-class__overlay__reference__input-prefix--highlighted">{percentage.toFixed(0)}%</span>
              </p>

              <DefaultInput
                className="last-class__overlay__reference__input"
                name={`exercise_reference:${association.id}`}
                type="number"
                placeholder="-"
                min="0"
                step="0.1"
                handleInputChange={(event) => this.handleReferenceChange(event)}
                value={prText}
                autoComplete="off"
                suffix="kg"
                // prefix={(
                //   <p className="last-class__overlay__reference__input-prefix">
                //
                //     <span className="last-class__overlay__reference__input-prefix--highlighted">{prPercentage.toFixed(0)}%</span>
                //     {this.state.screenWidth > 510 ? ' do PR' : ''}
                //
                //   </p>
                // )}
                onFocus={(event) => event.target.select()}
                disabled={true}
              />

              {/* {prValue ?
                <p className="last-class__overlay__reference__generic-text">
                  (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)
                </p>:
                null
              } */}

            </div>
          );
        }
      }

      let updatedAtText = null;

      if(this.state.studentOverlayReferenceData[association.exercise_id].updated_at) {
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
        const updatedAt = getAsLocalDate(this.state.studentOverlayReferenceData[association.exercise_id].updated_at.slice(0, 10));

        const timeDiff = Math.abs(today.getTime() - updatedAt.getTime());
        let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

        updatedAtText = `Salvo em ${updatedAt.toLocaleDateString()} (${daysCount} dia(s))`;
      }

      return (
        <div
          key={`student:${this.state.studentOverlaySelected.id}:exercise_reference:${association.id}`}
          className="last-class__overlay__reference"
        >

          {updatedAtText !== null &&
            <p className="last-class__overlay__reference__date-text">

              {updatedAtText}

            </p>
          }

          <div className="last-class__overlay__reference__wrapper">

            <h4 className="last-class__overlay__reference__label">{association.exercise_name}:</h4>

            {!this.state.studentOverlayShowInputs ?
              <div className="class-board__overlay__reference__percentages-wrapper">

                {percentageGraduation}

              </div>:
              <div className="last-class__overlay__reference__input-container--horizontal">

                <div className="last-class__overlay__reference__pr-input-wrapper">

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:weight`}
                    className="last-class__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:weight`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="0.1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].weight || ''}
                    autoComplete="off"
                    suffix="kg"
                    prefix="Peso"
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:repetition`}
                    className="last-class__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:repetition`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].repetition || ''}
                    autoComplete="off"
                    prefix="Repetições"
                    onFocus={(event) => event.target.select()}
                  />

                </div>

                {(this.state.studentOverlayReferenceData[association.exercise_id].repetition && this.state.studentOverlayReferenceData[association.exercise_id].weight) &&
                  <React.Fragment>

                    <i className="fas fa-long-arrow-alt-right last-class__overlay__reference__arrow-icon"></i>

                    <p className="last-class__overlay__reference__pr-text">

                      (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)

                    </p>

                  </React.Fragment>
                }

              </div>
            }

          </div>

        </div>
      );
    });
  }

  onSelectOverlayStudent(entry) {
    const exercise_ids = this.state.prExerciseAssociations.map((association) => association.exercise_id);

    const studentOverlayReferenceData = {};

    for(let id of exercise_ids) {
      studentOverlayReferenceData[id] = entry.exercise_reference[id];
    }

    this.setState({
      studentOverlayReferenceData,
      studentOverlaySelected: entry,
      studentOverlayShowInputs: false,
      studentOverlayOriginalReferenceData: {...studentOverlayReferenceData},
    });
  }

  hasSupportTable() {
    if(!this.state.last_class || !this.state.last_class.training_day) {
      return false;
    }

    return this.state.last_class.training_day.group_associations.some((association) => association.has_support_table);
  }

  getSupportTables() {
    const filteredAssociations = this.state.last_class.training_day.group_associations.filter((association) => association.has_support_table);

    return filteredAssociations.map((association) => {
      const rows = association.support_table.map((row, index) => (
        <div
          className="last-class__support-table__table__row-wrapper"
          key={`training_day_group_association:${association.id}:support_table:row:${index}`}
        >

          <p className="last-class__support-table__table__cell">
            {row[0]}
          </p>

          <p className="last-class__support-table__table__cell">
            {row[1]}
          </p>

        </div>
      ));

      return (
        <div
          className="last-class__support-table__table"
          key={`training_day_group_association:${association.id}:support_table`}
        >

          <div className="last-class__support-table__table__header">

            <h3 className="last-class__support-table__table__title">
              {association.name}
            </h3>

          </div>

          <div className="last-class__support-table__table__row-wrapper">

            <p className="last-class__support-table__table__column-label">
              Nº Série
            </p>

            <p className="last-class__support-table__table__column-label">
              Repetições
            </p>

          </div>

          {rows}

        </div>
      );
    });
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={!this.props.useClassId}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="last-class">

            <ul className="last-class__student-list">

              {this.getStudentList()}

            </ul>

          </div>

        </DefaultMenuLayout>

        {this.hasSupportTable() &&
          <aside className={`last-class__support-table${this.state.supportTableVisible ? '--visible' : ''}`}>

            <div className="last-class__support-table__controls">

              <DefaultMenuButton
                className="last-class__support-table__toggle-button"
                onClick={() => this.setState({supportTableVisible: !this.state.supportTableVisible})}
                text={(<i className="fas fa-chevron-down"></i>)}
                color="red"
              />

            </div>

            <header className="last-class__support-table__header">

              <div className="last-class__support-table__header__title-wrapper">

                <h2 className="last-class__support-table__header__title">

                  Tabela auxiliar

                </h2>

              </div>

            </header>

            <div className="last-class__support-table__content">

              {this.getSupportTables()}

            </div>

          </aside>
        }

        <OverlayWindow
          className="last-class__overlay"
          visible={this.state.studentOverlaySelected !== null}
          loading={false}
          actions={(
            <div className="last-class__overlay__action-container">

              {this.getStudentOverlayActions()}

            </div>
          )}
        >

          <header className="last-class__overlay__header">

            <h3 className="last-class__overlay__header__title">
              {this.state.studentOverlayShowInputs ? 'Definição de PR' : 'Referências de peso'}
            </h3>

            <DefaultMenuButton
              className="last-class__overlay__header__toggle-button"
              onClick={() => this.setState({
                studentOverlayShowInputs: !this.state.studentOverlayShowInputs
              })}
              text={(<i className="fas fa-exchange-alt"></i>)}
            />

          </header>

          <hr className="last-class__horizontal-rule" />

          <div className="last-class__overlay__reference-container">

            {this.getStudentOverlayData()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </React.Fragment>
    );
  }
}

export default LastClass;
