import React from 'react';
import { Link } from 'react-router-dom';
import './login.scss';
import {getStateToken} from '../utils/functions';
import WarningMessage from '../components/warning_message';
import {AUTHENTICATE_API, MAIN_PATH, NEW_ACCESS_REQUEST_PATH, LOCAL_CACHE_DEVICE_IDENTIFIER_KEY} from '../constants';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device_name: props.device_name,
      email: "",
      password: "",
      authenticating: false,
      unit_options: null,
      selectedUnit: null,
      warningMessage: "",
      showWarningMessage: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  mayAuthenticate() {
    return this.state.email.match(/.+@.+\..+/) != null &&
           this.state.password.length > 0 &&
           this.state.device_name.length > 0 &&
           (this.state.unit_options === null || this.state.selectedUnit != null);
  }

  handleKeyDown(event) {
    if(event.keyCode === 13 && this.mayAuthenticate()) {
      this.authenticate();
    }
  }

  async handleAuthenticateError(response) {
    if(response.status === 401) {
      const data = await response.json();

      if(data.code === 202) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "E-mail ou(e) senha inválido(s). Tente novamente.",
          password: "",
        });

        return null;
      }
    }
    else if(response.status === 403) {
      const data = await response.json();

      if(data.code === 203) {
        if(data.message.includes('Email not yet validated')) {
          this.setState({
            authenticating: false,
            showWarningMessage: true,
            warningMessage: "Email não validado. Em caso de qualquer dúvida, por favor, entre em contato com o responsável pela sua unidade.",
          });
        }
        else if(data.message.includes('Unit is disabled')) {
          this.setState({
            authenticating: false,
            showWarningMessage: true,
            warningMessage: "Unidade temporariamente desabilitada. Por favor, notifique o responsável da unidade sobre o ocorrido.",
          });
        }
        else {
          this.setState({
            authenticating: false,
            showWarningMessage: true,
            warningMessage: "Usuário não possui permissão.",
            password: "",
          });
        }

        return null;
      }
    }
    else if(response.status === 422) {
      const data = await response.json();

      if(data.errors[0].code === 103) {
        this.setState({
          authenticating: false,
          showWarningMessage: true,
          warningMessage: "Nome de dispositivo já utilizado.",
          password: "",
        });

        return null;
      }
    }

    throw Error('Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.');
  }

  async authenticate() {
    this.setState({
      authenticating: true,
      showWarningMessage: false,
    });

    let stateToken = await getStateToken(true);

    if(stateToken) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        'state': stateToken
      });

      const postData = {
        device_name: this.state.device_name,
        email: this.state.email,
        password: this.state.password,
      };

      if(this.state.selectedUnit != null) {
        postData.unit_id = this.state.selectedUnit;
      }

      const body = JSON.stringify(postData);

      try {
        let response = await fetch(AUTHENTICATE_API,
          {
            method: "POST",
            headers: headers,
            cache: 'no-store',
            body: body,
          });

        if(!response.ok) {
          return await this.handleAuthenticateError(response);
        }

        let data = await response.json();

        if(data.unit_options) {
          this.setState({
            authenticating: false,
            unit_options: data.unit_options
          });
        }
        else {
          let localCache = localStorage.getItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY);

          if(localCache) {
            localCache = new Map(JSON.parse(localCache));
          }
          else {
            localCache = new Map();
          }

          localCache.set(data.token.unit.id, data.token);

          localStorage.setItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY, JSON.stringify([...localCache.entries()]));

          if(data.redirect_to) {
            this.props.history.replace(data.redirect_to);
          }
          else if(this.props.should_redirect) {
            this.props.history.replace(MAIN_PATH);
          }

          this.props.onAuthenticate(data);
        }

        return data;
      }
      catch(error) {
        console.log(error);
      }
    }

    this.setState({
      authenticating: false,
      showWarningMessage: true,
      warningMessage: "Infelizmente estamos com problemas em nosso serviço. Por favor, tente novamente mais tarde.",
    });

    return null;
  }

  getUnitOptions() {
    return this.state.unit_options.map((unit) => (
      <button
        key={`login:unit_option:${unit.id}`}
        className={`login__unit-option${this.state.selectedUnit === unit.id ? '--selected' : ''}`}
        onClick={() => this.setState({selectedUnit: unit.id})}
      >
        {unit.name}
      </button>
    ));
  }

  render() {
    const selectingUnit = this.state.unit_options != null;

    return (
      <section className="login">

        <div className="login__container">

          <header className="login__header">

            <h1 className="login__header__text">
              <span className="login__header__text--red">LOUSA</span>
              {' '}
              FYD
            </h1>

          </header>

          {(this.props.identified && !this.props.requires_login) ?
            <React.Fragment>

              <div className="login__message">

                <p className="login__message__text">
                  <span className="login__message__text--red">Aguardando autorização</span> do dispositivo. Por favor, certifique-se de entrar em
                  contato com o responsável pela unidade para agilizar o processo.
                </p>

              </div>

              <hr className="horizontal-rule"></hr>

              <div className="login__reques-new-access__wrapper">

                <Link
                  className="login__reques-new-access"
                  to={NEW_ACCESS_REQUEST_PATH}
                  >

                    <p className="login__reques-new-access__text">
                      Requisitar novo acesso
                    </p>

                </Link>

              </div>

            </React.Fragment>:
            <React.Fragment>

              {!selectingUnit &&
                <div className="login__title">

                  <p className="login__title__text">
                    Realize o login para requisitar a autorização do dispositivo
                  </p>

                </div>
              }

              <div className="login__warning-wrapper">

                <WarningMessage
                  message={this.state.warningMessage}
                  onClose={() => {this.setState({showWarningMessage: false})}}
                  visible={this.state.showWarningMessage}
                />

              </div>

              <div className="login__input-wrapper">

                {selectingUnit ?
                  <React.Fragment>

                    <p className="login__instructions">Selecione a unidade desejada:</p>

                    {this.getUnitOptions()}

                  </React.Fragment>:
                  <React.Fragment>

                    <input
                      className="login__input"
                      name="device_name"
                      type="device_name"
                      placeholder="Nome do dispositivo"
                      onChange={(event) => this.handleInputChange(event)}
                      value={this.state.device_name}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                      autoComplete="off"
                    />

                    <hr className="horizontal-rule"></hr>

                    <input
                      className="login__input"
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      onChange={(event) => this.handleInputChange(event)}
                      value={this.state.email}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                    <input
                      className="login__input"
                      name="password"
                      type="password"
                      placeholder="Senha"
                      onChange={(event) => this.handleInputChange(event)}
                      value={this.state.password}
                      onKeyDown={(event) => this.handleKeyDown(event)}
                    />

                  </React.Fragment>
                }

              </div>

              <div className="login__authenticate-wrapper">

                <button
                  className="login__authenticate-button"
                  disabled={!this.mayAuthenticate() || this.state.authenticating}
                  onClick={() => this.authenticate()}
                >

                  {this.mayAuthenticate() ? (<i className="fas fa-unlock"></i>) : (<i className="fas fa-lock"></i>)}
                  {selectingUnit ?
                    (this.state.authenticating ? ' Confirmando...' : ' Confirmar'):
                    this.state.authenticating ? ' Requisitando autorização...' : ' Requisitar autorização'
                  }
                  {}

                </button>

              </div>

            </React.Fragment>
          }

          <footer className="login__footer">

            <p className="login__footer__text">

              <i className="far fa-copyright"></i>
              {' '}{(new Date()).getFullYear()} | FYD CENTRO DE TREINAMENTO

             </p>

          </footer>

        </div>

      </section>
    );
  }
}

export default Login;
