const DEFAULT_ERROR_MESSAGE = `Infelizmente estamos com problemas em nosso serviço. ` +
                              `Certifique-se de que esteja conectado na internet e, ` +
                              `por favor, tente novamente mais tarde.`;

const DEFAULT_UNKNOWN_ERROR_MESSAGE = `Erro não documentado. Por favor, entre em contato com ` +
                                      `o suporte para que isto seja corrigido`;

export {DEFAULT_ERROR_MESSAGE, DEFAULT_UNKNOWN_ERROR_MESSAGE};

const TRAINING_GROUP_WORKOUT_KEY = 'Workout';

const TRAINING_EXECUTION_METHOD_FIXED_REPS = 'Repetições fechadas';
const TRAINING_EXECUTION_METHOD_PIRAMIDAL = 'Piramidal';
const TRAINING_EXECUTION_METHOD_QRP = 'QRP';

const CLOCK_METHOD_STOPWATCH = 'Cronômetro';
const CLOCK_METHOD_TIMER = 'Contagem regressiva';
const CLOCK_METHOD_TABATA = 'Tabata';
const CLOCK_METHOD_SERIES = 'Séries';
const CLOCK_METHOD_CUSTOM = 'Personalizado';

const DIFFUCULTY_OPTION_EASY = 'Adaptado';
const DIFFUCULTY_OPTION_NORMAL = 'Normal';
const DIFFUCULTY_OPTION_INTERMEDIATE = '*';
const DIFFUCULTY_OPTION_ADVANCED = 'AV';

const SERVICE_GYM = 'Musculação';
const SERVICE_BIKE = 'Fyd Bike';
const SERVICE_PILATES = 'Fyd Pilates';
const SERVICE_CARDIO = 'Cardio';
const SERVICE_CORE = 'Core';
const SERVICE_BOOTCAMP = 'FYD Run Bootcamp';
const SERVICE_DEFAULT_TRAINING = 'Cross FYD';

const LOCAL_CACHE_DEVICE_IDENTIFIER_KEY = 'fyd:classroom:device_identifiers';
const LOCAL_CACHE_SELECTED_SERVICE_KEY = 'fyd:classroom:target_service';

const OPERATIONAL_TASK_STATUS_PENDING = 'Pendente';
const OPERATIONAL_TASK_STATUS_INITIATED = 'Em progresso';
const OPERATIONAL_TASK_STATUS_FINISHED = 'Finalizada';

export {
  TRAINING_GROUP_WORKOUT_KEY,
  TRAINING_EXECUTION_METHOD_FIXED_REPS,
  TRAINING_EXECUTION_METHOD_PIRAMIDAL,
  TRAINING_EXECUTION_METHOD_QRP,
  CLOCK_METHOD_STOPWATCH,
  CLOCK_METHOD_TIMER,
  CLOCK_METHOD_TABATA,
  CLOCK_METHOD_SERIES,
  CLOCK_METHOD_CUSTOM,
  DIFFUCULTY_OPTION_EASY,
  DIFFUCULTY_OPTION_NORMAL,
  DIFFUCULTY_OPTION_INTERMEDIATE,
  DIFFUCULTY_OPTION_ADVANCED,
  SERVICE_DEFAULT_TRAINING,
  LOCAL_CACHE_DEVICE_IDENTIFIER_KEY,
  LOCAL_CACHE_SELECTED_SERVICE_KEY,
  SERVICE_GYM,
  SERVICE_BIKE,
  SERVICE_PILATES,
  SERVICE_CARDIO,
  SERVICE_CORE,
  SERVICE_BOOTCAMP,
  OPERATIONAL_TASK_STATUS_PENDING,
  OPERATIONAL_TASK_STATUS_INITIATED,
  OPERATIONAL_TASK_STATUS_FINISHED
};

// APIS
const STATE_API = '/request_state';

const AUTHENTICATE_API = '/autenticar';
const IS_AUTHENTICATED_API = '/autenticado';
const SIGN_OUT_API = '/deslogar';

const STUDENTS_GET_API = '/students';
const COACHES_GET_API = '/coaches';
const TRAINING_CALENDAR_GET_API = '/training-calendar';
const CURRENT_TRAINING_CLASS_GET_API = '/current-training-class/';
// const CURRENT_TRAINING_CLASS_STUDENTS_GET_API = '/current-class-checkin-students/';
// const CURRENT_TRAINING_CLASS_STUDENTS_GET_API_V2 = '/v2/current-class-checkin-students/';
const CURRENT_TRAINING_CLASS_STUDENTS_GET_API_V3 = '/v3/current-class-checkin-students/';
const CURRENT_TRAINING_CLASS_POST_API = '/current-training-class';
const CURRENT_TRAINING_CLASS_DELETE_API = '/current-training-class/';
const STUDENTS_CHECKIN_STATUS_GET_API = '/students-checkin-status/';
const NEXT_CLASS_STUDENT_CHECKIN_POST_API = '/next-class-checkin-student';
const CURRENT_CLASS_STUDENT_CHECKIN_POST_API = '/current-class-checkin-student';
const NEXT_CLASS_STUDENT_CHECKIN_DELETE_API = '/next-class-checkin-student/';
const CURRENT_CLASS_STUDENT_CHECKIN_DELETE_API = '/current-class-checkin-student/';

const CURRENT_TRAINING_CLASS_UPDATE_COACHES_POST_API = '/current-training-class/{service}/update-coaches';

const STUDENT_BASE_API = '/student/';
const STUDENT_EXERCISE_REFERENCE_GET_API = '/exercise-references/';
const STUDENT_EXERCISE_REFERENCE_POST_API = '/exercise-references';

const STUDENT_ACTIVE_PERSONAL_TRAINING_GET_API = '/personal-training';

const STUDENT_ACTIVE_GYM_TRAINING_DAY_GET_API = '/personal-training/active-gym-training';
const STUDENT_ACTIVE_GYM_TRAINING_DAY_POST_API = '/personal-training/active-gym-training';

const FINISH_CURRENT_CLASS_POST_API = '/finish-current-training-class';

const LAST_TRAINING_CLASS_GET_API = '/last-training-class/';

const TRAINING_DATA_PATCH_API = '/training-data/';
const TRAINING_DATA_ENTRIES_GET_API = '/training-data-entries/';

const TRAINING_DAY_GET_BY_HASH_API = '/training-day-by-hash/';
const CREATE_CLASS_BY_HASH_POST_API = '/create-training-class-by-hash/';

const EXPERIMENTAL_TRAINING_DAY_GET_BY_HASH_API = '/experimental-training-day-by-hash/';

const HASHED_TRAINING_CLASS_CHECKIN_STATUS_GET_API = '/hashed-class-checkin-status/';
const HASHED_TRAINING_CLASS_STUDENTS_GET_API = '/hashed-class-checkin-students/';
const HASHED_CLASS_STUDENT_CHECKIN_POST_API = '/hashed-class-checkin-student/';
const HASHED_CLASS_STUDENT_CHECKIN_DELETE_API = '/hashed-class-checkin-student/';

const TRAINING_CLASS_GET_API = '/training-class/';

const TRAINING_TIMES_GET_API = '/training-times/';

const EXPERIMENTAL_CLASS_CHECK_IN_API = '/aula-experimental/agendados/';

const EARLY_CHECKIN_STUDENTS_GET_API = '/early-checkin-students/';
const DAILY_EARLY_CHECKIN_STUDENTS_GET_API = '/early-checkin-students/';

const SWITCH_DEVICE_UNIT_POST_API = '/switch-device-unit';
const REMOVE_DEVICE_IDENTIFIER_POST_API = '/remove-device-identifier';

const TRAINING_PERIOD_SERVICES_GET_API = '/training-period-services';

const WEARABLE_DEVICES_GET_API = '/wearable-devices';

const DEFAULT_EXPERIMENTAL_PERIODS_GET_API = '/default-experimental-periods';

const OPERATIONAL_TASKS_GET_API = '/operational-tasks/';
const OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API = '/operational-task/{id}/update-status';
const OPERATIONAL_TASK_POST_API = '/operational-tasks';

export {
  STATE_API,
  AUTHENTICATE_API,
  IS_AUTHENTICATED_API,
  SIGN_OUT_API,
  STUDENTS_GET_API,
  COACHES_GET_API,
  TRAINING_CALENDAR_GET_API,
  CURRENT_TRAINING_CLASS_STUDENTS_GET_API_V3,
  CURRENT_TRAINING_CLASS_GET_API,
  CURRENT_TRAINING_CLASS_POST_API,
  CURRENT_TRAINING_CLASS_DELETE_API,
  STUDENTS_CHECKIN_STATUS_GET_API,
  NEXT_CLASS_STUDENT_CHECKIN_POST_API,
  CURRENT_CLASS_STUDENT_CHECKIN_POST_API,
  NEXT_CLASS_STUDENT_CHECKIN_DELETE_API,
  CURRENT_CLASS_STUDENT_CHECKIN_DELETE_API,
  CURRENT_TRAINING_CLASS_UPDATE_COACHES_POST_API,
  STUDENT_BASE_API,
  STUDENT_EXERCISE_REFERENCE_GET_API,
  STUDENT_EXERCISE_REFERENCE_POST_API,
  STUDENT_ACTIVE_GYM_TRAINING_DAY_GET_API,
  STUDENT_ACTIVE_GYM_TRAINING_DAY_POST_API,
  FINISH_CURRENT_CLASS_POST_API,
  LAST_TRAINING_CLASS_GET_API,
  TRAINING_DATA_ENTRIES_GET_API,
  TRAINING_DATA_PATCH_API,
  TRAINING_DAY_GET_BY_HASH_API,
  CREATE_CLASS_BY_HASH_POST_API,
  HASHED_TRAINING_CLASS_CHECKIN_STATUS_GET_API,
  HASHED_TRAINING_CLASS_STUDENTS_GET_API,
  HASHED_CLASS_STUDENT_CHECKIN_POST_API,
  HASHED_CLASS_STUDENT_CHECKIN_DELETE_API,
  TRAINING_CLASS_GET_API,
  TRAINING_TIMES_GET_API,
  EXPERIMENTAL_CLASS_CHECK_IN_API,
  EARLY_CHECKIN_STUDENTS_GET_API,
  DAILY_EARLY_CHECKIN_STUDENTS_GET_API,
  SWITCH_DEVICE_UNIT_POST_API,
  REMOVE_DEVICE_IDENTIFIER_POST_API,
  TRAINING_PERIOD_SERVICES_GET_API,
  WEARABLE_DEVICES_GET_API,
  EXPERIMENTAL_TRAINING_DAY_GET_BY_HASH_API,
  DEFAULT_EXPERIMENTAL_PERIODS_GET_API,
  STUDENT_ACTIVE_PERSONAL_TRAINING_GET_API,
  OPERATIONAL_TASKS_GET_API,
  OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API,
  OPERATIONAL_TASK_POST_API
};

// PATHS
const MAIN_PATH = '/';
const STUDENT_CHECKIN_PATH = '/checkin-do-aluno';
const START_NEW_CLASS_PATH = '/iniciar-nova-aula';
const CURRENT_CLASS_PATH = '/aula-atual';
const LAST_CLASS_PATH = '/ultima-aula';

const STUDENT_BASE_PATH = '/student/';
const STUDENT_TRAINING_DAY_REPORT = '/training-day-report/';

const TRAINING_DAY_CLASS_BY_HASH_PATH = '/aula/';

const HASHED_CLASS_STUDENT_CHECKIN_PATH = '/checkin-do-aluno/';

const CLASS_RESULTS_PATH = '/resultado-da-aula/';

const DAILY_REPORT_PATH = '/resultados-do-dia';

const EARLY_CHECKIN_PATH = '/checkin-antecipado';

const FEEDBACK_PATH = '/pesquisa-de-satisfacao';

const NEW_ACCESS_REQUEST_PATH = '/requisitar-novo-acesso';
const SWITCH_UNIT_PATH = '/alterar-unidade-vinculada';

const SWITCH_SERVICE_PATH = '/alterar-servico-vinculado';

const INITIATE_DEVICE_CONNECTION_PATH = '/inicializar-dispositivos';

const EXPERIMENTAL_TRAINING_DAY_BY_HASH_PATH = '/treino/';

const CLASSROOM_OPERATIONAL_TASKS_PAGE = '/pendencias-e-tarefas';

export {
  MAIN_PATH,
  STUDENT_CHECKIN_PATH,
  START_NEW_CLASS_PATH,
  CURRENT_CLASS_PATH,
  LAST_CLASS_PATH,
  STUDENT_BASE_PATH,
  STUDENT_TRAINING_DAY_REPORT,
  TRAINING_DAY_CLASS_BY_HASH_PATH,
  HASHED_CLASS_STUDENT_CHECKIN_PATH,
  CLASS_RESULTS_PATH,
  DAILY_REPORT_PATH,
  EARLY_CHECKIN_PATH,
  FEEDBACK_PATH,
  NEW_ACCESS_REQUEST_PATH,
  SWITCH_UNIT_PATH,
  SWITCH_SERVICE_PATH,
  INITIATE_DEVICE_CONNECTION_PATH,
  EXPERIMENTAL_TRAINING_DAY_BY_HASH_PATH,
  CLASSROOM_OPERATIONAL_TASKS_PAGE
};
