import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer, VerticalAccordionContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import OverlayWindow from '../components/overlay_window';
import DefaultInput, {SelectOption} from '../utils/default_input';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        OPERATIONAL_TASK_STATUS_INITIATED,
        OPERATIONAL_TASK_STATUS_PENDING,
        OPERATIONAL_TASK_STATUS_FINISHED} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import ConfirmationWindow from '../components/confirmation_window';
import {getModels, patchModel, postModel} from '../utils/functions';
import './operational_tasks.scss';

class OperationalTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      operational_tasks: [],
      taskVisibilityMap: new Map(),
      onRegisterTask: false,
      taskToInitiate: null,
      taskToFinish: null,
      taskCommentaryToUpdate: '',
      newTaskText: '',
      newTaskPriority: 1,
      newTaskCommentary: '',
      showSuccessWindow: false,
      confirmFailed: false,
      confirmDescription: "",
      confirmInProgress: false,
      screenWidth: window.innerWidth,
    };

    this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER = [
      OPERATIONAL_TASK_STATUS_INITIATED,
      OPERATIONAL_TASK_STATUS_PENDING,
      OPERATIONAL_TASK_STATUS_FINISHED
    ];
  }

  async getOperationalTasks() {
    return await getModels(`${routes.OPERATIONAL_TASKS_GET_API}${this.props.target_service}`);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async reloadList() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let operational_tasks = this.getOperationalTasks();

    operational_tasks = await operational_tasks;

    update.operational_tasks = operational_tasks;

    update.operational_tasks.sort((a, b) => {
      const aStatusWeight = this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER.indexOf(a.status);
      const bStatusWeight = this.OPERATIONAL_TASK_STATUS_WEIGHT_ORDER.indexOf(b.status);

      if (aStatusWeight !== bStatusWeight) {
        return aStatusWeight - bStatusWeight;
      }
      else if (a.priority !== b.priority) {
        return b.priority - a.priority;
      }

      return b.created_at.localeCompare(a.created_at);
    });

    this.setState(update);
  }

  async componentDidMount() {
    this.reloadList();

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;

    this.setState({
      [name]: value
    });
  }

  onToggleTaskContent(taskId) {
    const taskVisibilityMap = new Map(this.state.taskVisibilityMap);

    let visible = false;

    if (taskVisibilityMap.has(taskId)) {
      visible = taskVisibilityMap.get(taskId);
    }

    taskVisibilityMap.set(taskId, !visible);

    this.setState({taskVisibilityMap});
  }

  getTasks() {
    const taskElements = this.state.operational_tasks.map((entry) => {
      const contentVisible = this.state.taskVisibilityMap.get(entry.id);

      return (
        <li
          key={`operational-task:${entry.id}`}
          className="operational-tasks__task__wrapper"
        >

          <div className={`operational-tasks__task__priority-indicator--${entry.priority}`}></div>

          <section className="operational-tasks__task__expandable-container">

            <header className="operational-tasks__task__expandable-container__header">

              <div className="operational-tasks__task">

                <div
                  className={`operational-tasks__task__main${entry.commentary !== null ? '' : '--disabled'}`}
                  onClick={() => {
                    if (entry.commentary !== null) {
                      this.onToggleTaskContent(entry.id);
                    }
                  }}
                >

                  {contentVisible ?
                    <i className={`fas fa-chevron-down operational-tasks__task__expandable-container__header__visible-icon${entry.commentary !== null ? '' : '--hidden'}`}></i>:
                    <i className={`fa-solid fa-chevron-right operational-tasks__task__expandable-container__header__visible-icon${entry.commentary !== null ? '' : '--hidden'}`}></i>
                  }

                  <h3 className="operational-tasks__task__expandable-container__header-text">{entry.task}</h3>

                </div>

                {entry.finished_at === null &&
                  <div className="operational-tasks__task__input-wrapper">

                    <button
                      className="operational-tasks__task__input--blue"
                      onClick={() => {
                        if (entry.initiated_at === null) {
                          this.onMarkTaskInitiated(entry);
                        }
                      }}
                      disabled={entry.initiated_at !== null}
                    >

                      {entry.initiated_at !== null &&
                        <i className="fas fa-check operational-tasks__task__input__icon"></i>
                      }

                    </button>

                  </div>
                }

                <div className="operational-tasks__task__input-wrapper">

                  <button
                    className="operational-tasks__task__input--green"
                    onClick={() => {
                      if (entry.finished_at === null) {
                        this.onMarkTaskFinished(entry);
                      }
                    }}
                    disabled={entry.finished_at !== null}
                  >

                    {entry.finished_at !== null &&
                      <i className="fas fa-check operational-tasks__task__input__icon"></i>
                    }

                  </button>

                </div>

              </div>

            </header>

            <VerticalAccordionContainer
              className="vertical-accordion-container operational-tasks__task__expandable-container__content-wrapper"
              pose={contentVisible ? 'verticalOpen' : 'verticalClosed'}>

              <div className="operational-tasks__task__expandable-container__content">

                <p className="operational-tasks__task__commentary">{entry.commentary}</p>

              </div>

            </VerticalAccordionContainer>

          </section>

        </li>
      );
    });

    return (
      <ul className="operational-tasks__list">

        <li className="operational-tasks__list__header">

          <p className="operational-tasks__list__header__name">Tarefa</p>
          <p className="operational-tasks__list__header__initiated">Iniciada</p>
          <p className="operational-tasks__list__header__finished">Finalizada</p>

        </li>

        {taskElements.length <= 0 &&
          <li className="operational-tasks__no-data-found">
            <p className="operational-tasks__no-data-found__text">Nenhuma tarefa pendente</p>
          </li>
        }

        {taskElements}

      </ul>
    );
  }

  onCancelConfirmationWindow() {
    this.setState({
      taskToInitiate: null,
      taskToFinish: null,
      onRegisterTask: false,
      confirmInProgress: false
    });
  }

  async onConfirmConfirmationWindow() {
    if (this.state.onRegisterTask) {
      const data = {
        priority: parseInt(this.state.newTaskPriority),
        task: this.state.newTaskText,
        commentary: this.state.newTaskCommentary,
        target_service: this.props.target_service
      };

      this.setState({
        confirmInProgress: true
      });

      try{
        await postModel(routes.OPERATIONAL_TASK_POST_API, data);

        this.reloadList();

        // this.setState({
        //   showSuccessWindow: true,
        //   confirmDescription: 'Tarefa cadastrada com sucesso',
        //   taskToInitiate: null,
        //   taskToFinish: null,
        //   onRegisterTask: false,
        //   confirmInProgress: false
        // });

        // return;
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else {
      const data = {commentary: this.state.taskCommentaryToUpdate};

      let taskId;

      if(this.state.taskToInitiate !== null) {
        data.status = OPERATIONAL_TASK_STATUS_INITIATED;
        taskId = this.state.taskToInitiate.id;
      }
      else if(this.state.taskToFinish !== null) {
        data.status = OPERATIONAL_TASK_STATUS_FINISHED;
        taskId = this.state.taskToFinish.id;
      }
      else {
        return;
      }

      this.setState({
        confirmInProgress: true
      });

      try{
        const response = await patchModel(routes.OPERATIONAL_TASK_UPDATE_STATUS_PATCH_API.replace('{id}', taskId), data, true);

        if(response) {
          const operational_tasks = this.state.operational_tasks.map((entry) => {
            let task;

            if (entry.id === taskId) {
              task = {...response.model_saved};
            }
            else {
              task = {...entry}
            }

            return task;
          });

          this.setState({operational_tasks});
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 209:
                errorDescription = 'Sessão do usuário expirada';

                break;
              default:
            }
          }
        }

        this.setState({
          confirmDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }

    this.onCancelConfirmationWindow();
  }

  getConfirmationWindowTitle() {
    if(this.state.showSuccessWindow) {
      return 'Sucesso!';
    }
    else if(this.state.onRegisterTask) {
      return 'Registrar pendência';
    }
    else if(this.state.taskToInitiate !== null) {
      return 'Iniciar tarefa';
    }
    else if(this.state.taskToFinish !== null) {
      return 'Finalizar tarefa';
    }

    return '';
  }

  getConfirmationWindowDescription() {
    if(this.state.onRegisterTask) {
      return 'Uma nova pendência será registrada e encaminhada para a equipe de gestão da academia.';
    }
    else if(this.state.taskToInitiate !== null) {
      return 'A tarefa será marcada como iniciada.';
    }
    else if(this.state.taskToFinish !== null) {
      return 'A tarefa será marcada como finalizada e não aparecerá novamente nesta listagem.';
    }
  }

  getPriorityOptions() {
    return [
      SelectOption(1, 'Normal'),
      SelectOption(2, 'Alta'),
      SelectOption(3, 'Urgente')
    ];
  }

  getConfirmationWindowContent() {
    if (this.state.taskToInitiate !== null || this.state.taskToFinish !== null) {
      return (
        <React.Fragment>

          <DefaultInput
            name="taskCommentaryToUpdate"
            label="Comentário/Observação"
            type="textarea"
            placeholder="Comentário / Observação"
            rows="6"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.taskCommentaryToUpdate || ''}
          />

        </React.Fragment>
      );
    }
    else if (this.state.onRegisterTask) {
      return (
        <React.Fragment>

          <DefaultInput
            name="newTaskText"
            label="Pendência"
            type="text"
            placeholder="Breve descrição"
            maxLength="128"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskText}
            autoComplete="off"
          />

          <DefaultInput
            name="newTaskPriority"
            label="Prioridade"
            type="select"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskPriority || ''}
            options={this.getPriorityOptions()}
          />

          <DefaultInput
            name="newTaskCommentary"
            label="Comentário/Observação"
            type="textarea"
            placeholder="Comentário / Observação"
            rows="6"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.newTaskCommentary || ''}
          />

        </React.Fragment>
      );
    }
  }

  onRegisterTask() {
    this.setState({
      onRegisterTask: true,
      newTaskText: '',
      newTaskPriority: 1,
      newTaskCommentary: ''
    });
  }

  onMarkTaskInitiated(entry) {
    this.setState({
      taskToInitiate: entry,
      taskCommentaryToUpdate: entry.commentary
    });
  }

  onMarkTaskFinished(entry) {
    this.setState({
      taskToFinish: entry,
      taskCommentaryToUpdate: entry.commentary
    });
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="operational-tasks">

            <React.Fragment>

              <div className="operational-tasks__actions-container">

                <DefaultMenuButton
                  className="operational-tasks__add-button"
                  onClick={() => {
                    this.onRegisterTask();
                  }}
                  text={(
                    <React.Fragment>

                      <i className="fa-solid fa-plus operational-tasks__add-button__icon"></i>
                      Cadastrar pendência

                    </React.Fragment>
                  )}
                  color="green"
                  disabled={this.state.confirmInProgress}
                />

              </div>

              {this.getTasks()}

            </React.Fragment>

          </div>

        </DefaultMenuLayout>

        <OverlayWindow
          className="operational-tasks__confirmation-window"
          visible={this.state.taskToInitiate !== null || this.state.taskToFinish !== null || this.state.onRegisterTask}
          loading={this.state.confirmInProgress}
          actions={(
            <div className="operational-tasks__confirmation-window__action-container">

              <DefaultMenuButton
                className="operational-tasks__confirmation-window__action-button"
                onClick={() => {
                  this.onCancelConfirmationWindow();
                }}
                disabled={this.state.confirmInProgress}
                text="Cancelar"
              />

              <DefaultMenuButton
                className="operational-tasks__confirmation-window__action-button"
                onClick={() => {
                  this.onConfirmConfirmationWindow();
                }}
                text="Confirmar"
                disabled={this.state.confirmInProgress}
                color="green"
              />

            </div>
          )}
        >

          <header className="operational-tasks__confirmation-window__header">

            <h3 className="operational-tasks__confirmation-window__header__title">
              {this.getConfirmationWindowTitle()}
            </h3>

          </header>

          <hr className="operational-tasks__confirmation-window__horizontal-rule" />

          <div className="operational-tasks__confirmation-window__content">

            <p className="operational-tasks__confirmation-window__description">{this.getConfirmationWindowDescription()}</p>

            {this.getConfirmationWindowContent()}

          </div>

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.state.confirmDescription}
          confirmText="Confirmar"
          cancelText="Ok"
          visible={this.state.confirmFailed || this.state.showSuccessWindow}
          onCancel={() => this.setState({
            confirmFailed: false,
            showSuccessWindow: false
          })}
          useSuccessIcon={this.state.showSuccessWindow}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed || this.state.showSuccessWindow}
        />

      </React.Fragment>
    );
  }
}

export default OperationalTasks;
