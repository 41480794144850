import React from 'react';
import './main_menu.scss';
import * as routes from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';


class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  render() {
    return (
      <DefaultMenuLayout
        token={this.props.token}
        target_service={this.props.target_service}
        bluetoothDevices={this.props.bluetoothDevices}
        showOperationalTasksLink={true}
      >

        <div className="main-menu">

          <div className="main-menu__link-wrapper last-class-link">

            <DefaultMenuButton
              linkTo={routes.LAST_CLASS_PATH}
              text="Última aula"
              color="blue"
            />

          </div>

          <div className="main-menu__link-wrapper student-checkin-link">

            <DefaultMenuButton
              linkTo={routes.STUDENT_CHECKIN_PATH}
              text="Check-in de aluno"
              color="red"
            />

          </div>

          <div className="main-menu__link-wrapper start-resume-class-link">

            <DefaultMenuButton
              linkTo={routes.START_NEW_CLASS_PATH}
              text="Iniciar nova aula"
            />

          </div>

          <div className="main-menu__link-wrapper daily-report-link">

            <DefaultMenuButton
              linkTo={routes.DAILY_REPORT_PATH}
              text="Resultados do dia"
              color="purple"
            />

          </div>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default MainMenu;
