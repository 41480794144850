import React from 'react';
import './default_menu_layout.scss';
import DefaultHeader from './default_header';

function DefaultMenuLayout(props) {
  return (
    <div className="default-menu-layout">

      <DefaultHeader
        className="default-menu-layout__header"
        showBackButton={props.showBackButton}
        history={props.history}
        onBackClick={props.onBackClick ? () => props.onBackClick() : null}
        token={props.token}
        target_service={props.target_service}
        bluetoothDevices={props.bluetoothDevices}
        showOperationalTasksLink={props.showOperationalTasksLink}
      />

      <main className="default-menu-layout__main">

        {props.children}

      </main>

    </div>
  );
}

export default DefaultMenuLayout;
