import React from 'react';
import { PoseGroup } from 'react-pose';
import './initiate_device_connection.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import UserList, {UserItem} from '../components/user_list';
import WarningMessage from '../components/warning_message';
import {getModels, setUrlParameters} from '../utils/functions';

class InitiateDeviceConnection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      hr_devices: [],
      syncMap: [],
      showErrorMessage: false,
      errorMessage: '',
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getDevices() {
    const parameters = {};

    if(this.props.target_service) {
      parameters.target_service = this.props.target_service;
    }

    return await getModels(setUrlParameters(routes.WEARABLE_DEVICES_GET_API, parameters));
  }

  async componentDidMount() {
    const update = {loading: false};

    let devices = await this.getDevices();

    update.devices = devices;

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  async syncDevice(device) {
    const lastSyncMap = [...this.state.syncMap];
    const newSyncMap = [...this.state.syncMap];
    newSyncMap.push(device.device_identifier);

    this.setState({
      showErrorMessage: false,
      syncMap: newSyncMap
    });

    const response = await this.props.bluetoothDevices.requestDevice(device.device_identifier, ['heart_rate']);

    if(response.error === null) {
      this.setState({
        showErrorMessage: false,
        syncMap: lastSyncMap
      });
    }
    else {
      this.setState({
        showErrorMessage: true,
        errorMessage: response.error,
        syncMap: lastSyncMap
      });
    }
  }

  getDeviceOptions() {
    if(this.state.devices.length <= 0) {
      return (
        <p className="initiate-device-connection__not-found-text">Nenhum dispositivo encontrado</p>
      );
    }

    this.state.devices.sort((a, b) => {
      const aInitialized = this.props.bluetoothDevices.deviceHasInitialized(a.device_identifier);
      const bInitialized = this.props.bluetoothDevices.deviceHasInitialized(b.device_identifier);

      if(aInitialized && !bInitialized) {
        return 1;
      }
      else if(!aInitialized && bInitialized) {
        return -1;
      }

      return a.name.localeCompare(b.name);
    });

    return this.state.devices.map((device) => {
      const initialized = this.props.bluetoothDevices.deviceHasInitialized(device.device_identifier);
      const syncInProgress = this.state.syncMap.includes(device.device_identifier);

      return (
        <UserItem
          key={`hr_device:${device.id}`}
          className={`initiate-device-connection__device-option${initialized ? '--selected' : ''}`}
          hideImage={true}
          name={device.name}
        >

          {initialized ?
            <p className="initiate-device-connection__device-option__status-text">Pareado</p>:
            <DefaultMenuButton
              className="initiate-device-connection__action-button"
              onClick={(event) => this.syncDevice(device)}
              text={
                syncInProgress ? (
                  <i className="fas fa-spinner initiate-device-connection__loading-icon"></i>
                ):
                (
                  <React.Fragment><i className="fab fa-bluetooth-b initiate-device-connection__action-button__icon"></i> Sincronizar</React.Fragment>
                )
              }
              color="blue"
              disabled={syncInProgress}
            />
          }

        </UserItem>
      );
    });
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="initiate-device-connection">

            <React.Fragment>

              <h1 className="initiate-device-connection__instructions">

                Dispositivos cadastrados:

              </h1>

              <WarningMessage
                message={this.state.errorMessage}
                onClose={() => {this.setState({showErrorMessage: false})}}
                visible={this.state.showErrorMessage}
              />

              <UserList className="initiate-device-connection__unit-list">

                {this.getDeviceOptions()}

              </UserList>

            </React.Fragment>

          </div>

        </DefaultMenuLayout>

      </React.Fragment>
    );
  }
}

export default InitiateDeviceConnection;
