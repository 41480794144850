import React from 'react';
import { PoseGroup } from 'react-pose';
import './daily_report.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModels} from '../utils/functions';
import SimpleBarGraph, {BarPoint} from '../graphs/simple_bar_graph';

// 0: "#bb2d2d",
// 1: "#dc5151",
// 2: "#ec7676",
// 3: "#d68787",
// 4: "#9a9090",
const TIME_COLOR_MAP = {
  0: "#e62b2b",
  1: "#f7640b",
  2: "#ffad11",
  3: "#d2c845",
  4: "#9a9090",
};

// 0: "#1866b4",
// 1: "#3f85cc",
// 2: "#63a0de",
// 3: "#83b0dc",
// 4: "#9a9090",
const REPETITION_COLOR_MAP = {
  0: "#4938cc",
  1: "#2489e2",
  2: "#24d8e2",
  3: "#46d0ab",
  4: "#9a9090",
};

const WEIGHT_COLOR_MAP = {
  0: "#508c41",
  1: "#6f9865",
  2: "#98bb72",
  3: "#cddc9e",
  4: "#9a9090",
};

// 0: "#7a39b5",
// 1: "#9652d4",
// 2: "#a670d8",
// 3: "#b699d2",
// 4: "#9a9090",
const PSE_COLOR_MAP = {
  0: "#5430dc",
  1: "#932dd0",
  2: "#de67d9",
  3: "#ca6ea0",
  4: "#9a9090",
};

class DailyReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      training_data: [],
      expected_pse: null,
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainingData() {
    return await getModels(`${routes.TRAINING_DATA_ENTRIES_GET_API}${this.props.target_service}?apply_today_filter=true`);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let training_data = this.getTrainingData();

    training_data = await training_data;

    if(training_data) {
      update.training_data = training_data;

      const referenceEntry = training_data.find((data) => data.expected_pse);

      if(referenceEntry) {
        this.setState({expected_pse: referenceEntry.expected_pse});
      }

      this.setState(update);

      this.resizeListener = () => this.updateSize();

      window.addEventListener("resize", this.resizeListener);
    }
    else {
      this.props.history.replace(routes.MAIN_PATH);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getReportData() {
    let timeData = [];
    let repsData = [];
    let pseData = [];
    let weightData = [];

    for(let entry of this.state.training_data) {
      const difficulty = entry.difficulty || 'Não especificado';
      const total_seconds = entry.total_seconds || 0;
      const total_repetitions = entry.total_repetitions || 0;
      const total_weight = entry.total_weight || 0;
      const pse = entry.pse || 0;
      const username = entry.username

      let groupIndex;

      switch (difficulty) {
        case 'AV':
          groupIndex = 0;
          break;
        case '*':
          groupIndex = 1;
          break;
        case 'Normal':
          groupIndex = 2;
          break;
        case 'Adaptado':
          groupIndex = 3;
          break;
        default:
          groupIndex = 4;
      }

      timeData.push({
        username,
        difficulty,
        total_seconds,
        groupIndex,
      });
      repsData.push({
        username,
        difficulty,
        total_repetitions,
        groupIndex,
      });
      weightData.push({
        username,
        difficulty,
        total_weight,
        groupIndex,
      });
      pseData.push({
        username,
        difficulty,
        pse,
        groupIndex,
      });
    }

    timeData.sort((a, b) => {
      let diff = a.groupIndex - b.groupIndex;

      if(diff === 0) {
        diff = a.total_seconds - b.total_seconds;
      }

      return diff;
    });
    repsData.sort((a, b) => {
      let diff = a.groupIndex - b.groupIndex;

      if(diff === 0) {
        diff = b.total_repetitions - a.total_repetitions;
      }

      return diff;
    });
    weightData.sort((a, b) => {
      let diff = a.groupIndex - b.groupIndex;

      if(diff === 0) {
        diff = b.total_weight - a.total_weight;
      }

      return diff;
    });
    pseData.sort((a, b) => {
      let diff = a.groupIndex - b.groupIndex;

      if(diff === 0) {
        diff = b.pse - a.pse;
      }

      return diff;
    });

    timeData = timeData.map((entry) => BarPoint(entry.total_seconds, entry.username, `${this.getTime(entry.total_seconds)}`, TIME_COLOR_MAP[entry.groupIndex]));
    repsData = repsData.map((entry) => BarPoint(entry.total_repetitions, entry.username, `${entry.total_repetitions}`, REPETITION_COLOR_MAP[entry.groupIndex]));
    weightData = weightData.map((entry) => BarPoint(entry.total_weight, entry.username, `${entry.total_weight}`, WEIGHT_COLOR_MAP[entry.groupIndex]));
    pseData = pseData.map((entry) => BarPoint(entry.pse, entry.username, `${entry.pse}`, PSE_COLOR_MAP[entry.groupIndex]));

    return {
      timeData,
      repsData,
      pseData,
      weightData
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getTime(value) {
    const formater = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 });

    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formater.format(minutes)}:${formater.format(seconds)}`;
  }

  render() {
    const reportData = this.getReportData();

    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        bluetoothDevices={this.props.bluetoothDevices}
      >

        <div className="daily-report">

          <div className="daily-report__graphs-container">

            {this.props.isGymService ?
              <React.Fragment>

                <h4 className="daily-report__graph-section">

                  <i className="fas fa-dumbbell"></i>
                  <span className="daily-report__graph-section__text">Kilagem total média</span>

                </h4>

                <SimpleBarGraph
                  className="daily-report__graph"
                  name="Kilagem total média"
                  data={reportData.weightData}
                  height={this.getDefaultGraphHeight()}
                  barColor="#62b94d"
                  labelFormatter={(value) => value}
                  xLabelAngle={-50}
                />

              </React.Fragment>:
              <React.Fragment>

                <h4 className="daily-report__graph-section">

                  <i className="fas fa-stopwatch"></i>
                  <span className="daily-report__graph-section__text">Tempo total</span>

                </h4>

                <SimpleBarGraph
                  className="daily-report__graph"
                  name="Tempo total"
                  data={reportData.timeData}
                  height={this.getDefaultGraphHeight()}
                  barColor="#e65a5a"
                  labelFormatter={(value) => this.getTime(value)}
                  xLabelAngle={-50}
                />

                <h4 className="daily-report__graph-section">

                  <i className="fas fa-dumbbell"></i>
                  <span className="daily-report__graph-section__text">Repetições</span>

                </h4>

                <SimpleBarGraph
                  className="student-report__graph"
                  name="Repetições"
                  data={reportData.repsData}
                  height={this.getDefaultGraphHeight()}
                  barColor="#4d86ce"
                  labelFormatter={(value) => value}
                  xLabelAngle={-45}
                />

              </React.Fragment>
            }

            <h4 className="daily-report__graph-section">

              <i className="fas fa-dumbbell"></i>
              <span className="daily-report__graph-section__text">Percepção Subjetiva de Esforço (PSE)</span>

            </h4>

            <SimpleBarGraph
              className="student-report__graph"
              name="PSE"
              data={reportData.pseData}
              height={this.getDefaultGraphHeight()}
              barColor="#b678ef"
              labelFormatter={(value) => value}
              stripLine={{
                value: this.state.expected_pse,
                label: 'PSE esperada'
              }}
              xLabelAngle={-45}
            />

          </div>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default DailyReport;
