import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import {SERVICE_GYM,
        SERVICE_BIKE,
        SERVICE_PILATES} from '../constants';
import DefaultMenuLayout from '../components/default_menu_layout';
import {getModels, getAsLocalDate} from '../utils/functions';
import SimpleBarGraph, {BarPoint} from '../graphs/simple_bar_graph';
import './student_report.scss';


class StudentReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      training_data: [],
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getTrainingData() {
    return await getModels(`${routes.TRAINING_DATA_ENTRIES_GET_API}${this.props.target_service}?student_id=${this.props.match.params.studentId}&training_day_id=${this.props.match.params.trainingDayId}`);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let training_data = this.getTrainingData();

    training_data = await training_data;

    if(training_data && training_data.length > 0) {
      update.training_data = training_data;
      update.training_data.sort((a, b) => a.training_class.started_at.localeCompare(b.training_class.started_at));

      this.setState(update);

      this.resizeListener = () => this.updateSize();

      window.addEventListener("resize", this.resizeListener);
    }
    else {
      this.props.history.replace(routes.MAIN_PATH);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getReportData() {
    const timeData = [];
    const repsData = [];
    const pseData = [];
    const weightData = [];

    for(let entry of this.state.training_data) {
      const total_seconds = entry.total_seconds || 0;
      const total_repetitions = entry.total_repetitions || 0;
      const total_weight = entry.total_weight || 0;
      const pse = entry.pse || 0;
      const difficulty = entry.difficulty || 'Não especificado';

      const index = `${difficulty} - Fase ${entry.training_class.repetition_index}`;

      timeData.push(BarPoint(total_seconds, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${this.getTime(total_seconds)} - ${index}`));
      repsData.push(BarPoint(total_repetitions, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${total_repetitions} - ${index}`));
      weightData.push(BarPoint(total_weight, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${total_weight} - ${index}`));
      pseData.push(BarPoint(pse, getAsLocalDate(entry.training_class.started_at).toLocaleDateString(), `${pse} - ${index}`));
    }

    return {
      timeData,
      repsData,
      pseData,
      weightData
    };
  }

  getDefaultGraphHeight() {
    if(this.state.screenWidth <= 420) {
      return 220;
    }

    if(this.state.screenWidth <= 600) {
      return 270;
    }

    if(this.state.screenWidth <= 1100) {
      return 350;
    }

    return null;
  }

  getTime(value) {
    const formater = new Intl.NumberFormat('en-US', { minimumIntegerDigits: 2 });

    const minutes = Math.floor(value / 60);
    const seconds = value % 60;

    return `${formater.format(minutes)}:${formater.format(seconds)}`;
  }

  getServiceGraphs(reportData) {
    switch (this.props.target_service) {
      case SERVICE_BIKE:
      case SERVICE_PILATES:
        return null;
      case SERVICE_GYM:
        return (
          <React.Fragment>

            <h4 className="student-report__graph-section">

              <i className="fas fa-dumbbell"></i>
              <span className="student-report__graph-section__text">Kilagem total média</span>

            </h4>

            <SimpleBarGraph
              className="student-report__graph"
              name="Kilagem total média"
              data={reportData.weightData}
              height={this.getDefaultGraphHeight()}
              barColor="#62b94d"
              labelFormatter={(value) => value}
            />

          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>

            <h4 className="student-report__graph-section">

              <i className="fas fa-stopwatch"></i>
              <span className="student-report__graph-section__text">Tempo total</span>

            </h4>

            <SimpleBarGraph
              className="student-report__graph"
              name="Tempo total"
              data={reportData.timeData}
              height={this.getDefaultGraphHeight()}
              barColor="#e65a5a"
              labelFormatter={(value) => this.getTime(value)}
            />

            <h4 className="student-report__graph-section">

              <i className="fas fa-dumbbell"></i>
              <span className="student-report__graph-section__text">Repetições</span>

            </h4>

            <SimpleBarGraph
              className="student-report__graph"
              name="Repetições"
              data={reportData.repsData}
              height={this.getDefaultGraphHeight()}
              barColor="#4d86ce"
              labelFormatter={(value) => value}
            />

          </React.Fragment>
        );
    }
  }

  render() {
    const reportData = this.getReportData();

    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <DefaultMenuLayout
        showBackButton={true}
        history={this.props.history}
        bluetoothDevices={this.props.bluetoothDevices}
      >

        <div className="student-report">

          <header className="student-report__header">

            <div className="student-report__header__student-name">

              <h2 className="student-report__header__label">
                <u>Aluno</u>:
              </h2>

              <h2 className="student-report__header__text">
                {this.state.training_data[0].username}
              </h2>

            </div>

            {/* <div className="student-report__header__student-picture-container">

              <i className="fas fa-user student-report__header__student-picture-icon"></i>

            </div> */}

          </header>

          <hr className="student-report__horizontal-rule"/>

          <div className="student-report__graphs-container">

            {this.getServiceGraphs(reportData)}

            <h4 className="student-report__graph-section">

              <i className="fas fa-dumbbell"></i>
              <span className="student-report__graph-section__text">Percepção Subjetiva de Esforço (PSE)</span>

            </h4>

            <SimpleBarGraph
              className="student-report__graph"
              name="PSE"
              data={reportData.pseData}
              height={this.getDefaultGraphHeight()}
              barColor="#b678ef"
              labelFormatter={(value) => value}
            />

          </div>

        </div>

      </DefaultMenuLayout>
    );
  }
}

export default StudentReport;
