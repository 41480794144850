import React from 'react';
import { PoseGroup } from 'react-pose';
import './student_checkin.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import ConfirmationWindow from '../components/confirmation_window';
import * as routes from '../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE} from '../constants';
import DefaultMenuButton from '../components/default_menu_button';
import DefaultMenuLayout from '../components/default_menu_layout';
import DefaultFilterInput from '../components/default_filter_input';
import UserList, {UserItem} from '../components/user_list';
import {getModels, getModel, postModel, deleteModel} from '../utils/functions';


class StudentCheckin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toNextClass: null,
      loading: true,
      students: [],
      nextClassList: [],
      hashedClassList: [],
      currentClassList: null,
      current_class_time: null,
      classStudentCheckinSet: new Set(),
      studentNameFilter: "",
      selectedStudent: null,
      selectedStatus: null,
      target_service: this.props.target_service,
      onCancelCheckinStudent: null,
      onConfirmClicked: false,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async getStudents() {
    return await getModels(routes.STUDENTS_GET_API);
  }

  async getCheckinStatus() {
    if(this.props.accessedByHash) {
      return await getModel(`${routes.HASHED_TRAINING_CLASS_CHECKIN_STATUS_GET_API}${this.props.match.params.authHash}`);
    }

    return await getModel(`${routes.STUDENTS_CHECKIN_STATUS_GET_API}${this.props.target_service}`);
  }

  async reloadCheckinStatus(returnUpdateState=false) {
    this.setState({
      loading: true
    });

    const update = {loading: false};

    let checkinStatus = this.getCheckinStatus();

    checkinStatus = await checkinStatus;

    if(checkinStatus) {
      if(this.props.accessedByHash) {
        update.hashedClassList = checkinStatus;
        update.classStudentCheckinSet = new Set(update.hashedClassList);
      }
      else {
        update.nextClassList = checkinStatus['next_class_list'];
        update.currentClassList = checkinStatus['current_class_list'];

        if(checkinStatus['current_class_time']) {
          update.current_class_time = checkinStatus['current_class_time'];
        }

        if(this.state.toNextClass !== null) {
          if(this.state.toNextClass) {
            if(update.nextClassList) {
              update.classStudentCheckinSet = new Set(update.nextClassList);
            }
          }
          else {
            if(update.currentClassList) {
              update.classStudentCheckinSet = new Set(update.currentClassList);
            }
          }
        }
        else if(checkinStatus['current_class_list'] === null) {
          update.toNextClass = true;

          if(update.nextClassList) {
            update.classStudentCheckinSet = new Set(update.nextClassList);
          }
        }
      }
    }

    if(returnUpdateState) {
      return update;
    }

    this.setState(update);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    let hashed_class;

    if(this.props.accessedByHash) {
      hashed_class = getModel(`${routes.TRAINING_DAY_GET_BY_HASH_API}${this.props.match.params.authHash}`);
    }

    let students = this.getStudents();

    let checkinStatusUpdate = await this.reloadCheckinStatus(true);

    const update = {
      ...checkinStatusUpdate,
      loading: false
    };

    students = await students;

    if(students) {
      update.students = students;
    }

    if(this.props.accessedByHash) {
      hashed_class = await hashed_class;

      if(hashed_class) {
        update.target_service = hashed_class.target_service;
      }
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getStudentItems() {
    const filteredStudents = this.state.students.filter((student) => student.name.toLocaleLowerCase().includes(this.state.studentNameFilter.toLocaleLowerCase()));

    if(filteredStudents.length <= 0) {
      return (
        <p className="student-checkin__not-found-text">Nenhum aluno encontrado que contenha este nome</p>
      );
    }

    return filteredStudents.map((student) => (
      <UserItem
        key={`student:${student.id}`}
        name={student.name}
      >

        {this.state.classStudentCheckinSet.has(student.id) ?
          <DefaultMenuButton
            className="student-checkin__user-action-button"
            onClick={() => this.setState({onCancelCheckinStudent: student})}
            text="Cancelar check-in"
            color="red"
          />:
          <DefaultMenuButton
            className="student-checkin__user-action-button"
            onClick={() => this.setState({selectedStudent: student})}
            text="Check-in"
            color="green"
          />
        }

      </UserItem>
    ));
  }

  async checkinStudent() {
    if(!this.state.selectedStudent || !this.state.selectedStatus || (this.state.toNextClass === null && !this.props.accessedByHash)) {
      return;
    }

    this.setState({
      loading: true
    });

    const data = {
      student_id: this.state.selectedStudent.id,
      physical_recuperation_status: parseInt(this.state.selectedStatus)
    };

    try {
      if(this.props.accessedByHash) {
        await postModel(`${routes.HASHED_CLASS_STUDENT_CHECKIN_POST_API}${this.props.match.params.authHash}`, data);
      }
      else {
        let postPath = this.state.toNextClass ? routes.NEXT_CLASS_STUDENT_CHECKIN_POST_API : routes.CURRENT_CLASS_STUDENT_CHECKIN_POST_API;

        data.target_service = this.props.target_service;

        await postModel(postPath, data);
      }
    }
    catch(errors) {
      let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

      // if(errors instanceof Array) {
      //   for(let error of errors) {
      //     if(error.code === 103) {
      //       for(let parameter of error.parameters) {
      //         if(parameter.name === 'email') {
      //           warningMessages.push('E-mail já cadastrado');
      //           highlights.push('email');
      //         }
      //       }
      //     }
      //   }
      // }

      this.setState({
        onConfirmClicked: true,
        confirmFailDescription: errorDescription,
        confirmFailed: true,
        confirmInProgress: false,
        loading: false
      });

      return;
    }

    if(this.props.accessedByHash) {
      window.location.reload(false);
    }
    else {
      this.props.history.replace(routes.MAIN_PATH);
    }
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onConfirmClicked) {
        return 'Falha ao confirmar checkin';
      }
      else if(this.state.onCancelCheckinStudent !== null) {
        return 'Falha ao cancelar checkin';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onCancelCheckinStudent !== null) {
        return 'Cancelando checkin';
      }
    }
    else {
      if(this.state.onCancelCheckinStudent !== null) {
        return 'Cancelar checkin';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onCancelCheckinStudent !== null) {
      return `Deseja realmente cancelar o checkin para o aluno ${this.state.onCancelCheckinStudent.name}?`;
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onCancelCheckinStudent !== null) {
      return `Cancelar checkin`;
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onConfirmClicked === true || this.state.onCancelCheckinStudent !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      onCancelCheckinStudent: null,
      onConfirmClicked: false,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.onCancelCheckinStudent !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(this.props.accessedByHash) {
          if(await deleteModel(`${routes.HASHED_CLASS_STUDENT_CHECKIN_DELETE_API}${this.state.onCancelCheckinStudent.id}/${this.props.match.params.authHash}`)) {
            this.setState({
              onCancelCheckinStudent: null,
              confirmInProgress: false
            });

            this.reloadCheckinStatus();
          }
        }
        else {
          let postPath = this.state.toNextClass ? `${routes.NEXT_CLASS_STUDENT_CHECKIN_DELETE_API}${this.props.target_service}/` : `${routes.CURRENT_CLASS_STUDENT_CHECKIN_DELETE_API}${this.props.target_service}/`;

          if(await deleteModel(`${postPath}${this.state.onCancelCheckinStudent.id}`)) {
            this.setState({
              onCancelCheckinStudent: null,
              confirmInProgress: false
            });

            this.reloadCheckinStatus();
          }
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
  }

  setTargetClass(toNextClass) {
    const update = {toNextClass};

    if(toNextClass) {
      if(this.state.nextClassList) {
        update.classStudentCheckinSet = new Set(this.state.nextClassList);
      }
    }
    else {
      if(this.state.currentClassList) {
        update.classStudentCheckinSet = new Set(this.state.currentClassList);
      }
    }

    this.setState(update);
  }

  studentHasRestriction() {
    if(!this.state.selectedStudent.restriction_allow_map) {
      return false;
    }

    return this.state.selectedStudent.restriction_allow_map[this.state.target_service] === false;
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

        <DefaultMenuLayout
          showBackButton={!this.props.accessedByHash || this.state.selectedStudent !== null}
          onBackClick={this.props.accessedByHash ? () => window.location.reload(false) : null}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="student-checkin">

            {(this.state.toNextClass === null && !this.props.accessedByHash) ?
              (
                <React.Fragment>

                  <h1 className="student-checkin__instructions">

                    Escolha a aula para realizar o checkin:

                  </h1>

                  <div className="student-checkin__options">

                    <div className="student-checkin__option-wrapper">

                      <DefaultMenuButton
                        onClick={() => this.setTargetClass(false)}
                        text={this.state.current_class_time || 'Aula atual'}
                      />

                    </div>

                    <div className="student-checkin__option-wrapper">

                      <DefaultMenuButton
                        onClick={() => this.setTargetClass(true)}
                        text="Próxima aula"
                        color="red"
                      />

                    </div>

                  </div>

                  <aside
                    key="student_early_checkin_link"
                    className="student-checkin__schedule"
                  >

                    <DefaultMenuButton
                      className="student-checkin__schedule__button"
                      linkTo={routes.EARLY_CHECKIN_PATH}
                      text={
                        <React.Fragment>
                          <i className="fas fa-clipboard-list student-checkin__schedule__button__icon"></i>
                          Agendados
                        </React.Fragment>
                      }
                    />

                  </aside>

                </React.Fragment>
              ):
              this.state.selectedStudent !== null ?
                (
                  <section className="student-checkin__checkin-form">

                    <header className="student-checkin__checkin-form__header">

                      <div className="student-checkin__checkin-form__header__student-name">

                        <h2 className="student-checkin__checkin-form__header__label">
                          <u>Aluno</u>:
                        </h2>

                        <h2 className="student-checkin__checkin-form__header__text">
                          {this.state.selectedStudent.name}
                        </h2>

                      </div>

                      <div className="student-checkin__checkin-form__header__student-picture-container">

                        <i className="fas fa-user student-checkin__checkin-form__header__student-picture-icon"></i>

                      </div>

                    </header>

                    <hr className="student-checkin__horizontal-rule"/>

                    {this.studentHasRestriction() &&
                      <div className="student-checkin__warning-container">

                        <i className="fas fa-exclamation student-checkin__warning-container__icon"></i>

                        <p className="student-checkin__warning-container__text">

                          Infelizmente seu plano contratado não lhe permite realizar aulas neste horário. Em caso de qualquer dúvida,
                          por favor, entre em contato com seu treinador.

                        </p>

                      </div>
                    }

                    {this.state.selectedStudent.contract_expired ?
                      <div className="student-checkin__warning-container">

                        <i className="fas fa-exclamation student-checkin__warning-container__icon"></i>

                        <p className="student-checkin__warning-container__text">

                          Seu contrato venceu há poucos dias. Por favor, entre em contato com seu treinador para renová-lo
                          caso tenha a intenção de continuar com seu treinamento.

                        </p>

                      </div>:
                      this.state.selectedStudent.contract_not_accepted ?
                        <div className="student-checkin__warning-container">

                          <i className="fas fa-exclamation student-checkin__warning-container__icon"></i>

                          <p className="student-checkin__warning-container__text">

                            Você possui um CONTRATO PENDENTE de aceite. Certifique-se de aceitá-lo o quanto antes para
                            que você possa continuar usufruindo de nossos serviços da melhor forma possível.

                          </p>

                        </div>:
                        null
                    }

                    {(!this.studentHasRestriction() && this.state.selectedStudent.has_registered) &&
                      <React.Fragment>

                        <div className="student-checkin__checkin-form__scale">

                          <h3 className="student-checkin__checkin-form__scale__title">

                            <span className="student-checkin__checkin-form__scale__highlighted-title">
                              {this.state.screenWidth > 510 ? 'Escala de recuperação:' : 'Recuperação:'}
                            </span>

                            Selecione como você está se sentindo

                          </h3>

                          <div className="student-checkin__overlay-note">

                            <p className="student-checkin__overlay-note__text">
                              A escala de <strong>percepção subjetiva de recuperação</strong>, ou <strong>PSR</strong>, deve ser preenchido somente com base na sua
                              fadiga ou disposição <strong>física</strong>, e não com base no seu ânimo ou cansaço psicológico.
                            </p>

                          </div>

                          <ul className="student-checkin__checkin-form__scale__ranges">

                            <li className="student-checkin__checkin-form__scale__range" style={{flex: 3}}>

                              <div className="student-checkin__checkin-form__scale__range__image--3">

                                <i className="fas fa-dizzy student-checkin__checkin-form__scale__range__image-icon"></i>

                                <p className="student-checkin__checkin-form__scale__range__image__text">
                                  Expectativa de diminuição no desempenho
                                </p>

                              </div>

                              <div className="student-checkin__checkin-form__scale__range__options">

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--1"
                                  onClick={() => this.setState({selectedStatus: 1})}
                                  disabled={this.state.selectedStatus === 1}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    1
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Extremo cansaço
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--2"
                                  onClick={() => this.setState({selectedStatus: 2})}
                                  disabled={this.state.selectedStatus === 2}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    2
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Cansado
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--3"
                                  onClick={() => this.setState({selectedStatus: 3})}
                                  disabled={this.state.selectedStatus === 3}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    3
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Um pouco cansado
                                  </p>

                                </button>

                              </div>

                              {/* <div className="student-checkin__checkin-form__scale__range__group-description">

                                <p className="student-checkin__checkin-form__scale__range__group-description__text">

                                </p>

                              </div> */}

                            </li>

                            <li className="student-checkin__checkin-form__scale__range" style={{flex: 4}}>

                              <div className="student-checkin__checkin-form__scale__range__image--7">

                                <i className="fas fa-meh student-checkin__checkin-form__scale__range__image-icon"></i>

                                <p className="student-checkin__checkin-form__scale__range__image__text">
                                  Expectativa de similaridade no desempenho
                                </p>

                              </div>

                              <div className="student-checkin__checkin-form__scale__range__options">

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--4"
                                  onClick={() => this.setState({selectedStatus: 4})}
                                  disabled={this.state.selectedStatus === 4}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    4
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Pouco recuperado
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--5"
                                  onClick={() => this.setState({selectedStatus: 5})}
                                  disabled={this.state.selectedStatus === 5}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    5
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Em recuperação
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--6"
                                  onClick={() => this.setState({selectedStatus: 6})}
                                  disabled={this.state.selectedStatus === 6}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    6
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Quase recuperado
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--7"
                                  onClick={() => this.setState({selectedStatus: 7})}
                                  disabled={this.state.selectedStatus === 7}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    7
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Recuperado
                                  </p>

                                </button>

                              </div>

                              {/* <div className="student-checkin__checkin-form__scale__range__group-description">

                                <p className="student-checkin__checkin-form__scale__range__group-description__text">

                                </p>

                              </div> */}

                            </li>

                            <li className="student-checkin__checkin-form__scale__range" style={{flex: 3}}>

                              <div className="student-checkin__checkin-form__scale__range__image--10">

                                <i className="fas fa-laugh-beam student-checkin__checkin-form__scale__range__image-icon"></i>

                                <p className="student-checkin__checkin-form__scale__range__image__text">
                                  Expectativa de um bom desempenho
                                </p>

                              </div>

                              <div className="student-checkin__checkin-form__scale__range__options">

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--8"
                                  onClick={() => this.setState({selectedStatus: 8})}
                                  disabled={this.state.selectedStatus === 8}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    8
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Bem recuperado
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--9"
                                  onClick={() => this.setState({selectedStatus: 9})}
                                  disabled={this.state.selectedStatus === 9}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    9
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Muito bem recuperado
                                  </p>

                                </button>

                                <button
                                  className="student-checkin__checkin-form__scale__range__option--10"
                                  onClick={() => this.setState({selectedStatus: 10})}
                                  disabled={this.state.selectedStatus === 10}
                                >

                                  <p className="student-checkin__checkin-form__scale__range__option-number">
                                    10
                                  </p>

                                  <p className="student-checkin__checkin-form__scale__range__option-text--flex">
                                    Altamente energético
                                  </p>

                                </button>

                              </div>

                              {/* <div className="student-checkin__checkin-form__scale__range__group-description">

                                <p className="student-checkin__checkin-form__scale__range__group-description__text">

                                </p>

                              </div> */}

                            </li>

                          </ul>

                        </div>

                        <div className="student-checkin__checkin-form__confirm-container">

                          <DefaultMenuButton
                            className="student-checkin__checkin-form__confirm-button"
                            onClick={() => this.checkinStudent()}
                            text="Confimar"
                            color="green"
                            disabled={this.state.selectedStatus === null}
                          />

                        </div>

                      </React.Fragment>
                    }

                  </section>
                ):
                (

                  <React.Fragment>

                    <h1 className="student-checkin__instructions">

                      Encontre seu cadastro e confirme sua presença:

                    </h1>

                    <div className="student-checkin__user-select-container">

                      <DefaultFilterInput
                        name="name"
                        label={this.state.screenWidth > 680 ? 'Digite seu nome:' : 'Nome:'}
                        placeholder={this.state.screenWidth > 680 ? 'Digite as 2 primeiras letras de seu nome' : 'Digite seu nome'}
                        autoComplete="off"
                        value={this.state.studentNameFilter}
                        handleInputChange={(event) => this.setState({studentNameFilter: event.target.value})}
                      />

                    </div>

                    {this.state.studentNameFilter.length > 1 &&
                      <UserList className="student-checkin__user-list">

                        {this.getStudentItems()}

                      </UserList>
                    }

                    {!this.props.accessedByHash ?
                      <aside
                        key="student_early_checkin_link"
                        className="student-checkin__schedule"
                      >

                        <DefaultMenuButton
                          className="student-checkin__schedule__button"
                          linkTo={routes.EARLY_CHECKIN_PATH}
                          text={
                            <React.Fragment>
                              <i className="fas fa-clipboard-list student-checkin__schedule__button__icon"></i>
                              Agendados
                            </React.Fragment>
                          }
                        />

                      </aside>:
                      <aside
                        key="student_early_checkin_link"
                        className="student-checkin__schedule"
                      >

                        <DefaultMenuButton
                          className="student-checkin__schedule__button"
                          linkTo={`${routes.EARLY_CHECKIN_PATH}?service=${this.state.target_service}`}
                          text={
                            <React.Fragment>
                              <i className="fas fa-clipboard-list student-checkin__schedule__button__icon"></i>
                              Agendados
                            </React.Fragment>
                          }
                        />

                      </aside>
                    }

                  </React.Fragment>
                )
            }

          </div>

        </DefaultMenuLayout>

      </React.Fragment>
    );
  }
}

export default StudentCheckin;
