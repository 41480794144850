import React from 'react';
import { Link } from 'react-router-dom';
import './default_header.scss';
import logo from '../images/logo_fyd_club.png';
import * as routes from '../constants';
import {LOCAL_CACHE_DEVICE_IDENTIFIER_KEY} from '../constants';

class DefaultHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
      isbluetoothAvailable: false
    };
  }

  async componentDidMount() {
    this.props.bluetoothDevices.isBluetoothAvailable().then((isAvailable) => {
      this.setState({isbluetoothAvailable: isAvailable});
    });
  }

  getDeviceOptions() {
    let localCache = localStorage.getItem(LOCAL_CACHE_DEVICE_IDENTIFIER_KEY);

    if(localCache && JSON.parse(localCache).length > 0) {
      return (
        <React.Fragment>

          <Link
            className="default-header__menu__option"
            to={routes.SWITCH_UNIT_PATH}
          >

            <p className="default-header__menu__option__text">
              Unidades
            </p>

          </Link>

          <hr className="default-header__horizontal-rule"></hr>

        </React.Fragment>
      );
    }

    return null;
  }

  render() {
    return (
      <header className={`default-header ${this.props.className}`}>

        <div className="default-header__left-menu-bar">

          {this.props.showBackButton &&
            <button
              className="default-header__back-button"
              onClick={() => this.props.onBackClick ? this.props.onBackClick() : this.props.history.goBack()}
            >
              <i className="fas fa-angle-left default-header__back-button__icon"></i>
              <span>Voltar</span>
            </button>
          }

          {this.props.showOperationalTasksLink &&
            <Link
              className="default-header__operational-tasks-button"
              to={routes.CLASSROOM_OPERATIONAL_TASKS_PAGE}
            >

              <i className="fa-solid fa-list-check default-header__operational-tasks-button__icon"></i>

            </Link>
          }

        </div>

        <div className="default-header__logo-wrapper">

          <img className="default-header__logo" src={logo} alt="Logo da FYD" />

        </div>

        <div className="default-header__right-menu-bar">

          {this.props.token &&
            <button
              className="default-header__menu-button"
              onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
            >
              <i className="fas fa-bars default-header__menu-button__icon"></i>

              {this.state.menuVisible &&
                <aside
                  className="default-header__menu"
                  onClick={(event) => {event.stopPropagation();}}
                >

                  <p className="default-header__menu__header-text">
                    {this.props.token.unit.name}
                  </p>

                  <hr className="default-header__horizontal-rule"></hr>

                  {this.getDeviceOptions()}

                  <p className="default-header__menu__header-text">
                    {this.props.target_service}
                  </p>

                  <Link
                    className="default-header__menu__option"
                    to={routes.SWITCH_SERVICE_PATH}
                  >

                    <p className="default-header__menu__option__text">
                      Serviços
                    </p>

                  </Link>

                  <hr className="default-header__horizontal-rule"></hr>

                  <Link
                    className="default-header__menu__option"
                    to={routes.NEW_ACCESS_REQUEST_PATH}
                  >

                    <p className="default-header__menu__option__text">
                      Novo acesso
                    </p>

                  </Link>

                  {this.state.isbluetoothAvailable &&
                    <React.Fragment>

                      <hr className="default-header__horizontal-rule"></hr>

                      <Link
                        className="default-header__menu__option"
                        to={routes.INITIATE_DEVICE_CONNECTION_PATH}
                        >

                        <p className="default-header__menu__option__text">
                          Frequencímetros
                        </p>

                      </Link>

                    </React.Fragment>
                  }

                </aside>
              }
            </button>
          }

        </div>

      </header>
    );
  }
}

export default DefaultHeader;
