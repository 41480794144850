import React from 'react';
import { PoseGroup } from 'react-pose';
import {FadeContainer, VerticalAccordionContainer} from '../../utils/pose_containers';
import PreLoader from '../../utils/preloader';
import * as routes from '../../constants';
import {DEFAULT_UNKNOWN_ERROR_MESSAGE,
        TRAINING_GROUP_WORKOUT_KEY,
        TRAINING_EXECUTION_METHOD_FIXED_REPS,
        TRAINING_EXECUTION_METHOD_PIRAMIDAL,
        TRAINING_EXECUTION_METHOD_QRP,
        CLOCK_METHOD_STOPWATCH,
        CLOCK_METHOD_TIMER,
        CLOCK_METHOD_TABATA,
        CLOCK_METHOD_SERIES,
        CLOCK_METHOD_CUSTOM} from '../../constants';
import DefaultMenuButton from '../../components/default_menu_button';
import TrainingClock from '../../components/training_clock';
import logo from '../../images/logo_fyd_club.png';
import ConfirmationWindow from '../../components/confirmation_window';
import OverlayWindow from '../../components/overlay_window';
import WarningMessage from '../../components/warning_message';
import {deleteModel,
        getModel,
        getModels,
        postModel,
        patchModel,
        getAsLocalDate,
        setUrlParameters,
        parseTextForIcons,
        getLocalTimeIsoString,
        getAbbreviatedName} from '../../utils/functions';
import startCount from '../../sounds/start_count_down.wav';
import startSound from '../../sounds/start_sound.wav';
import minuteTurn from '../../sounds/minute_turn_soft.mp3';
import endSound from '../../sounds/end_time.wav';
import DefaultInput from '../../utils/default_input';
import LoadingIcon from '../../components/loading_icon'
import UserList, {UserItem} from '../../components/user_list';
import DefaultFilterInput from '../../components/default_filter_input';
import './class_board.scss';

import birthdayHat_1 from '../../images/achievements/birthday_hat_1.svg';
import birthdayHat_2 from '../../images/achievements/birthday_hat_2.svg';
import birthdayHat_3 from '../../images/achievements/birthday_hat_3.svg';
import birthdayHat_4 from '../../images/achievements/birthday_hat_4.svg';
import birthdayHat_5 from '../../images/achievements/birthday_hat_5.svg';
import birthdayHat_6 from '../../images/achievements/birthday_hat_6.svg';
import birthdayHat_7 from '../../images/achievements/birthday_hat_7.svg';
import birthdayHat_8 from '../../images/achievements/birthday_hat_8.svg';
import birthdayHat_9 from '../../images/achievements/birthday_hat_9.svg';
import birthdaySign from '../../images/achievements/birthday_sign.svg';
import confetti from '../../images/achievements/confetti.svg';
import userFrameWood from '../../images/student_rank/user_frame_wood.svg';
import userFrameBronze from '../../images/student_rank/user_frame_bronze.svg';
import userFrameSilver from '../../images/student_rank/user_frame_silver.svg';
import userFrameGold from '../../images/student_rank/user_frame_gold.svg';


// CONSTANTS
const QRP_CYCLES_VISIBLE_NUMBER = 3;

const CLOCK_STATE_PAUSED = 'PAUSED';
const CLOCK_STATE_PLAYING = 'PLAYING';
const CLOCK_STATE_STOPPED = 'STOPPED';
const CLOCK_STATE_FINISHED = 'FINISHED';

const CLOCK_PHASE_NORMAL = 'NORMAL';
const CLOCK_PHASE_PREPARING = 'PREPARING';
const CLOCK_PHASE_RESTING = 'RESTING';
const CLOCK_PHASE_POST_TIME = 'POST_TIME';

const FINAL_SECOND_COUNT = 5;
const TABATA_FINAL_SECOND_COUNT = 1;
const PERSONALIZED_FINAL_SECOND_COUNT = 1;

const STUDENT_LIST_ORDER_PRIORITY = {
  checkin: "CHECKIN",
  rac: "RAC",
  disc: "DISC"
};

const BIRTHDAY_HAT_OPTIONS = [
  birthdayHat_1,
  birthdayHat_2,
  birthdayHat_3,
  birthdayHat_4,
  birthdayHat_5,
  birthdayHat_6,
  birthdayHat_7,
  birthdayHat_8,
  birthdayHat_9
];

// AUXILIAR FUNCTIONS
function getFormatedTime(minutes) {
  const secondsRemaining = Math.floor((minutes*60) % 60);

  return `${Math.floor(minutes)}'` + (secondsRemaining > 0 ? ` ${secondsRemaining}''` : '');
}

function getDayId(date) {
  let dayId = date.getDay() - 1;

  if(dayId < 0) {
    dayId = 6;
  }

  return dayId;
}


class ClassBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training: null,
      students: [],
      early_checkin_ids: [],
      early_checkins: [],
      experimental_classes: [],
      training_times: [],
      devices: [],
      hashedClassId: null,
      hashedClassTime: null,
      hashedClassTimeSelected: false,
      trainingPeriodSelected: null,
      trainingDaySelected: null,
      trainingGroupSelected: null,
      overviewSelectedGroup: null,
      classIsCompleted: false,
      studentListVisible: this.props.isGymService,
      studentListOrderPriority: STUDENT_LIST_ORDER_PRIORITY.checkin,
      clockExpanded: false,
      currentClockScale: 1,
      clockParametersMap: new Map(),
      clockMusicMap: new Map(),
      overlayTrainingGroupSelected: null,
      studentOverlayShowInputs: false,
      studentOverlayLoading: false,
      studentOverlaySelected: null,
      studentOverlayReferenceData: null,
      studentOverlayOriginalReferenceData: null,
      loading: true,
      onFinishClass: false,
      showGenericWarning: null,
      onSelectActiveGymTraining: false,
      onUpdateStudentReference: false,
      onAbortClicked: false,
      onBackClicked: false,
      onCancelCheckinStudent: null,
      onConfirmExperimentalClass: null,
      onCancelExperimentalClass: null,
      onResetGymStudentTraining: null,
      onResetGymExperimentalTraining: null,
      userToDisconnectHrDevice: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      selectedExercise: null,
      exercisevideoContainerSize: null,
      isbluetoothAvailable: false,
      studentDataMap: new Map(),
      studentHrMap: new Map(),
      hrDeviceMap: new Map(),
      hrSyncStudentSelected: null,
      hrSyncInProgress: false,
      hrClockContainerSizeMultiplier: 1,
      showDeviceErrorMessage: false,
      deviceErrorMessage: '',
      showHrView: false,
      showCoachSelector: false,
      gymStudentSelected: null,
      gymExperimentalSelected: null,
      gymPeriodNoteVisible: false,
      gymStudentNavigationMap: new Map(),
      gymExperimentalNavigationMap: new Map(),
      gymStudentActivePersonalPeriodMap: new Map(),
      experimentalPeriods: [],
      qrCodeExpanded: false,
      coaches: [],
      selectedCoaches: [],
      newSelectedCoaches: [],
      coachNameFilter: "",
      currentDateTimeText: "00:00",
      screenWidth: window.innerWidth,
    };

    // for legacy browsers
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    this.audioContext = new AudioContext();
    this.audioContext.suspend();

    this.startCountAudioBuffer = null;
    this.startAudioBuffer = null;
    this.minuteTurnAudioBuffer = null;
    this.endAudioBuffer = null;

    this.currentSoundId = 1;
    this.activeSoundIdSet = new Set();

    this.soundPlayTimeMap = new Map();
    this.minSoundReplayPeriod = 200;

    this.mainRef = React.createRef();

    this.exerciseVideoContainer = null;

    this.clockElement = null;
    this.clockTimerElement = null;
    this.clockLapElement = null;

    this.willRescaleClock = false;

    this.mayUpdateDateTimeText = false;
    this.mayUpdateClock = false;

    this.clockRef = (element) => {
      if (element == null) {
        this.clockElement = null;
        this.clockTimerElement = null;
        this.clockLapElement = null;
      }
      else {
        this.clockElement = element;

        const clockLapSelection = element.getElementsByClassName('class-board__clock__lap');
        if (clockLapSelection.length > 0) {
          this.clockLapElement = clockLapSelection[0];
        }
        else {
          this.clockLapElement = null;
        }

        this.clockTimerElement = element.getElementsByClassName('clock-timer')[0];
      }
    };

    this.unifiedHrClockContainerElement = null;

    this.unifiedHrClockContainerRef = (element) => {
      if (element == null) {
        this.unifiedHrClockContainerElement = null;
      }
      else {
        this.unifiedHrClockContainerElement = element;
      }
    };

    this.trainingGroupTitleElement = null;
    this.trainingGroupTitleRef = (element) => {
      if (element == null) {
        this.trainingGroupTitleElement = null;
      }
      else {
        this.trainingGroupTitleElement = element;
      }
    };

    this.exerciseOverlayContainerRef = (element) => {
      this.exerciseVideoContainer = element;

      if(element) {
        this.setState({exercisevideoContainerSize: [element.clientWidth, element.clientHeight]});
      }
    };

    this.studentBirthdayHatSelection = new Map();
  }

  isFullscreen(fromDocument=false) {
    if(fromDocument) {
      return document.fullscreenElement != null;
    }

    return this.state.clockExpanded;
  }

  async loadAudioFile(audioFile) {
    const response = await fetch(audioFile);
    const fileBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(fileBuffer);
    return audioBuffer;
  }

  async getCurrentTrainingClass() {
    if(this.props.accessedByHash) {
      let training_day;

      if(this.props.basicAccess) {
        training_day = await getModel(`${routes.EXPERIMENTAL_TRAINING_DAY_GET_BY_HASH_API}${this.props.match.params.authHash}`);
      }
      else {
        training_day = await getModel(`${routes.TRAINING_DAY_GET_BY_HASH_API}${this.props.match.params.authHash}`);
      }

      if(training_day) {
        return {
          training_day,
          repetition_index: 1
        };
      }

      return training_day
    }
    else {
      return await getModel(`${routes.CURRENT_TRAINING_CLASS_GET_API}${this.props.target_service}`);
    }
  }

  async getStudents() {
    if(this.props.basicAccess) {
      return null;
    }

    if(this.props.accessedByHash) {
      return await getModels(`${routes.HASHED_TRAINING_CLASS_STUDENTS_GET_API}${this.props.match.params.authHash}`);
    }

    return await getModels(`${routes.CURRENT_TRAINING_CLASS_STUDENTS_GET_API_V3}${this.props.target_service}`);
  }

  async getExperimentalPeriods() {
    if(this.props.basicAccess || !this.props.isGymService) {
      return [];
    }

    return await getModels(routes.DEFAULT_EXPERIMENTAL_PERIODS_GET_API);
  }

  async getTrainingTimes() {
    if(this.props.basicAccess) {
      return [];
    }

    if(this.props.accessedByHash) {
      const today = new Date();

      return await getModels(`${routes.TRAINING_TIMES_GET_API}${getDayId(today)}`);
    }

    return [];
  }

  async getDevices() {
    if(this.props.basicAccess) {
      return null;
    }

    const parameters = {};

    if(this.props.target_service) {
      parameters.target_service = this.props.target_service;
    }

    return await getModels(setUrlParameters(routes.WEARABLE_DEVICES_GET_API, parameters));
  }

  async getCoaches() {
    if(this.props.basicAccess || this.props.accessedByHash) {
      return [];
    }

    return await getModels(routes.COACHES_GET_API);
  }

  async loadStudentActivePersonalTraining(student) {
    const update = {};

    let shouldUpdate = false;
    let active_personal_training = null;

    if (!this.state.gymStudentActivePersonalPeriodMap.has(student.id)) {
      active_personal_training = await getModel(`${routes.STUDENT_BASE_API}${student.id}${routes.STUDENT_ACTIVE_PERSONAL_TRAINING_GET_API}`);

      if (active_personal_training) {
        this.state.gymStudentActivePersonalPeriodMap.set(student.id, active_personal_training);
      }
      else {
        this.state.gymStudentActivePersonalPeriodMap.set(student.id, null);
      }

      update.gymStudentActivePersonalPeriodMap = new Map(this.state.gymStudentActivePersonalPeriodMap);
      shouldUpdate = true;
    }
    else {
      active_personal_training = this.state.gymStudentActivePersonalPeriodMap.get(student.id);
    }

    if (student.active_gym_training_day_id !== null && active_personal_training && active_personal_training.training_period) {
      const trainingDay = active_personal_training.training_period.training_days.find((entry) => entry.id === student.active_gym_training_day_id);

      if (trainingDay) {
        const trainingPeriodSelected = active_personal_training.training_period;
        const trainingDaySelected = trainingDay;
        let trainingGroupSelected = null;

        if (this.state.gymStudentSelected !== null && this.state.gymStudentSelected.id === student.id) {
          update.trainingPeriodSelected = trainingPeriodSelected;
          update.trainingDaySelected = trainingDaySelected;

          if (this.state.trainingDaySelected !== null && this.state.trainingDaySelected.id === trainingDaySelected.id) {
            trainingGroupSelected = this.state.trainingGroupSelected;
          }

          update.trainingGroupSelected = trainingGroupSelected;
        }

        this.state.gymStudentNavigationMap.set(student.id, {
          trainingPeriodSelected,
          trainingDaySelected,
          trainingGroupSelected,
        });
        update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      this.setState(update);
    }
  }

  async reloadStudents(changeLoading=true) {
    const update = {};

    if(changeLoading) {
      this.setState({
        loading: true
      });

      update.loading = false;
    }

    let students = this.getStudents();

    students = await students;

    if(students && !this.props.basicAccess) {
      update.students = students.checkin;
      update.experimental_classes = students.experimental_classes;

      if(this.props.accessedByHash && this.state.hashedClassTimeSelected && this.state.hashedClassTime !== false) {
        update.early_checkins = await getModels(`${routes.EARLY_CHECKIN_STUDENTS_GET_API}${this.state.trainingDaySelected.target_service}/${this.state.hashedClassTime}`)
        update.early_checkin_ids = update.early_checkins.map((entry) => entry.id);
      }
      else {
        if(students.early_checkin_ids) {
          update.early_checkin_ids = students.early_checkin_ids;
          update.early_checkins = students.early_checkins;
        }
      }

      update.students.sort((a, b) => {
        const aValue = a.note !== null ? 1 : 0;
        const bValue = b.note !== null ? 1 : 0;

        if(aValue < bValue) {
          return 1;
        }
        else if(aValue > bValue) {
          return -1;
        }

        if(a.timestamp < b.timestamp) {
          return -1;
        }
        else if(a.timestamp > b.timestamp) {
          return 1;
        }

        return 0;
      });
    }

    this.setState(update);

    if (this.props.isGymService && !this.props.basicAccess) {
      const studentIdSet = new Set();

      for (const student of [...update.students, ...update.early_checkins]) {
        if (!studentIdSet.has(student.id)) {
          studentIdSet.add(student.id);
          this.loadStudentActivePersonalTraining(student);
        }
      }
    }
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    if(this.exerciseVideoContainer !== null) {
      update.exercisevideoContainerSize = [this.exerciseVideoContainer.clientWidth, this.exerciseVideoContainer.clientHeight];
    }

    this.setClockToRescale();

    this.setState(update);
  }

  onChangeFullscreen() {
    if(document.fullscreenElement === null) {
      this.setState({clockExpanded: false});
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    try{
      const update = {loading: false};

      const startCountAudioBuffer = this.loadAudioFile(startCount);
      const startAudioBuffer = this.loadAudioFile(startSound);
      const minuteTurnAudioBuffer = this.loadAudioFile(minuteTurn);
      const endAudioBuffer = this.loadAudioFile(endSound);

      let training = this.getCurrentTrainingClass();
      let students = this.reloadStudents(false);
      let training_times = this.getTrainingTimes();
      let devices = this.getDevices();
      let experimentalPeriods = this.getExperimentalPeriods();

      training = await training;

      if(training) {
        update.training = training;

        if (update.training.coaches) {
          update.selectedCoaches = [...update.training.coaches];
        }

        if(!this.props.isGymService && training.training_day.group_associations.length > 0) {
          update.trainingDaySelected = training.training_day;
          update.overviewSelectedGroup = training.training_day.group_associations[0];

          update.clockMusicMap = new Map();

          for (const group of training.training_day.group_associations) {
            if (group.has_clock && typeof group.music_file_url !== 'undefined' && group.music_file_url !== null) {
              const audioFile = new Audio(group.music_file_url);
              audioFile.crossOrigin = 'anonymous';
              const audioSource = this.audioContext.createMediaElementSource(audioFile);
              const gainNode = this.audioContext.createGain();

              audioSource.connect(gainNode).connect(this.audioContext.destination);

              update.clockMusicMap.set(group.id, {audioFile, audioSource, gainNode});

              this.setState({clockParametersMap: new Map(this.state.clockParametersMap)});
            }
          }
        }

        training_times = await training_times;

        if(training_times) {
          update.training_times = training_times;
        }

        devices = await devices;

        if(devices) {
          update.devices = devices;
        }

        await students;

        experimentalPeriods = await experimentalPeriods;

        if(experimentalPeriods) {
          update.experimentalPeriods = experimentalPeriods;
        }

        this.startCountAudioBuffer = await startCountAudioBuffer;
        this.startAudioBuffer = await startAudioBuffer;
        this.minuteTurnAudioBuffer = await minuteTurnAudioBuffer;
        this.endAudioBuffer = await endAudioBuffer;

        this.setState(update);

        this.resizeListener = () => this.updateSize();
        this.changeFullscreenListener = () => this.onChangeFullscreen();

        window.addEventListener("resize", this.resizeListener);
        window.addEventListener("fullscreenchange", this.changeFullscreenListener);
      }
      else {
        if(this.props.basicAccess) {
          window.location.replace(`${window.location.protocol}//${window.location.host.replace('lousa.', 'aluno.').replace(':3000', ':5000')}`);
        }
        else {
          this.props.history.replace(routes.MAIN_PATH);
        }
      }
    }
    catch(errors) {
      // if(this.props.basicAccess) {
      //   window.location.replace(`${window.location.protocol}//${window.location.host.replace('lousa.', 'aluno.').replace(':3000', ':5000')}`);
      // }
      // else {
      //   this.props.history.replace(routes.MAIN_PATH);
      // }
    }

    this.props.bluetoothDevices.isBluetoothAvailable().then((isAvailable) => {
      this.setState({isbluetoothAvailable: isAvailable});
    });

    this.mayUpdateDateTimeText = true;
    this.mayUpdateClock = true;

    requestAnimationFrame(this.updateDateTimeText.bind(this));
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.showHrView !== this.state.showHrView &&
        this.state.showHrView &&
        !this.props.isGymService &&
        this.mayShowUnifiedGroupSelection()) {
      this.updateUnifiedClockSizes();
    }
  }

  componentWillUnmount() {
    this.mayUpdateDateTimeText = false;
    this.mayUpdateClock = false;

    this.audioContext.close();

    this.state.studentHrMap.clear();
    this.state.hrDeviceMap.clear();
    this.state.studentDataMap.clear();
    this.props.bluetoothDevices.disconnectAllDevices();

    window.removeEventListener("resize", this.resizeListener);
    window.removeEventListener("fullscreenchange", this.changeFullscreenListener);
  }

  updateDateTimeText() {
    if (!this.mayUpdateDateTimeText) {
      return;
    }

    this.setState({currentDateTimeText: getLocalTimeIsoString(new Date(), false)});

    requestAnimationFrame(this.updateDateTimeText.bind(this));
  }

  getHeaderTitle() {
    if(this.props.isGymService) {
      if(this.state.gymStudentSelected !== null) {
        if(this.state.trainingDaySelected !== null) {
          return this.state.gymStudentSelected.name;
        }

        return 'Seleção de treino';
      }
      else if(this.state.gymExperimentalSelected !== null) {
        if(this.state.trainingDaySelected !== null) {
          return this.state.gymExperimentalSelected.name;
        }

        return 'Seleção de treino';
      }

      return 'Musculação';
    }

    if(this.state.trainingGroupSelected !== null) {
      return parseTextForIcons(this.state.trainingGroupSelected.name, 'header:group:name', 'class-board__parsed-text');
    }

    return 'Visão geral';
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.onBackClicked) {
        return 'Falha ao sair da aula';
      }
      else if(this.state.onAbortClicked) {
        return 'Falha ao abortar aula';
      }
      else if(this.state.onCancelCheckinStudent !== null) {
        return 'Falha ao cancelar checkin';
      }
      else if(this.state.onConfirmExperimentalClass !== null) {
        return 'Falha ao alterar presença de aula experimental';
      }
      else if(this.state.onCancelExperimentalClass !== null) {
        return 'Falha ao alterar presença de aula experimental';
      }
      else if(this.state.onResetGymStudentTraining !== null || this.state.onResetGymExperimentalTraining !== null) {
        return 'Falha ao resetar treino selecionado';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Falha ao salvar dados de PR de aluno';
      }
      else if(this.state.onSelectActiveGymTraining) {
        return 'Falha ao selecionar treino de aluno';
      }
      else if(this.state.onFinishClass) {
        return 'Falha ao finalizar aula';
      }
      else if(this.state.userToDisconnectHrDevice !== null) {
        return 'Falha ao desconectar dispositivo';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.onBackClicked) {
        return 'Saindo da aula';
      }
      else if(this.state.onAbortClicked) {
        return 'Abortando aula';
      }
      else if(this.state.onCancelCheckinStudent !== null) {
        return 'Cancelando checkin';
      }
      else if(this.state.onConfirmExperimentalClass !== null) {
        return 'Alterando presença';
      }
      else if(this.state.onCancelExperimentalClass !== null) {
        return 'Alterando presença';
      }
      else if(this.state.onResetGymStudentTraining !== null || this.state.onResetGymExperimentalTraining !== null) {
        return 'Resetando treino selecionado';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Salvando dados';
      }
      else if(this.state.onFinishClass) {
        return 'Finalizando aula';
      }
      else if(this.state.userToDisconnectHrDevice !== null) {
        return 'Disconectando dispositivo';
      }
    }
    else {
      if(this.state.onBackClicked) {
        return 'Sair da aula';
      }
      else if(this.state.onAbortClicked) {
        return 'Abortar aula';
      }
      else if(this.state.onCancelCheckinStudent !== null) {
        return 'Cancelar checkin';
      }
      else if(this.state.onConfirmExperimentalClass !== null) {
        return 'Aula experimental';
      }
      else if(this.state.onCancelExperimentalClass !== null) {
        return 'Aula experimental';
      }
      else if(this.state.onResetGymStudentTraining !== null || this.state.onResetGymExperimentalTraining !== null) {
        return 'Resetar treino';
      }
      else if(this.state.onUpdateStudentReference) {
        return 'Salvar dados de PR';
      }
      else if(this.state.onFinishClass) {
        return 'Finalizar aula';
      }
      else if(this.state.showGenericWarning !== null) {
        return 'Alunos sem treino selecionado';
      }
      else if(this.state.userToDisconnectHrDevice !== null) {
        return 'Desconectar dispositivo';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.onBackClicked) {
      return 'Deseja realmente sair da lousa da aula?';
    }
    else if(this.state.onAbortClicked) {
      return 'Deseja realmente cancelar a aula? Todos os dados relacionados serão ' +
             'perdidos e os alunos terão de realizar o checkin novamente.';
    }
    else if(this.state.onCancelCheckinStudent !== null) {
      return `Deseja realmente cancelar o checkin para o aluno ${this.state.onCancelCheckinStudent.name}?`;
    }
    else if(this.state.onConfirmExperimentalClass !== null) {
      return `Alterar presença de ${this.state.onConfirmExperimentalClass.name} para COMPARECEU?`;
    }
    else if(this.state.onCancelExperimentalClass !== null) {
      return `Alterar presença de ${this.state.onCancelExperimentalClass.name} para FALTOU?`;
    }
    else if(this.state.onResetGymStudentTraining !== null) {
      return `Deseja realmente resetar o treino selecionado do aluno ${this.state.onResetGymStudentTraining.name}?`;
    }
    else if(this.state.onResetGymExperimentalTraining !== null) {
      return `Deseja realmente resetar o treino selecionado do aluno experimental ${this.state.onResetGymExperimentalTraining.name}?`;
    }
    else if(this.state.onUpdateStudentReference) {
      return `Deseja realmente atualizar os dados de PR do aluno?`;
    }
    else if(this.state.onFinishClass) {
      return 'Deseja realmente finalizar a aula? Esta operação é irreversível. Certifique-se de que todos os alunos ' +
             'realizaram o checkin antes de prosseguir.';
    }
    else if(this.state.showGenericWarning !== null) {
      return this.state.showGenericWarning;
    }
    else if(this.state.userToDisconnectHrDevice !== null) {
      return 'O dispositivo vinculado com o usuário será desconectado.';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.onBackClicked) {
      return 'Sair da aula';
    }
    else if(this.state.onAbortClicked) {
      return 'Abortar aula';
    }
    else if(this.state.onCancelCheckinStudent !== null) {
      return `Cancelar checkin`;
    }
    else if(this.state.onConfirmExperimentalClass !== null) {
      return `Alterar presença`;
    }
    else if(this.state.onCancelExperimentalClass !== null) {
      return `Alterar presença`;
    }
    else if(this.state.onResetGymStudentTraining !== null || this.state.onResetGymExperimentalTraining !== null) {
      return `Resetar`;
    }
    else if(this.state.onUpdateStudentReference) {
      return `Salvar dados`;
    }
    else if(this.state.onFinishClass) {
      return `Finalizar aula`;
    }
    else if(this.state.userToDisconnectHrDevice !== null) {
      return 'Desconectar';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.onBackClicked === true ||
           this.state.onAbortClicked === true ||
           this.state.onCancelCheckinStudent !== null ||
           this.state.onConfirmExperimentalClass !== null ||
           this.state.onCancelExperimentalClass !== null ||
           this.state.onResetGymStudentTraining !== null ||
           this.state.onResetGymExperimentalTraining !== null ||
           this.state.onUpdateStudentReference === true ||
           this.state.onSelectActiveGymTraining === true ||
           this.state.onFinishClass === true ||
           this.state.showGenericWarning !== null ||
           this.state.userToDisconnectHrDevice !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      onCancelCheckinStudent: null,
      onConfirmExperimentalClass: null,
      onCancelExperimentalClass: null,
      onResetGymStudentTraining: null,
      onResetGymExperimentalTraining: null,
      userToDisconnectHrDevice: null,
      onBackClicked: false,
      onAbortClicked: false,
      onSelectActiveGymTraining: false,
      onUpdateStudentReference: false,
      onFinishClass: false,
      showGenericWarning: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.props.basicAccess) {
      return;
    }

    if(this.state.onBackClicked) {
      if(this.state.hashedClassId === null || !this.props.accessedByHash) {
        this.props.history.goBack();
      }
      else {
        this.props.history.replace(`${routes.CLASS_RESULTS_PATH}${this.state.hashedClassId}`);
      }
    }
    else if(this.state.onAbortClicked) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(await deleteModel(`${routes.CURRENT_TRAINING_CLASS_DELETE_API}${this.props.target_service}`)) {
          this.props.history.push(routes.MAIN_PATH);
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE + '.';

        if(errors instanceof Array) {
          for(let error of errors) {
            switch (error.code) {
              case 104:
                for(let parameter of error.parameters) {
                  switch (parameter.name) {
                    case 'experimental_entries':
                      errorDescription = 'Aula possui alunos experimentais vinculados. Neste caso é obrigatório a finalização da aula.';

                      break;
                    default:
                  }
                }

                break;
              default:
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onCancelCheckinStudent !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        if(this.props.accessedByHash) {
          if(this.state.hashedClassTime !== false) {
            if(await deleteModel(`${routes.HASHED_CLASS_STUDENT_CHECKIN_DELETE_API}${this.state.onCancelCheckinStudent.id}/${this.props.match.params.authHash}/${this.state.hashedClassTime}`)) {
              this.setState({
                onCancelCheckinStudent: null,
                confirmInProgress: false
              });

              this.reloadStudents();
            }
          }
          else {
            if(await deleteModel(`${routes.HASHED_CLASS_STUDENT_CHECKIN_DELETE_API}${this.state.onCancelCheckinStudent.id}/${this.props.match.params.authHash}`)) {
              this.setState({
                onCancelCheckinStudent: null,
                confirmInProgress: false
              });

              this.reloadStudents();
            }
          }
        }
        else if(await deleteModel(`${routes.CURRENT_CLASS_STUDENT_CHECKIN_DELETE_API}${this.props.target_service}/${this.state.onCancelCheckinStudent.id}`)) {
          this.setState({
            onCancelCheckinStudent: null,
            confirmInProgress: false
          });

          this.reloadStudents();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onConfirmExperimentalClass !== null || this.state.onCancelExperimentalClass !== null) {
      const checked_in = this.state.onConfirmExperimentalClass !== null ? true : false;
      const entryId = this.state.onConfirmExperimentalClass !== null ? this.state.onConfirmExperimentalClass.id : this.state.onCancelExperimentalClass.id;

      this.setState({
        confirmInProgress: true
      });

      const data = {checked_in};

      if(this.state.training.coaches) {
        data.training_class_id = this.state.training.id;
      }
      else {
        data.training_class_id = null;
      }

      try{
        if(await patchModel(`${routes.EXPERIMENTAL_CLASS_CHECK_IN_API}${entryId}`, data)) {
          this.setState({
            onConfirmExperimentalClass: null,
            onCancelExperimentalClass: null,
            confirmInProgress: false
          });

          this.reloadStudents();
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onUpdateStudentReference) {
      this.setState({
        confirmInProgress: true
      });

      const data = {reference_data: []};

      const referenceCopy = {...this.state.studentOverlayReferenceData};
      const todayIso = (new Date()).toISOString().slice(0, 10);

      for(let association of this.state.trainingGroupSelected.exercise_associations.filter((association) => association.difficult_name === 'PR')) {
        referenceCopy[association.exercise_id].updated_at = todayIso;

        data.reference_data.push({
          exercise_id: association.exercise_id,
          value: parseFloat(referenceCopy[association.exercise_id].value),
          weight: parseFloat(referenceCopy[association.exercise_id].weight),
          repetition: parseInt(referenceCopy[association.exercise_id].repetition),
        });
      }

      try{
        if(await postModel(`${routes.STUDENT_BASE_API}${this.state.studentOverlaySelected.id}${routes.STUDENT_EXERCISE_REFERENCE_POST_API}`, data)) {
          this.setState({
            onUpdateStudentReference: null,
            confirmInProgress: false,
            studentOverlayOriginalReferenceData: {...referenceCopy}
          });
        }
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onFinishClass) {
      this.setState({
        confirmInProgress: true
      });

      const data = {data_map: {}};

      for(let [key, value] of this.state.studentDataMap) {
        if(value.energy_expended > 0) {
          data.data_map[key] = {energy_expended: value.energy_expended};
        }
      }

      if(this.props.isGymService) {
        const exclude_ids = this.state.students.map((entry) => entry.id);
        const checked_students = [...this.state.students, ...this.state.early_checkins.filter((entry) => !exclude_ids.includes(entry.id))];

        for(const student of checked_students) {
          if(!data.data_map[student.id]) {
            data.data_map[student.id] = {};
          }

          if (!this.state.gymStudentActivePersonalPeriodMap.has(student.id) || this.state.gymStudentActivePersonalPeriodMap.get(student.id) === null || !this.state.gymStudentActivePersonalPeriodMap.get(student.id).training_period) {
            data.data_map[student.id].training_day_id = this.state.gymStudentNavigationMap.get(student.id).trainingDaySelected.id;
          }
        }
      }

      try{
        if(this.props.accessedByHash) {
          data.repetition_index = this.state.training.repetition_index;
          data.class_time = this.state.hashedClassTime !== false ? this.state.hashedClassTime : null;

          let response = await postModel(`${routes.CREATE_CLASS_BY_HASH_POST_API}${this.props.match.params.authHash}`, data, true);

          if(response) {
            // this.props.history.replace(routes.LAST_CLASS_PATH);
            this.setState({
              hashedClassId: response.model_saved.id,
              onFinishClass: null,
              confirmInProgress: false,
              classIsCompleted: true
            });
          }
        }
        else {
          data.target_service = this.props.target_service;

          if(await postModel(routes.FINISH_CURRENT_CLASS_POST_API, data)) {
            // this.props.history.replace(routes.LAST_CLASS_PATH);
            this.setState({
              onFinishClass: null,
              confirmInProgress: false,
              classIsCompleted: true
            });
          }
        }

      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        if (this.props.isGymService) {
          if(errors instanceof Array) {
            for(let error of errors) {
              switch (error.code) {
                case 102:
                  for(let parameter of error.parameters) {
                    switch (parameter.name) {
                      case 'training_day_id':
                        errorDescription = 'Existem alunos sem treino selecionado. Lembre-se de conferir a lista de presença e atualizá-la caso necessário.';
                        this.reloadStudents();

                        break;
                      default:
                    }
                  }

                  break;
                default:
              }
            }
          }
        }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.userToDisconnectHrDevice !== null) {
      this.setState({
        confirmInProgress: true
      });

      try{
        const update = {
          confirmInProgress: false,
          userToDisconnectHrDevice: null
        };

        if(this.state.studentHrMap.has(this.state.userToDisconnectHrDevice)) {
          const deviceData = this.state.studentHrMap.get(this.state.userToDisconnectHrDevice);
          this.state.hrDeviceMap.delete(deviceData.device_identifier);
          this.state.studentHrMap.delete(this.state.userToDisconnectHrDevice);

          this.props.bluetoothDevices.disconnectDevice(deviceData.device_identifier);

          update.hrDeviceMap = new Map(this.state.hrDeviceMap);
          update.studentHrMap = new Map(this.state.studentHrMap);
        }

        this.setState(update);
      }
      catch(errors) {
        let errorDescription = DEFAULT_UNKNOWN_ERROR_MESSAGE;

        // if(errors instanceof Array) {
        //   for(let error of errors) {
        //     switch (error.code) {
        //       case 104:
        //         for(let parameter of error.parameters) {
        //           switch (parameter.name) {
        //             case 'contracts':
        //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
        //
        //               break;
        //             default:
        //           }
        //         }
        //
        //         break;
        //       default:
        //     }
        //   }
        // }

        this.setState({
          confirmFailDescription: errorDescription,
          confirmFailed: true,
          confirmInProgress: false
        });

        return;
      }
    }
    else if(this.state.onResetGymStudentTraining !== null) {
      const update = {
        confirmInProgress: false,
        onResetGymStudentTraining: null,
      };

      this.state.gymStudentNavigationMap.delete(this.state.onResetGymStudentTraining.id);
      update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);

      this.setState(update);

      this.onResumeGymStudentTraining(this.state.onResetGymStudentTraining);
    }
    else if(this.state.onResetGymExperimentalTraining !== null) {
      const update = {
        confirmInProgress: false,
        onResetGymExperimentalTraining: null,
      };

      this.state.gymExperimentalNavigationMap.delete(this.state.onResetGymExperimentalTraining.id);
      update.gymExperimentalNavigationMap = new Map(this.state.gymExperimentalNavigationMap);

      this.setState(update);

      this.onResumeGymExperimentalTraining(this.state.onResetGymExperimentalTraining);
    }

    return;
  }

  getOverviewNavButtons() {
    if(this.state.trainingDaySelected === null) {
      return null;
    }

    return this.state.trainingDaySelected.group_associations.map((group) => (
      <DefaultMenuButton
        key={`overview:training_groups:${group.id}`}
        className="class-board__overview-navigation__nav-button"
        onClick={() => this.setState({overviewSelectedGroup: group})}
        text={parseTextForIcons(group.name, `overview:training_groups:${group.id}:name`, 'class-board__parsed-text')}
        disabled={this.state.overviewSelectedGroup === group}
      />
    ));
  }

  getTrainingExercises(group, repetitionIndex) {
    if(group.exercise_associations.length <= 0) {
      return (
        <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO</p>
      );
    }

    let intensities = [];
    const difficulties = [];
    const intermediateDifficulties = [];
    const advancedDifficulties = [];
    const exerciseNames = [];
    const easierOptions = [];
    const references = [];

    let showDificulties = false;
    let showIntermediateDificulties = false;
    let showAdvancedDificulties = false;
    let showEasierOptions = false;
    let showReferences = false;
    let showCycleNumber = true;

    let hasAdditionalHeader = false;

    let cycleNumberText = `${group.cycle_number}x`;

    if(group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
      showCycleNumber = false;
      hasAdditionalHeader = true;

      for(let i = 0; i < group.cycle_number; ++i) {
        intensities.push([(
          <p
            className="class-board__training-exercise__header"
            key={`training_exercise:intensity_header:cycle:${i}`}
          >

            {i + 1}

          </p>
        )]);
      }
    }
    else if(group.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
      cycleNumberText = (
        <React.Fragment>

          <span className="class-board__training-exercise__reps-text-span">
            QRP
          </span>

          {group.clock_time_limit ?
            <span className="class-board__training-exercise__reps-text-span">
              {getFormatedTime(group.clock_time_limit)}
            </span>:
            null
          }

        </React.Fragment>
      );
      hasAdditionalHeader = true;

      for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER + 1; ++i) {
        intensities.push([(
          <p
            className="class-board__training-exercise__header"
            key={`training_exercise:intensity_header:cycle:${i}`}
          >

            {i === QRP_CYCLES_VISIBLE_NUMBER ? 'N' : (i + 1)}

          </p>
        )]);
      }
    }

    for(let exercise of group.exercise_associations) {
      if (typeof exercise.difficult_value === 'undefined' || typeof exercise.difficult_value[repetitionIndex-1] === 'undefined') {
        return (
          <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
        );
      }

      if(!showDificulties && exercise.difficult_value[repetitionIndex-1]) {
        showDificulties = true;
      }

      if(!showIntermediateDificulties && exercise.difficult_intermediate_value[repetitionIndex-1]) {
        showIntermediateDificulties = true;
      }

      if(!showAdvancedDificulties && exercise.difficult_advanced_value[repetitionIndex-1]) {
        showAdvancedDificulties = true;
      }

      if(!showEasierOptions && exercise.easier_option && exercise.easier_option.length > 0) {
        showEasierOptions = true;
      }

      if(!this.isFullscreen() && !showReferences && exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        showReferences = true;
      }

      if(group.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS) {
        let intensityStyle = {};
        let intensityText;

        if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined') {
          return (
            <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
          );
        }

        if(exercise.intensity_value[repetitionIndex-1].trim().length > 0) {
          intensityText = exercise.intensity_value[repetitionIndex-1];
          intensityStyle = {};
        }
        else {
          intensityStyle.color = 'transparent';
          intensityText = '-';
        }

        intensities.push(
          <p
            className="class-board__training-exercise__intensity-text"
            key={`training_exercise:exercise:${exercise.id}:intensity`}
            style={intensityStyle}
          >

            {intensityText}

          </p>
        );
      }
      else if(group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL) {
        for(let i = 0; i < group.cycle_number; ++i) {
          let intensityStyle = {};
          let intensityText;

          if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined' || typeof exercise.intensity_value[repetitionIndex-1][i] === 'undefined') {
            return (
              <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
            );
          }

          if(exercise.intensity_value[repetitionIndex-1][i].trim().length > 0) {
            intensityText = exercise.intensity_value[repetitionIndex-1][i];
          }
          else {
            intensityStyle.color = 'transparent';
            intensityText = '-';
          }

          intensities[i].push(
            <p
              className="class-board__training-exercise__intensity-cycle-text"
              key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${i}`}
              style={intensityStyle}
            >

              {intensityText}

            </p>
          );
        }
      }
      else if(group.execution_method === TRAINING_EXECUTION_METHOD_QRP) {
        let showElipsis = true;

        if (typeof exercise.intensity_value[repetitionIndex-1] === 'undefined' || typeof exercise.intensity_value_step[repetitionIndex-1] === 'undefined') {
          return (
            <p className="class-board__training-exercise__not-configured">TREINO NÃO CONFIGURADO CORRETAMENTE</p>
          );
        }

        for(let i = 0; i < QRP_CYCLES_VISIBLE_NUMBER; ++i) {
          let intensity_value = exercise.intensity_value[repetitionIndex-1] + (exercise.intensity_value_step[repetitionIndex-1]*i);

          if(intensity_value <= 0) {
            intensity_value = '-';
            showElipsis = false;
          }
          else {
            intensity_value = `${intensity_value}${exercise.intensity_unit ? ' ' + exercise.intensity_unit : ''}`;
          }

          intensities[i].push(
            <p
              className="class-board__training-exercise__intensity-cycle-text"
              key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${i}`}
            >

              {intensity_value}

            </p>
          );
        }

        intensities[QRP_CYCLES_VISIBLE_NUMBER].push(
          <p
            className="class-board__training-exercise__intensity-cycle-text"
            key={`training_exercise:exercise:${exercise.id}:intensity:cycle:${QRP_CYCLES_VISIBLE_NUMBER}`}
          >

            {showElipsis ?
              <i className="fas fa-ellipsis-h"></i>:
              '-'
            }

          </p>
        );
      }

      let difficultyStyle = {};
      let difficultyText;

      if(exercise.difficult_value[repetitionIndex-1]) {
        difficultyText = `${exercise.difficult_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        difficultyStyle.color = 'transparent';
        difficultyText = '-';
      }

      difficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:difficulty`}
          style={difficultyStyle}
        >

          {parseTextForIcons(difficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      let intermediateDifficultyStyle = {};
      let intermediateDifficultyText;

      if(exercise.difficult_intermediate_value[repetitionIndex-1]) {
        intermediateDifficultyText = `${exercise.difficult_intermediate_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        intermediateDifficultyStyle.color = 'transparent';
        intermediateDifficultyText = '-';
      }

      intermediateDifficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:intermediate_difficulty`}
          style={intermediateDifficultyStyle}
        >

          {parseTextForIcons(intermediateDifficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      let advancedDifficultyStyle = {};
      let advancedDifficultyText;

      if(exercise.difficult_advanced_value[repetitionIndex-1]) {
        advancedDifficultyText = `${exercise.difficult_advanced_value[repetitionIndex-1]}${exercise.difficult_unit || ''} ${exercise.difficult_name ? '(' + exercise.difficult_name + ')' : ''}`;
      }
      else {
        advancedDifficultyStyle.color = 'transparent';
        advancedDifficultyText = '-';
      }

      advancedDifficulties.push(
        <p
          className="class-board__training-exercise__difficult-text"
          key={`training_exercise:exercise:${exercise.id}:advanced_difficulty`}
          style={advancedDifficultyStyle}
        >

          {parseTextForIcons(advancedDifficultyText, `training_exercise:exercise:${exercise.id}:dificult_text`, 'class-board__parsed-text')}

        </p>
      );

      exerciseNames.push(
        <p
          className="class-board__training-exercise__name-text"
          key={`training_exercise:exercise:${exercise.id}:name`}
        >

          {exercise.exercise_name}

        </p>
      );

      let easierOptionStyle = {};
      let easierOptionText;

      if(exercise.easier_option) {
        easierOptionText = exercise.easier_option;
      }
      else {
        easierOptionStyle.color = 'transparent';
        easierOptionText = '-';
      }

      easierOptions.push(
        <p
          className="class-board__training-exercise__easier-option-text"
          key={`training_exercise:exercise:${exercise.id}:easier_option`}
          style={easierOptionStyle}
        >

          {easierOptionText}

        </p>
      );

      if(exercise.exercise.reference_url && exercise.exercise.reference_url.length > 0) {
        const urlInfo = this.parseVideoUrl(exercise.exercise.reference_url);

        if(urlInfo.mayEmbed) {
          references.push(
            <button
              className="class-board__training-exercise__reference-link"
              onClick={() => this.onSelectExerciseLink(exercise.exercise)}
              key={`training_exercise:exercise:${exercise.id}:reference`}
            >

              <i className="fas fa-link"></i>

            </button>
          );
        }
        else {
          references.push(
            <a
              className="class-board__training-exercise__reference-link"
              href={exercise.exercise.reference_url}
              target="_blank"
              rel="noopener noreferrer"
              key={`training_exercise:exercise:${exercise.id}:reference`}
              >

                <i className="fas fa-link"></i>

              </a>
            );
        }
      }
      else {
        references.push(
          <p
            className="class-board__training-exercise__reference-link"
            key={`training_exercise:exercise:${exercise.id}:reference`}
            style={{color: 'transparent'}}
          >
            -
          </p>
        );
      }
    }

    if(group.execution_method !== TRAINING_EXECUTION_METHOD_FIXED_REPS) {
      intensities = intensities.map((entry, index) => (
        <div
          className="class-board__training-exercise__intensities"
          key={`training_exercise:intensity_cycle_column:${index}`}
        >

          {entry}

        </div>
      ));
    }

    return (
      <React.Fragment>

        <div
          className={`class-board__training-exercise__first-column${group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? '--expanded' : ''}`}
        >

          <p
            className="class-board__training-exercise__header"
          >

          </p>

          {hasAdditionalHeader &&
            <p
              className="class-board__training-exercise__header"
            >

              {group.execution_method === TRAINING_EXECUTION_METHOD_PIRAMIDAL ? 'Série' : ''}

            </p>
          }

        </div>

        {showCycleNumber &&
          <div className="class-board__training-exercise__reps">

            <p
              className="class-board__training-exercise__header"
            >

            </p>

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

                Série

              </p>
            }

            <p className="class-board__training-exercise__reps-text">

              {cycleNumberText}

            </p>

          </div>
        }

        <div className={`class-board__training-exercise__intensities${hasAdditionalHeader ? '--collapsed' : ''}`}>

          <p
            className={`class-board__training-exercise__header${hasAdditionalHeader ? '--centered' : ''}`}
          >

            Reps

          </p>

          <div className={`class-board__training-exercise__intensities-wrapper${group.execution_method === TRAINING_EXECUTION_METHOD_FIXED_REPS ? '--vertical' : '--horizontal'}`}>

            {intensities}

          </div>

        </div>

        <div className="class-board__training-exercise__names">

          {hasAdditionalHeader &&
            <p
              className="class-board__training-exercise__header"
            >

            </p>
          }

          <p
            className="class-board__training-exercise__header"
          >

            Exercícios

          </p>

          {exerciseNames}

        </div>

        {showDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Dificuldade

            </p>

            {difficulties}

          </div>
        }

        {showIntermediateDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              *

            </p>

            {intermediateDifficulties}

          </div>
        }

        {showAdvancedDificulties &&
          <div className="class-board__training-exercise__diffculties">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              AV

            </p>

            {advancedDifficulties}

          </div>
        }

        {showEasierOptions &&
          <div className="class-board__training-exercise__easier-options">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Variações

            </p>

            {easierOptions}

          </div>
        }

        {showReferences &&
          <div className="class-board__training-exercise__references">

            {hasAdditionalHeader &&
              <p
                className="class-board__training-exercise__header"
              >

              </p>
            }

            <p
              className="class-board__training-exercise__header"
            >

              Links

            </p>

            {references}

          </div>
        }

      </React.Fragment>
    );
  }

  getTrainingGroup(group, repetitionIndex) {
    if(group === null) {
      return (
        <p
          key="training_group:undefined_group"
          className="class-board__training-group-not-configured"
        >
          TREINO NÃO CONFIGURADO CORRETAMENTE
        </p>
      );
    }

    return (
      <div
        key={`training_group:${group.id}`}
        className={`class-board__training-group${this.isFullscreen(true) ? '--fullscreen' : ''}`}
      >

        <h4
          ref={this.trainingGroupTitleRef}
          className="class-board__training-group__title"
          key={`training_group_name:${group.name}`}
        >

          {parseTextForIcons(group.name, `training_group_name:${group.name}:name`, 'class-board__parsed-text')}

        </h4>

        <div className="class-board__training-group__exercises-container">

          {this.getTrainingExercises(group, repetitionIndex)}

        </div>

        {group.note ?
          <div className="class-board__training-group__note-container">

            <p className="class-board__training-group__note-label">
              OBS:
            </p>

            <p
              className="class-board__training-group__note-text"
            >
              {group.note}
            </p>

          </div>:
          null
        }

      </div>
    );
  }

  async onSelectOverlayStudent(student) {
    this.setState({
      studentOverlayLoading: true,
      studentOverlaySelected: student,
      studentOverlayShowInputs: false
    });

    const exercise_ids = this.state.trainingGroupSelected.exercise_associations.filter((association) => association.difficult_name === 'PR').map((association) => association.exercise_id);

    const referenceData = await getModel(`${routes.STUDENT_BASE_API}${student.id}${routes.STUDENT_EXERCISE_REFERENCE_GET_API}${this.state.trainingGroupSelected.id}`);

    const studentOverlayReferenceData = {};

    for(let id of exercise_ids) {
      studentOverlayReferenceData[id] = referenceData[id];
    }

    this.setState({
      studentOverlayReferenceData,
      studentOverlayOriginalReferenceData: {...studentOverlayReferenceData},
      studentOverlayLoading: false
    });
  }

  async onSyncHrDevice(student) {
    this.setState({
      hrSyncStudentSelected: student,
      hrSyncInProgress: false,
      showDeviceErrorMessage: false,
      deviceErrorMessage: '',
    });
  }

  async syncDevice(device_identifier=null) {
    if(this.state.hrSyncStudentSelected === null) {
      return;
    }

    this.setState({
      hrSyncInProgress: true
    });

    const student = this.state.hrSyncStudentSelected;

    const deviceResponse = await this.props.bluetoothDevices.requestDevice(device_identifier, ['heart_rate']);

    if(deviceResponse.error !== null) {
      this.setState({
        hrSyncInProgress: false,
        showDeviceErrorMessage: true,
        deviceErrorMessage: deviceResponse.error,
      });
    }
    else {
      const update = {
        hrSyncInProgress: false,
        hrSyncStudentSelected: null,
      };

      const true_device_identifier = deviceResponse.device.name;

      let studentData;

      if(this.state.studentDataMap.has(student.id)) {
        studentData = this.state.studentDataMap.get(student.id);
      }
      else {
        studentData = {};
        this.state.studentDataMap.set(student.id, studentData);
      }

      studentData.energy_expended = 0;
      studentData.last_hr = null;

      update.studentData = new Map(this.state.studentData);

      if(this.state.hrDeviceMap.has(true_device_identifier)) {
        const lastStudent = this.state.hrDeviceMap.get(true_device_identifier);
        const lastConnection = this.state.studentHrMap.get(lastStudent.id);

        if(lastConnection.errorMessage.length <= 0) {
          this.state.hrDeviceMap.set(true_device_identifier, student);
          this.state.studentHrMap.delete(lastStudent.id);
          this.state.studentHrMap.set(student.id, {...lastConnection});

          update.hrDeviceMap = new Map(this.state.hrDeviceMap);
          update.studentHrMap = new Map(this.state.studentHrMap);

          this.setState(update);
          return;
        }
        else {
          this.state.studentHrMap.delete(lastStudent.id);
        }
      }

      this.state.hrDeviceMap.set(true_device_identifier, student);

      this.state.studentHrMap.set(student.id, {
        device_identifier: true_device_identifier,
        connected: false,
        hr: null,
        errorMessage: ''
      });

      update.studentHrMap = new Map(this.state.studentHrMap);

      this.setState(update);

      const response = await this.props.bluetoothDevices.subscribeToCharacteristic(
        true_device_identifier,
        'heart_rate',
        'heart_rate_measurement',
        (value) => {
          let student = null;

          if(this.state.hrDeviceMap.has(true_device_identifier)) {
            student = this.state.hrDeviceMap.get(true_device_identifier);
          }

          if(student !== null && this.state.studentHrMap.has(student.id)) {
            const now = Date.now();
            const entry = this.state.studentHrMap.get(student.id);
            entry.connected = true;
            entry.hr = this.parseHeartRate(value);
            entry.errorMessage = '';

            const stateUpdate = {studentHrMap: new Map(this.state.studentHrMap)};

            if(student.max_hr && student.vo2_max && student.resting_hr && student.resting_vo2) {
              const data = this.state.studentDataMap.get(student.id);

              if(data.last_hr !== null) {
                const vo2_res = student.vo2_res;
                const hr_res = student.hr_res;

                const meanHr = (data.last_hr.value + entry.hr) / 2;
                const timeDiff = (now - data.last_hr.time) / 60000;

                let meanVo2 = student.resting_vo2 + (vo2_res * ((meanHr - student.resting_hr) / hr_res));

                meanVo2 = Math.max(meanVo2, 0);

                data.energy_expended += 5 * meanVo2 * timeDiff;
              }

              data.last_hr = {
                value: entry.hr,
                time: now
              };

              stateUpdate.studentDataMap = new Map(this.state.studentDataMap);
            }

            this.setState(stateUpdate);
          }
        },
        () => {
          let student_id = null;

          if(this.state.hrDeviceMap.has(true_device_identifier)) {
            student_id = this.state.hrDeviceMap.get(true_device_identifier).id;
          }

          if(student_id !== null && this.state.studentHrMap.has(student_id)) {
            const entry = this.state.studentHrMap.get(student_id);
            entry.connected = false;
            entry.errorMessage = '';

            this.setState({studentHrMap: new Map(this.state.studentHrMap)});
          }
        }
      );

      if(response.error !== null) {
        // TODO: DISPLAY ERROR MESSAGE SOMEWHERE
        window.alert(`Falha ao coletar dados do sensor (${true_device_identifier}). Erro: ${response.error}`);
        // console.log(`Falha ao coletar dados do sensor (${true_device_identifier}). Erro: ${response.error}`);
        let student_id = null;

        if(this.state.hrDeviceMap.has(true_device_identifier)) {
          student_id = this.state.hrDeviceMap.get(true_device_identifier).id;
        }

        if(student_id !== null && this.state.studentHrMap.has(student_id)) {
          const entry = this.state.studentHrMap.get(student_id);
          entry.errorMessage = response.error;

          this.setState({studentHrMap: new Map(this.state.studentHrMap)});
        }
      }
    }
  }

  getHrSyncDeviceOptions() {
    if(this.state.hrSyncStudentSelected === null) {
      return null;
    }

    let deviceOptions = [];

    this.state.devices.sort((a, b) => {
      const isASynced = this.state.hrDeviceMap.has(a.device_identifier);
      const isBSynced = this.state.hrDeviceMap.has(b.device_identifier);

      if(isASynced && !isBSynced) {
        return 1;
      }
      else if(!isASynced && isBSynced) {
        return -1;
      }

      return a.name.localeCompare(b.name);
    })

    if(this.state.devices.length > 0) {
      deviceOptions = this.state.devices.map((device) => {
        return (
          <div
            className="class-board__overlay__hr-device-option"
            key={`device-option:${device.id}`}
          >

            <div className="class-board__overlay__hr-device-option__header">

              <h2 className="class-board__overlay__hr-device-option__header__title">{device.name}</h2>

              {this.state.hrDeviceMap.has(device.device_identifier) &&
                <p className="class-board__overlay__hr-device-option__header__sub-title">{`ATUAL: ${this.state.hrDeviceMap.get(device.device_identifier).name}`}</p>
              }

            </div>

            <DefaultMenuButton
              className="class-board__overlay__hr-device-option__select-button"
              onClick={(event) => this.syncDevice(device.device_identifier)}
              text={<React.Fragment><i className="fab fa-bluetooth-b initiate-device-connection__action-button__icon"></i> Vincular</React.Fragment>}
              color="purple"
            />

          </div>
        );
      });
    }

    deviceOptions.push((<hr key="device-option:separator" className="class-board__horizontal-rule" />));

    deviceOptions.push((
      <div
        className="class-board__overlay__hr-device-option"
        key={`device-option:any-hr-device`}
      >

        <div className="class-board__overlay__hr-device-option__header">

          <h2 className="class-board__overlay__hr-device-option__header__title">OUTRO</h2>

        </div>

        <DefaultMenuButton
          className="class-board__overlay__hr-device-option__select-button"
          onClick={(event) => this.syncDevice()}
          text={<React.Fragment><i className="fab fa-bluetooth-b initiate-device-connection__action-button__icon"></i> Procurar</React.Fragment>}
          color="blue"
        />

      </div>
    ));

    return deviceOptions;
  }

  getHrZone(value, maxHr) {
    if(value < 0.6*maxHr) {
      return 1;
    }
    else if(value < 0.7*maxHr) {
      return 2;
    }
    else if(value < 0.8*maxHr) {
      return 3;
    }
    else if(value < 0.9*maxHr) {
      return 4;
    }
    else if(value <= maxHr) {
      return 5;
    }

    return 5;
  }

  handleReferenceChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    const referenceCopy = {...this.state.studentOverlayReferenceData};

    if(name.startsWith('exercise_reference:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);

      const association = this.state.trainingGroupSelected.exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      if(!value) {
        value = null;
      }
      else {
        const prPercentage = this.getPrPercentage(this.state.studentOverlaySelected.physical_recuperation_status, association.difficult_value[this.state.training.repetition_index - 1]);

        value = 100 * parseFloat(value) / prPercentage;
      }

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id].value = value;
    }
    else if(name.startsWith('exercise_reference_input:')) {
      const selection = name.split(':');
      const associationId = parseInt(selection[1]);
      const parameterName = selection[2];

      const association = this.state.trainingGroupSelected.exercise_associations.find((association) => association.id === associationId);
      const exercise_id = association.exercise_id;

      referenceCopy[exercise_id] = {...referenceCopy[exercise_id]};

      referenceCopy[exercise_id][parameterName] = value || null;

      if(referenceCopy[exercise_id].weight && referenceCopy[exercise_id].repetition) {
        referenceCopy[exercise_id].value = (referenceCopy[exercise_id].weight * 100) / (102.78 - (2.78 * referenceCopy[exercise_id].repetition));
      }
    }

    this.setState({
      studentOverlayReferenceData: referenceCopy
    });
  }

  getPrPercentage(physicalRecuperationStatus, currentPrPercentage) {
    return Math.round(currentPrPercentage - (10*(10 - physicalRecuperationStatus)/3));
  }

  getStudentOverlayData() {
    if(this.state.studentOverlaySelected === null || this.state.studentOverlayReferenceData === null) {
      return null;
    }

    return this.state.trainingGroupSelected.exercise_associations.filter((association) => association.difficult_name === 'PR').map((association) => {
      const prValue = this.state.studentOverlayReferenceData[association.exercise_id].value;

      const normalMaxPercentage = association.difficult_value[this.state.training.repetition_index - 1];
      let maxPercentage = normalMaxPercentage;
      let minPercentage = normalMaxPercentage - 30;

      const prPercentage = this.getPrPercentage(this.state.studentOverlaySelected.physical_recuperation_status, normalMaxPercentage);

      if(association.difficult_intermediate_value) {
        const intermediateMaxPercentage = association.difficult_intermediate_value[this.state.training.repetition_index - 1];

        if(intermediateMaxPercentage > 0) {
          maxPercentage = Math.max(intermediateMaxPercentage, maxPercentage);
          minPercentage = Math.min(intermediateMaxPercentage - 30, minPercentage);
        }
      }

      if(association.difficult_advanced_value) {
        const advancedMaxPercentage = association.difficult_advanced_value[this.state.training.repetition_index - 1];

        if(advancedMaxPercentage > 0) {
          maxPercentage = Math.max(advancedMaxPercentage, maxPercentage);
          minPercentage = Math.min(advancedMaxPercentage - 30, minPercentage);
        }
      }

      let prText = '';

      // if(prValue) {
      //   prText = prValue*prPercentage*0.01;
      //
      //   prText *= 1000;
      //   prText = Math.round(prText);
      //   prText = prText / 1000;
      // }

      const percentageGraduation = [];

      minPercentage = Math.max(minPercentage, 0);

      const step = Math.ceil((maxPercentage - minPercentage) / 6);
      minPercentage = maxPercentage - (6*step);

      const percentageReference = (Math.floor(((prPercentage - minPercentage) / step))*step) + minPercentage;

      if(!this.state.studentOverlayShowInputs) {
        const resistanceEntries = [];
        const hypertrophyEntries = [];
        const strengthEntries = [];

        for(let percentage=maxPercentage; percentage >= minPercentage; percentage -= step) {
          if(percentage < 0) {
            break;
          }

          if(prValue) {
            prText = prValue*percentage*0.01;

            prText *= 10;
            prText = Math.round(prText);
            prText = prText / 10;
          }

          const entry = (
            <div
              className={`class-board__overlay__reference__input-container${percentage === percentageReference ? '--selected' : ''}`}
              key={`class_board:exercise_reference:${association.id}:pr_output:${percentage}`}
            >

              <p className="class-board__overlay__reference__pr-input-header">
                <span className="class-board__overlay__reference__input-prefix--highlighted">{percentage.toFixed(0)}%</span>
              </p>

              <DefaultInput
                className="class-board__overlay__reference__input"
                name={`exercise_reference:${association.id}`}
                type="number"
                placeholder="-"
                min="0"
                step="0.1"
                handleInputChange={(event) => this.handleReferenceChange(event)}
                value={prText}
                autoComplete="off"
                suffix="kg"
                // prefix={(
                //   <p className="class-board__overlay__reference__input-prefix">
                //
                //     <span className="class-board__overlay__reference__input-prefix--highlighted">{prPercentage.toFixed(0)}%</span>
                //     {this.state.screenWidth > 510 ? ' do PR' : ''}
                //
                //   </p>
                // )}
                onFocus={(event) => event.target.select()}
                disabled={true}
              />

              {/* {prValue ?
                <p className="class-board__overlay__reference__generic-text">
                  (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)
                </p>:
                null
              } */}

            </div>
          );

          if (percentage < 60) {
            resistanceEntries.push(entry);
          }
          else if (percentage < 85) {
            hypertrophyEntries.push(entry);
          }
          else {
            strengthEntries.push(entry);
          }
        }

        if (strengthEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__overlay__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:strength`}
            >

              <div className="class-board__overlay__reference__input-group-container__title-wrapper--strength">

                <p className="class-board__overlay__reference__input-group-container__title">Força</p>

              </div>

              <div className="class-board__overlay__reference__percentages-wrapper">

                {strengthEntries}

              </div>

            </div>
          );
        }
        if (hypertrophyEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__overlay__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:hypertrophy`}
            >

              <div className="class-board__overlay__reference__input-group-container__title-wrapper--hypertrophy">

                <p className="class-board__overlay__reference__input-group-container__title">Hipertrofia</p>

              </div>

              <div className="class-board__overlay__reference__percentages-wrapper">

                {hypertrophyEntries}

              </div>

            </div>
          );
        }
        if (resistanceEntries.length > 0) {
          percentageGraduation.push(
            <div
              className="class-board__overlay__reference__input-group-container"
              key={`class_board:exercise_reference:${association.id}:pr_output_group:resistance`}
            >

              <div className="class-board__overlay__reference__input-group-container__title-wrapper--resistance">

                <p className="class-board__overlay__reference__input-group-container__title">Resistência</p>

              </div>

              <div className="class-board__overlay__reference__percentages-wrapper">

                {resistanceEntries}

              </div>

            </div>
          );
        }
      }

      let updatedAtText = null;

      if(this.state.studentOverlayReferenceData[association.exercise_id].updated_at) {
        const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));
        const updatedAt = getAsLocalDate(this.state.studentOverlayReferenceData[association.exercise_id].updated_at.slice(0, 10));

        const timeDiff = Math.abs(today.getTime() - updatedAt.getTime());
        let daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

        updatedAtText = `Salvo em ${updatedAt.toLocaleDateString()} (${daysCount} dia(s))`;
      }

      return (
        <div
          key={`student:${this.state.studentOverlaySelected.id}:exercise_reference:${association.id}`}
          className="class-board__overlay__reference"
        >

          {updatedAtText !== null &&
            <p className="class-board__overlay__reference__date-text">

              {updatedAtText}

            </p>
          }

          <div className="class-board__overlay__reference__wrapper">

            <h4 className="class-board__overlay__reference__label">{association.exercise_name}:</h4>

            {!this.state.studentOverlayShowInputs ?
              <div className="class-board__overlay__reference__percentages-wrapper">

                {percentageGraduation}

              </div>:
              <div className="class-board__overlay__reference__input-container--horizontal">

                <div className="class-board__overlay__reference__pr-input-wrapper">

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:weight`}
                    className="class-board__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:weight`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="0.1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].weight || ''}
                    autoComplete="off"
                    suffix="kg"
                    prefix="Peso"
                    onFocus={(event) => event.target.select()}
                  />

                  <DefaultInput
                    key={`class_board:exercise_reference:${association.id}:repetition`}
                    className="class-board__overlay__reference__input--extended"
                    name={`exercise_reference_input:${association.id}:repetition`}
                    type="number"
                    placeholder="-"
                    min="0"
                    step="1"
                    handleInputChange={(event) => this.handleReferenceChange(event)}
                    value={this.state.studentOverlayReferenceData[association.exercise_id].repetition || ''}
                    autoComplete="off"
                    prefix="Repetições"
                    onFocus={(event) => event.target.select()}
                  />

                </div>

                {(this.state.studentOverlayReferenceData[association.exercise_id].repetition && this.state.studentOverlayReferenceData[association.exercise_id].weight) &&
                  <React.Fragment>

                    <i className="fas fa-long-arrow-alt-right class-board__overlay__reference__arrow-icon"></i>

                    <p className="class-board__overlay__reference__pr-text">

                      (<b>100%</b> do PR: <b>{prValue.toFixed(0)}</b> kg)

                    </p>

                  </React.Fragment>
                }

              </div>
            }

          </div>

        </div>
      );
    });
  }

  getStudentOverlayActions() {
    let addSaveAction = false;

    if(this.state.studentOverlayReferenceData !== null && this.state.studentOverlayOriginalReferenceData !== null) {
      for(let association of this.state.trainingGroupSelected.exercise_associations.filter((association) => association.difficult_name === 'PR')) {
        if(this.state.studentOverlayReferenceData[association.exercise_id].value !== this.state.studentOverlayOriginalReferenceData[association.exercise_id].value) {
          addSaveAction = true;
        }
      }
    }

    return (
      <React.Fragment>

        <DefaultMenuButton
          className="class-board__overlay__action-button"
          onClick={() => this.setState({
            studentOverlaySelected: null,
            studentOverlayReferenceData: null,
            studentOverlayOriginalReferenceData: null,
          })}
          text="Fechar"
          disabled={this.state.onUpdateStudentReference}
        />

        {addSaveAction &&
          <DefaultMenuButton
            className="class-board__overlay__action-button"
            onClick={() => this.setState({onUpdateStudentReference: true})}
            text="Salvar"
            color="green"
            disabled={this.state.onUpdateStudentReference}
          />
        }

      </React.Fragment>
    );
  }

  getStudentActions(student) {
    let showInfoButton = false;

    const studentValidForInfoButton = this.state.gymStudentSelected === null || (this.state.gymStudentSelected !== null && this.state.gymStudentSelected.id === student.id);

    if(studentValidForInfoButton && this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.exercise_associations.some((association) => association.difficult_name === 'PR')) {
      showInfoButton = true;
    }

    const meanWeeklyFrequency = Math.round(student.weekly_frequency * 10) / 10;

    let zone_index = student.zone_index * 100;
    zone_index = Math.round(zone_index) / 100;

    let zoneClass = '--alert';

    if(zone_index >= 0.8 && zone_index <= 1.3) {
      zoneClass = '--normal';
    }
    else if(zone_index > 1.3 && zone_index < 1.5) {
      zoneClass = '--danger';
    }
    else if(zone_index >= 1.5) {
      zoneClass = '--extreme-danger';
    }

    let hrZone = '';

    const hasDeviceSynced = this.state.studentHrMap.has(student.id);
    const showHrButton = this.state.isbluetoothAvailable && !hasDeviceSynced;
    let studentHrEntry = null;
    if(hasDeviceSynced) {
      studentHrEntry = this.state.studentHrMap.get(student.id);

      if(student.max_hr && studentHrEntry.connected) {
        hrZone = `--${this.getHrZone(studentHrEntry.hr, student.max_hr)}`;
      }
    }

    return (
      <React.Fragment>

        <div className={`class-board__students-panel__student__zone${zoneClass}`}>

          <p className="class-board__students-panel__student__zone__index">
            {meanWeeklyFrequency}
          </p>
          {/* <p className="class-board__students-panel__student__zone__index">
            {zone_index.toFixed(2)}
          </p>

          <p className="class-board__students-panel__student__zone__weekly-frequency">
            {meanWeeklyFrequency}
          </p> */}

        </div>

        {showInfoButton &&
          <DefaultMenuButton
            className="class-board__students-panel__student__info-button"
            onClick={() => this.onSelectOverlayStudent(student)}
            text={(<i className="fas fa-info"></i>)}
          />
        }

        {this.state.isbluetoothAvailable &&
          <DefaultMenuButton
            className={`class-board__students-panel__student__hr-button${hrZone}`}
            onClick={() => {
              if(hasDeviceSynced) {
                this.setState({userToDisconnectHrDevice: student.id});
              }
              else {
                this.onSyncHrDevice(student);
              }
            }}
            text={showHrButton ? (
              <i className="fas fa-heartbeat"></i>
            ):
            (studentHrEntry.connected ? (
              studentHrEntry.hr
            ):
            (
              <i className="fas fa-spinner class-board__loading-icon"></i>
            ))}
          />
        }

        {!this.state.classIsCompleted &&
          <DefaultMenuButton
            className="class-board__students-panel__student__action-button"
            onClick={() => this.setState({onCancelCheckinStudent: student})}
            text={(<i className="fas fa-trash-alt"></i>)}
            color="red"
          />
        }

      </React.Fragment>
    );
  }

  getStudentDisc(student, returnAsInteger=false) {
    let result = null;

    for(let entry of student.disc) {
      if(!result || result.value < entry.value) {
        result = entry;
      }
    }

    if (returnAsInteger) {
      if (result !== null) {
        switch (result) {
          case 'd':
            result = 1;
            break;
          case 'i':
            result = 2;
            break;
          case 's':
            result = 3;
            break;
          case 'c':
            result = 4;
            break;
          default:
            result = 0;
        }
      }
      else {
        result = 0;
      }
    }

    return result.type;
  }

  async onResumeGymStudentTraining(student, fetchSelection=false) {
    let update = {};

    if(this.state.gymStudentSelected !== null && (this.state.trainingPeriodSelected !== null || this.state.trainingDaySelected !== null) && this.state.gymStudentSelected.id !== student.id) {
      this.state.gymStudentNavigationMap.set(this.state.gymStudentSelected.id, {
        trainingPeriodSelected: this.state.trainingPeriodSelected,
        trainingDaySelected: this.state.trainingDaySelected,
        trainingGroupSelected: this.state.trainingGroupSelected,
      });

      update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
    }
    else if(this.state.gymExperimentalSelected !== null && this.state.trainingPeriodSelected !== null) {
      this.state.gymExperimentalNavigationMap.set(this.state.gymExperimentalSelected.id, {
        trainingPeriodSelected: this.state.trainingPeriodSelected,
        trainingDaySelected: this.state.trainingDaySelected,
        trainingGroupSelected: this.state.trainingGroupSelected,
      });

      update.gymExperimentalNavigationMap = new Map(this.state.gymExperimentalNavigationMap);
    }

    update.gymStudentSelected = student;
    update.gymExperimentalSelected = null;
    update.gymPeriodNoteVisible = false;
    update.studentListVisible = false;
    update.qrCodeExpanded = false;

    if(this.state.gymStudentNavigationMap.has(student.id)) {
      update = {
        ...update,
        ...this.state.gymStudentNavigationMap.get(student.id)
      };
    }
    else {
      update.trainingPeriodSelected = this.state.experimentalPeriods.length === 1 ? this.state.experimentalPeriods[0] : null;
      update.trainingDaySelected = null;
      update.trainingGroupSelected = null;
    }

    const active_personal_training = this.state.gymStudentActivePersonalPeriodMap.has(student.id) ? this.state.gymStudentActivePersonalPeriodMap.get(student.id) : null;

    if (fetchSelection && active_personal_training !== null && active_personal_training.training_period) {
      this.setState({
        loading: true
      });

      update.loading = false;

      const training_day_id = await getModel(`${routes.STUDENT_BASE_API}${student.id}${routes.STUDENT_ACTIVE_GYM_TRAINING_DAY_GET_API}`);

      if (training_day_id) {
        const trainingDay = active_personal_training.training_period.training_days.find((entry) => entry.id === training_day_id);

        if (trainingDay && (update.trainingDaySelected === null || update.trainingDaySelected.id !== trainingDay.id)) {
          update.trainingDaySelected = trainingDay;
          update.trainingGroupSelected = null;

          this.state.gymStudentNavigationMap.set(student.id, {
            trainingPeriodSelected: update.trainingPeriodSelected,
            trainingDaySelected: update.trainingDaySelected,
            trainingGroupSelected: update.trainingGroupSelected,
          });
          update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
        }
      }
    }

    if (update.trainingDaySelected !== null && update.trainingGroupSelected === null) {
      if(update.trainingDaySelected.group_associations.length > 0) {
        update.overviewSelectedGroup = update.trainingDaySelected.group_associations[0];
      }
    }

    if (update.trainingDaySelected !== null && update.trainingGroupSelected !== null) {
      this.initializeClockGroupParameters(update.trainingGroupSelected);
    }

    this.setState(update);
  }

  onResumeGymExperimentalTraining(experimental_entry) {
    let update = {};

    if(this.state.gymStudentSelected !== null && (this.state.trainingPeriodSelected !== null || this.state.trainingDaySelected !== null)) {
      this.state.gymStudentNavigationMap.set(this.state.gymStudentSelected.id, {
        trainingPeriodSelected: this.state.trainingPeriodSelected,
        trainingDaySelected: this.state.trainingDaySelected,
        trainingGroupSelected: this.state.trainingGroupSelected,
      });

      update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
    }
    else if(this.state.gymExperimentalSelected !== null && this.state.trainingPeriodSelected !== null && this.state.gymExperimentalSelected.id !== experimental_entry.id) {
      this.state.gymExperimentalNavigationMap.set(this.state.gymExperimentalSelected.id, {
        trainingPeriodSelected: this.state.trainingPeriodSelected,
        trainingDaySelected: this.state.trainingDaySelected,
        trainingGroupSelected: this.state.trainingGroupSelected,
      });

      update.gymExperimentalNavigationMap = new Map(this.state.gymExperimentalNavigationMap);
    }

    update.gymStudentSelected = null;
    update.gymExperimentalSelected = experimental_entry;
    update.gymPeriodNoteVisible = false;
    update.studentListVisible = false;
    update.qrCodeExpanded = false;

    if(this.state.gymExperimentalNavigationMap.has(experimental_entry.id)) {
      update = {
        ...update,
        ...this.state.gymExperimentalNavigationMap.get(experimental_entry.id)
      };
    }
    else {
      update.trainingPeriodSelected = this.state.experimentalPeriods.length === 1 ? this.state.experimentalPeriods[0] : null;
      update.trainingDaySelected = null;
      update.trainingGroupSelected = null;
    }

    if (update.trainingDaySelected !== null && update.trainingGroupSelected === null) {
      if(update.trainingDaySelected.group_associations.length > 0) {
        update.overviewSelectedGroup = update.trainingDaySelected.group_associations[0];
      }
    }

    if (update.trainingDaySelected !== null && update.trainingGroupSelected !== null) {
      this.initializeClockGroupParameters(update.trainingGroupSelected);
    }

    this.setState(update);
  }

  onReselectStudentTraining(student) {
    this.setState({
      onResetGymStudentTraining: student
    });
  }

  onReselectExperimentalTraining(experimental_entry) {
    this.setState({
      onResetGymExperimentalTraining: experimental_entry
    });
  }

  getTrainingPeriodProgress(student) {
    const active_personal_training = this.state.gymStudentActivePersonalPeriodMap.get(student.id);

    let progress = null;

    if(active_personal_training.initial_date !== null) {
      const cycleCount = active_personal_training.training_period.repetition_count;
      const initialDate = getAsLocalDate(active_personal_training.initial_date);

      let trainingsExecuted = 0;

      for(const trainingData of active_personal_training.training_data) {
        const executionDate = getAsLocalDate(trainingData.date);

        if(executionDate >= initialDate) {
          trainingsExecuted += 1;
        }
      }

      progress = 100 * trainingsExecuted / (active_personal_training.training_period.training_days.length * cycleCount);

      progress = (
        <div className="class-board__students-panel__student__training-period-progress">

          <p className="class-board__students-panel__student__training-period-progress__text">

            {progress.toFixed(0)}%

          </p>

        </div>);
    }

    return progress;
  }

  getCheckedInStudents() {
    if(this.props.basicAccess) {
      return null;
    }

    let experimental_classes;

    if(this.props.accessedByHash) {
      experimental_classes = this.state.experimental_classes.filter((entry) => entry.hour === this.state.hashedClassTime && entry.checked_in !== false);
    }
    else {
      experimental_classes = this.state.experimental_classes.filter((entry) => entry.checked_in !== false);
    }

    const experimentalClasses = experimental_classes.map((entry) => {
      let note = '';

      if(entry.physical_condition) {
        note = entry.physical_condition;
      }

      if(entry.physical_activity) {
        if(note) {
          note += ' | ';
        }

        note += entry.physical_activity;
      }

      return (
        <div
          key={`experimental_class:${entry.id}`}
          className={`class-board__students-panel__student ${this.state.gymExperimentalSelected !== null && this.state.gymExperimentalSelected.id === entry.id ? 'student-selected' : ''}`}
        >

          <div className="class-board__students-panel__student__picture-container--with-text">

            <span className="class-board__students-panel__student__picture-container__text">EXP</span>

          </div>

          <div className="class-board__students-panel__student__wrapper">

            <div className="class-board__students-panel__student__row-wrapper">

              <div className="class-board__students-panel__student__data-container">

                <p className="class-board__students-panel__student__name">

                  {getAbbreviatedName(entry.name)}

                </p>

                {note ?
                  <p className="class-board__students-panel__student__note">

                    {note}

                  </p>:
                  null
                }

              </div>

              <div className="class-board__students-panel__student__actions">

                {entry.checked_in === null &&
                  <React.Fragment>

                    <DefaultMenuButton
                      className="class-board__students-panel__student__action-button"
                      onClick={() => this.setState({onConfirmExperimentalClass: entry})}
                      text={(<i className="fas fa-thumbs-up"></i>)}
                      color="green"
                    />

                    <DefaultMenuButton
                      className="class-board__students-panel__student__action-button"
                      onClick={() => this.setState({onCancelExperimentalClass: entry})}
                      text={(<i className="fas fa-thumbs-down"></i>)}
                      color="red"
                    />

                  </React.Fragment>
                }

              </div>

            </div>

            {(this.props.isGymService && this.state.experimentalPeriods.length > 0) &&
              <div className="class-board__students-panel__student__gym-buttons-container">

                <DefaultMenuButton
                  className="class-board__students-panel__student__gym-select-button"
                  onClick={() => this.onResumeGymExperimentalTraining(entry)}
                  text={this.state.gymExperimentalNavigationMap.has(entry.id) ? 'Resumir' : 'Selecionar treino'}
                  color="blue"
                />

                {this.state.gymExperimentalNavigationMap.has(entry.id) &&
                  <DefaultMenuButton
                    className="class-board__students-panel__student__gym-select-button"
                    onClick={() => this.onReselectExperimentalTraining(entry)}
                    text="Resetar"
                    color="red"
                  />
                }

              </div>
            }

          </div>

        </div>
      );
    });

    const exclude_ids = this.state.students.map((entry) => entry.id);

    const checked_students = [...this.state.students, ...this.state.early_checkins.filter((entry) => !exclude_ids.includes(entry.id))];

    switch (this.state.studentListOrderPriority) {
      case STUDENT_LIST_ORDER_PRIORITY.rac:
        checked_students.sort((a, b) => b.zone_index - a.zone_index)
        break;
      case STUDENT_LIST_ORDER_PRIORITY.disc:
        checked_students.sort((a, b) => this.getStudentDisc(a, true) - this.getStudentDisc(b, true))
        break;
      case STUDENT_LIST_ORDER_PRIORITY.checkin:
        checked_students.sort((a, b) => a.timestamp - b.timestamp)
        break;
      default:
    }

    const checkedInStudents = checked_students.map((student) => {
      let hatImage = null;
      let birthDaySignImage = null;
      let confettiImage = null;

      const todayIsodate = (new Date()).toISOString().slice(5, 10);

      if (todayIsodate === student.birthdate.slice(5, 10)) {
        if (!this.studentBirthdayHatSelection.has(student.id)) {
          this.studentBirthdayHatSelection.set(student.id, Math.floor(Math.random() * BIRTHDAY_HAT_OPTIONS.length));
        }

        hatImage = BIRTHDAY_HAT_OPTIONS[this.studentBirthdayHatSelection.get(student.id)];
        birthDaySignImage = birthdaySign;
        confettiImage = confetti;
      }

      let classCountTierImage = null;
      let classCountTierStyle = null;

      if (student.class_count >= 300) {
        classCountTierImage = userFrameGold;
        classCountTierStyle = '--gold';
      }
      else if (student.class_count >= 200) {
        classCountTierImage = userFrameSilver;
        classCountTierStyle = '--silver';
      }
      else if (student.class_count >= 100) {
        classCountTierImage = userFrameBronze;
        classCountTierStyle = '--bronze';
      }
      else if (student.class_count >= 50) {
        classCountTierImage = userFrameWood;
        classCountTierStyle = '--wood';
      }

      let fireStyle = null;

      if (student.weekly_frequency >= 4.5) {
        if (classCountTierImage !== null) {
          fireStyle = '--large-2';
        }
        else {
          fireStyle = '--2';
        }
      }
      else if (student.weekly_frequency >= 3) {
        if (classCountTierImage !== null) {
          fireStyle = '--large-1';
        }
        else {
          fireStyle = '--1';
        }
      }

      return (
        <div
          key={`students:${student.id}`}
          className={`class-board__students-panel__student${this.state.early_checkin_ids.includes(student.id) ? '--highlighted' : ''} ${this.state.gymStudentSelected !== null && this.state.gymStudentSelected.id === student.id ? 'student-selected' : ''}`}
        >

          <div className="class-board__students-panel__student__picture-wrapper">

            {fireStyle !== null &&
              <React.Fragment>

              <div className={`class-board__students-panel__student__flame${fireStyle}`}></div>
              <svg id="svg-fire" style={{width: 0, height: 0}}>
                <filter id="svg-fire-filter">
                  <feTurbulence
                    x="0"
                    y="0"
                    baseFrequency="0.03"
                    numOctaves="5"
                    seed="5">
                      <animate
                        attributeName='baseFrequency'
                        dur="8s"
                        values="0.09;0.009"
                        repeatCount="indefinite"
                      />
                    </feTurbulence>
                  <feDisplacementMap
                    in="SourceGraphic"
                    scale="20" />
                </filter>
              </svg>

              </React.Fragment>
            }

            {confettiImage !== null &&
              <img
                className="class-board__students-panel__student__confetti-image"
                src={confettiImage}
                alt=''
              />
            }

            <div className={`class-board__students-panel__student__picture-container${classCountTierImage !== null ? '--no-border' : ''}`}>

              {student.avatar_image_url ? (
                <img className="class-board__students-panel__student__avatar-image" src={student.avatar_image_url} alt={`Foto de ${student.name}`} />
              ) : (
                <i className="fas fa-user class-board__students-panel__student__picture-icon"></i>
              )}

            </div>

            {classCountTierImage !== null &&
              <React.Fragment>

                <img
                  className={`class-board__students-panel__student__user-frame${classCountTierStyle}`}
                  src={classCountTierImage}
                  alt=''
                />

                <div className='class-board__students-panel__student__class-count-text__wrapper'>

                  <p className={`class-board__students-panel__student__class-count-text${classCountTierStyle}`}>{student.class_count}</p>

                </div>

              </React.Fragment>
            }

            {hatImage !== null &&
              <img
                className="class-board__students-panel__student__hat-image"
                src={hatImage}
                alt=''
              />
            }

            {birthDaySignImage !== null &&
              <img
                className="class-board__students-panel__student__sign-image"
                src={birthDaySignImage}
                alt=''
              />
            }
          </div>

          <div className="class-board__students-panel__student__wrapper">

            <div className="class-board__students-panel__student__row-wrapper">

              <div className="class-board__students-panel__student__data-container">

                <p className={`class-board__students-panel__student__name--${this.getStudentDisc(student)} ${(student.emphasis_status && student.emphasis_status > 0) ? 'class-board__students-panel__student__name--first-class' : ''}`}>

                  {student.physical_evaluation_is_available &&
                    <i className="far fa-check-circle class-board__students-panel__student__physical-evaluation-icon"></i>
                  }

                  <span className="class-board__students-panel__student__name__first-letter">
                    {student.name[0]}
                  </span>
                  {getAbbreviatedName(student.name.slice(1))}

                </p>

                {student.note ?
                  <p className="class-board__students-panel__student__note">

                    {student.note}

                  </p>:
                  null
                }

              </div>

              <div className="class-board__students-panel__student__actions">

                {this.getStudentActions(student)}

              </div>

            </div>

            {this.props.isGymService &&
              <React.Fragment>

                {this.state.gymStudentActivePersonalPeriodMap.has(student.id) ? (

                  <div className="class-board__students-panel__student__row-wrapper">

                    {this.getTrainingPeriodProgress(student)}

                    <div className="class-board__students-panel__student__gym-buttons-container">

                      {((this.state.gymStudentActivePersonalPeriodMap.get(student.id) !== null && this.state.gymStudentActivePersonalPeriodMap.get(student.id).initial_date !== null) || this.state.gymStudentNavigationMap.has(student.id)) ?
                        <React.Fragment>

                          <DefaultMenuButton
                            className="class-board__students-panel__student__gym-select-button"
                            onClick={() => this.onResumeGymStudentTraining(student, true)}
                            text={this.state.gymStudentNavigationMap.has(student.id) ? 'Resumir' : 'Selecionar treino'}
                            color="blue"
                          />

                          {this.state.gymStudentNavigationMap.has(student.id) &&
                            <DefaultMenuButton
                              className="class-board__students-panel__student__gym-select-button"
                              onClick={() => this.onReselectStudentTraining(student)}
                              text="Resetar"
                              color="red"
                            />
                          }

                        </React.Fragment>:
                        <React.Fragment>

                          <DefaultMenuButton
                            className="class-board__students-panel__student__gym-select-button"
                            text="Não configurado"
                            color="blue"
                            disabled={true}
                          />

                          {this.state.experimentalPeriods.length > 0 &&
                            <DefaultMenuButton
                              className="class-board__students-panel__student__gym-select-button"
                              onClick={() => this.onResumeGymStudentTraining(student)}
                              text="Treino padrão"
                              color="blue"
                            />
                          }

                        </React.Fragment>
                      }

                    </div>
                  </div>
                ) : (
                  <LoadingIcon />
                )}

              </React.Fragment>
            }

            {(this.props.isCardioOrFunctionalService &&
                student.last_training_data &&
                (student.last_training_data.total_repetitions !== null || student.last_training_data.total_seconds !== null) &&
                student.last_training_data !== null &&
                this.state.trainingGroupSelected !== null &&
                this.state.trainingGroupSelected.group_name === TRAINING_GROUP_WORKOUT_KEY) &&
              <div className="class-board__students-panel__student__workout-data">

                {/* <p className="class-board__students-panel__student__workout-data__title">Último treino</p> */}

                <div className="class-board__students-panel__student__workout-data__indicators">
                  {student.last_training_data.difficulty !== null &&
                    <p className="class-board__students-panel__student__workout-data__text">
                      <span className="class-board__students-panel__student__workout-data__label">Dif:</span>
                      <span className="class-board__students-panel__student__workout-data__value">
                        {student.last_training_data.difficulty}
                      </span>
                    </p>
                  }
                  {student.last_training_data.total_repetitions !== null &&
                    <p className="class-board__students-panel__student__workout-data__text">
                      <span className="class-board__students-panel__student__workout-data__label">Reps:</span>
                      <span className="class-board__students-panel__student__workout-data__value">
                        {student.last_training_data.total_repetitions}
                      </span>
                    </p>
                  }
                  {student.last_training_data.total_seconds !== null &&
                    <p className="class-board__students-panel__student__workout-data__text">
                      <span className="class-board__students-panel__student__workout-data__label">Tempo:</span>
                      <span className="class-board__students-panel__student__workout-data__value">
                        {`${Math.floor(student.last_training_data.total_seconds / 60)}:${student.last_training_data.total_seconds % 60}`}
                      </span>
                    </p>
                  }
                </div>

              </div>
            }

          </div>

        </div>
      );
    });

    return [...experimentalClasses, ...checkedInStudents];
  }

  setTrainingGroup(trainingGroup) {
    const update = {
      trainingGroupSelected: trainingGroup,
      currentClockScale: 1,
    };

    const currentGroupSelection = this.getCurrentGroupSelection();

    for (const group of currentGroupSelection) {
      if (this.state.clockMusicMap.has(group.id)) {
        const musicData = this.state.clockMusicMap.get(group.id);

        musicData.audioFile.pause();
        musicData.audioFile.currentTime = 0;
      }
    }

    if (trainingGroup === null && this.state.trainingDaySelected.group_associations.length > 0) {
      update.overviewSelectedGroup = this.state.trainingDaySelected.group_associations[0];
    }
    else if (trainingGroup !== null) {
      update.clockParametersMap = new Map();
      update.clockParametersMap.set(trainingGroup.id, this.initializeClockGroupParameters(trainingGroup, false));

      if (!this.props.isGymService) {
        for (let i=trainingGroup.order; i < this.state.trainingDaySelected.group_associations.length; ++i) {
          const group = this.state.trainingDaySelected.group_associations[i];

          if (!group.merge_with_previous_group) {
            break;
          }

          update.clockParametersMap.set(group.id, this.initializeClockGroupParameters(group, false));
        }
      }
    }

    this.setState(update);
  }

  getMainNavigation() {
    if(this.state.training === null || this.state.trainingDaySelected === null || !this.state.trainingDaySelected.group_associations.length) {
      return null;
    }

    const buttonsDisabled = this.state.trainingGroupSelected !== null && this.state.clockParametersMap.get(this.state.trainingGroupSelected.id).clockState === CLOCK_STATE_PLAYING;

    let isLastGroup = false;

    let previousGroupIndex = this.state.trainingGroupSelected !== null ? this.state.trainingGroupSelected.order - 2 : -1;
    let nextGroupIndex = this.state.trainingGroupSelected !== null ? this.state.trainingGroupSelected.order : 0;

    let nextTrainingGroup = null;
    let previousTrainingGroup = null;

    while(previousGroupIndex >= 0 && previousTrainingGroup === null) {
      const groupCandidate = this.state.trainingDaySelected.group_associations[previousGroupIndex];

      if (this.props.isGymService || !groupCandidate.merge_with_previous_group) {
        previousTrainingGroup = groupCandidate;
      }
      else {
        previousGroupIndex -= 1;
      }
    }

    while(nextGroupIndex < this.state.trainingDaySelected.group_associations.length && nextTrainingGroup === null) {
      const groupCandidate = this.state.trainingDaySelected.group_associations[nextGroupIndex];

      if (this.props.isGymService || !groupCandidate.merge_with_previous_group) {
        nextTrainingGroup = groupCandidate;
      }
      else {
        nextGroupIndex += 1;
      }
    }

    if (nextTrainingGroup !== null) {
      let nextIndex = nextGroupIndex;

      while(nextIndex < this.state.trainingDaySelected.group_associations.length) {
        if(nextIndex === this.state.trainingDaySelected.group_associations.length-1) {
          isLastGroup = true;
          break;
        }
        else if(this.props.isGymService) {
          break;
        }
        else {
          nextIndex += 1;

          if (!this.state.trainingDaySelected.group_associations[nextIndex].merge_with_previous_group) {
            break;
          }
        }
      }
    }

    if(nextTrainingGroup) {
      return (
        <nav className="class-board__main__nav-container">

          {this.state.trainingGroupSelected !== null &&
            <DefaultMenuButton
              className="class-board__main__nav-button"
              onClick={() => this.setTrainingGroup(previousTrainingGroup)}
              text={(
                <React.Fragment>

                  <i className="fas fa-chevron-left"></i>

                </React.Fragment>
              )}
              disabled={buttonsDisabled}
            />
          }

          <DefaultMenuButton
            className="class-board__main__nav-button"
            onClick={() => {
              if(isLastGroup && !this.props.isGymService) {
                this.setState({studentListVisible: true});
              }

              this.setTrainingGroup(nextTrainingGroup);
            }}
            text={(
              <React.Fragment>

                {(this.state.trainingGroupSelected === null || this.state.screenWidth > 420) &&
                  <span className="class-board__main__nav-button__text">
                    {this.state.trainingGroupSelected === null ? 'Iniciar' : parseTextForIcons(nextTrainingGroup.name, 'navigation:group:name', 'class-board__parsed-text')}
                  </span>
                }
                <i className="fas fa-chevron-right class-board__main__nav-button__icon"></i>

              </React.Fragment>
            )}
            color="blue"
            disabled={buttonsDisabled}
          />

        </nav>
      );
    }

    return (
      <nav className="class-board__main__nav-container">

        {(this.state.trainingGroupSelected !== null) &&
          <DefaultMenuButton
            className="class-board__main__nav-button"
            onClick={() => {
              this.setTrainingGroup(previousTrainingGroup);
            }}
            text={(
              <React.Fragment>

                <i className="fas fa-chevron-left"></i>

              </React.Fragment>
            )}
            disabled={buttonsDisabled}
          />
        }

        {(!this.props.isGymService && !this.props.basicAccess) ? (
            this.state.classIsCompleted ?
              <DefaultMenuButton
                className="class-board__main__nav-button"
                onClick={() => this.setState({onBackClicked: true})}
                text={this.state.hashedClassId === null ? 'Fechar' : 'Preencher dados de alunos'}
                color="red"
                disabled={buttonsDisabled}
              />:
              <DefaultMenuButton
                className="class-board__main__nav-button"
                onClick={() => this.setState({
                  onFinishClass: true
                })}
                text="Finalizar aula"
                color="green"
                // disabled={buttonsDisabled}
              />):
            null
        }

      </nav>
    );
  }

  playAudioBuffer(audioBuffer, audioIdentifier=null) {
    const currentTime = Date.now();

    if (audioIdentifier !== null) {
      if (this.soundPlayTimeMap.has(audioIdentifier) && (currentTime - this.soundPlayTimeMap.get(audioIdentifier)) < this.minSoundReplayPeriod) {
        return null;
      }

      this.soundPlayTimeMap.set(audioIdentifier, currentTime);
    }

    const audioSource = new AudioBufferSourceNode(this.audioContext, {
      buffer: audioBuffer
    });

    const soundId = this.currentSoundId;
    this.currentSoundId += 1;

    this.activeSoundIdSet.add(soundId);

    audioSource.connect(this.audioContext.destination);
    audioSource.start();

    if (this.audioContext.state === "suspended") {
      this.audioContext.resume();
    }

    audioSource.onended = () => {
      this.activeSoundIdSet.delete(soundId);
    };

    return audioSource;
  }

  setClockToRescale() {
    if(this.willRescaleClock === false && this.state.trainingGroupSelected !== null) {
      this.willRescaleClock = true;

      setTimeout(() => {
        if (this.clockElement !== null && this.state.trainingGroupSelected.has_clock && this.trainingGroupTitleElement !== null) {
          let clockScale = 1;

          if (this.isFullscreen() || (this.state.trainingGroupSelected !== null && this.state.clockParametersMap.get(this.state.trainingGroupSelected.id).clockState === CLOCK_STATE_PLAYING)) {
            const clockRect = this.clockElement.getBoundingClientRect();
            const fontSize = parseFloat(getComputedStyle(this.clockTimerElement).fontSize);

            let targetMaxWidth;
            let currentWidth;

            const trainingGroupTitleRect = this.trainingGroupTitleElement.getBoundingClientRect();
            clockScale = this.state.currentClockScale * ((trainingGroupTitleRect.bottom - trainingGroupTitleRect.height * 0.15) - clockRect.top) / clockRect.height;

            const marginOffset = 0.5 * clockScale * fontSize;

            if (this.isFullscreen()) {
              if (this.clockLapElement !== null) {
                const clockLapRect = this.clockLapElement.getBoundingClientRect();
                currentWidth = clockRect.width - (clockLapRect.left - clockRect.left);
              }
              else {
                const clockTimerRect = this.clockTimerElement.getBoundingClientRect();
                currentWidth = clockRect.width - (clockTimerRect.left - clockRect.left);
              }

              targetMaxWidth = this.state.screenWidth - marginOffset;
            }
            else {
              currentWidth = clockRect.width;
              targetMaxWidth = this.state.screenWidth - (2 * (this.state.screenWidth - clockRect.right)) - marginOffset;
            }

            clockScale = Math.min(clockScale, this.state.currentClockScale * targetMaxWidth / currentWidth);
            clockScale = Math.max(clockScale, 1);
          }

          this.setState({
            currentClockScale: clockScale
          });

          this.willRescaleClock = false;
        }
      }, 500);
    }
  }

  initializeClockGroupParameters(trainingGroup, updateState=true) {
    if (!trainingGroup.has_clock && updateState) {
      return;
    }

    const initialState = {
      clockTimeElaped: 0,
      clockLastSoundPlayedAt: 0,
      clockTimeReference: null,
      clockRound: 1,
      clockState: CLOCK_STATE_STOPPED,
      clockPhase: CLOCK_PHASE_PREPARING,
      clock_method: trainingGroup.clock_method,
      clock_time_limit: trainingGroup.clock_time_limit,
      clock_round_count: trainingGroup.clock_round_count || null,
      clock_round_period: trainingGroup.clock_round_period || null,
      clock_custom_array: trainingGroup.clock_custom_array || null,
      clock_preparation_period: trainingGroup.clock_preparation_period || null,
      pause_on_round_end: trainingGroup.pause_on_round_end || false,
      clock_rest_period: trainingGroup.clock_rest_period || null,
      active_color: trainingGroup.active_color || null,
    };

    if (updateState) {
      this.state.clockParametersMap.set(trainingGroup.id, initialState);

      this.setState({clockParametersMap: new Map(this.state.clockParametersMap)});
    }

    return initialState;
  }

  playClock(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = {...this.state.clockParametersMap.get(group.id)};

    let nextClockState = CLOCK_STATE_PLAYING;

    if(clockParameters.clock_method === CLOCK_METHOD_STOPWATCH || clockParameters.clock_method === CLOCK_METHOD_TIMER) {
      if(clockParameters.clockTimeElaped > (clockParameters.clock_time_limit*60)) {
        nextClockState = CLOCK_STATE_FINISHED;
      }
    }
    else if(clockParameters.clock_method === CLOCK_METHOD_SERIES || clockParameters.clock_method === CLOCK_METHOD_TABATA) {
      if(clockParameters.clockRound >= clockParameters.clock_round_count && clockParameters.clockTimeElaped > clockParameters.clock_round_period) {
        nextClockState = CLOCK_STATE_FINISHED;
      }
    }
    else if(clockParameters.clock_method === CLOCK_METHOD_CUSTOM) {
      const clock_custom_array = clockParameters.clock_custom_array;
      const roundIndex = Math.min(clockParameters.clockRound-1, clock_custom_array.length);

      if(clockParameters.clockRound >= clockParameters.clock_round_count && clockParameters.clockTimeElaped > clock_custom_array[roundIndex].period) {
        nextClockState = CLOCK_STATE_FINISHED;
      }
    }

    clockParameters.clockTimeReference = Date.now() - (clockParameters.clockTimeElaped*1000);
    clockParameters.clockState = nextClockState;

    this.state.clockParametersMap.set(group.id, clockParameters);

    this.setState({
      clockParametersMap: new Map(this.state.clockParametersMap)
    });

    this.setClockToRescale();

    if (this.state.clockMusicMap.has(group.id)) {
      const musicData = this.state.clockMusicMap.get(group.id);

      if (!musicData.audioFile.ended) {
        musicData.audioFile.play();
      }

      if (this.audioContext.state === "suspended") {
        this.audioContext.resume();
      }
    }

    requestAnimationFrame(this.updateClock.bind(this, group));
  }

  pauseClock(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = {...this.state.clockParametersMap.get(group.id)};
    clockParameters.clockState = CLOCK_STATE_PAUSED;

    this.state.clockParametersMap.set(group.id, clockParameters);

    this.setClockToRescale();

    if (this.state.clockMusicMap.has(group.id)) {
      const musicData = this.state.clockMusicMap.get(group.id);

      musicData.audioFile.pause();
    }

    this.setState({clockParametersMap: new Map(this.state.clockParametersMap)});
  }

  resetClock(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    this.setClockToRescale();

    if (this.state.clockMusicMap.has(group.id)) {
      const musicData = this.state.clockMusicMap.get(group.id);

      musicData.audioFile.currentTime = 0;
    }

    this.initializeClockGroupParameters(group);
  }

  updateClock(group) {
    if (!this.mayUpdateClock || !this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = this.state.clockParametersMap.get(group.id);

    let updateClock = clockParameters.clockState === CLOCK_STATE_PLAYING;

    if(clockParameters.clock_method === CLOCK_METHOD_STOPWATCH) {
      if(clockParameters.clockState === CLOCK_STATE_FINISHED) {
        updateClock = true;
      }
    }

    if(updateClock) {
      const update = {};

      let elapsedTime = ((Date.now() - clockParameters.clockTimeReference)/1000);

      if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING) {
        if(elapsedTime > clockParameters.clock_preparation_period) {
          update.clockPhase = CLOCK_PHASE_NORMAL;
          elapsedTime = 0;
          update.clockTimeReference = Date.now();
          this.playAudioBuffer(this.startAudioBuffer, 'start');
          update.clockLastSoundPlayedAt = 0;
        }
        else if(clockParameters.clock_preparation_period - elapsedTime < 3) {
          if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
            update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
            this.playAudioBuffer(this.startCountAudioBuffer, 'startCount');
          }
        }
        // else if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
        //   update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
        //   this.playAudioBuffer(this.startCountAudioBuffer, 'startCount');
        // }
      }
      else if(clockParameters.clockPhase === CLOCK_PHASE_NORMAL) {
        if(clockParameters.clock_method === CLOCK_METHOD_SERIES) {
          if(elapsedTime > clockParameters.clock_round_period) {
            if(clockParameters.clockRound >= clockParameters.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.playAudioBuffer(this.endAudioBuffer, 'end');
            }
            else {
              if(clockParameters.pause_on_round_end) {
                update.clockState = CLOCK_STATE_PAUSED;
              }
              update.clockRound = clockParameters.clockRound + 1;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(clockParameters.clock_round_period - elapsedTime < FINAL_SECOND_COUNT) {
            if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.playAudioBuffer(this.minuteTurnAudioBuffer, 'minuteTurn');
            }
          }
        }
        else if(clockParameters.clock_method === CLOCK_METHOD_TABATA) {
          if(elapsedTime > clockParameters.clock_round_period) {
            if(clockParameters.clockRound >= clockParameters.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.playAudioBuffer(this.endAudioBuffer, 'end');
            }
            else {
              update.clockPhase = CLOCK_PHASE_RESTING;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(clockParameters.clock_round_period - elapsedTime < TABATA_FINAL_SECOND_COUNT) {
            if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.playAudioBuffer(this.minuteTurnAudioBuffer, 'minuteTurn');
            }
          }
        }
        else if(clockParameters.clock_method === CLOCK_METHOD_CUSTOM) {
          const clock_custom_array = clockParameters.clock_custom_array;
          const roundIndex = Math.min(clockParameters.clockRound-1, clock_custom_array.length);
          const roundData = clock_custom_array[roundIndex];

          if(elapsedTime > roundData.period) {
            if(clockParameters.clockRound >= clockParameters.clock_round_count) {
              update.clockState = CLOCK_STATE_FINISHED;
              update.clockPhase = CLOCK_PHASE_POST_TIME;
              this.playAudioBuffer(this.endAudioBuffer, 'end');
            }
            else {
              if(roundData.pause_on_end) {
                update.clockState = CLOCK_STATE_PAUSED;
              }
              update.clockRound = clockParameters.clockRound + 1;
              elapsedTime = 0;
              update.clockTimeReference = Date.now();
              update.clockLastSoundPlayedAt = 0;
            }
          }
          else if(roundData.period - elapsedTime < PERSONALIZED_FINAL_SECOND_COUNT) {
            if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.playAudioBuffer(this.minuteTurnAudioBuffer, 'minuteTurn');
            }
          }
        }
        else {
          if(elapsedTime > (clockParameters.clock_time_limit*60)) {
            update.clockState = CLOCK_STATE_FINISHED;
            update.clockPhase = CLOCK_PHASE_POST_TIME;
            this.playAudioBuffer(this.endAudioBuffer, 'end');
          }
          else if((clockParameters.clock_time_limit*60) - elapsedTime < FINAL_SECOND_COUNT) {
            if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.playAudioBuffer(this.minuteTurnAudioBuffer, 'minuteTurn');
            }
          }
          else if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 60) {
            update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
            this.playAudioBuffer(this.minuteTurnAudioBuffer, 'minuteTurn');
          }
        }
      }
      else if(clockParameters.clockPhase === CLOCK_PHASE_RESTING) {
        if(clockParameters.clock_method === CLOCK_METHOD_TABATA) {
          if(elapsedTime > clockParameters.clock_rest_period) {
            update.clockPhase = CLOCK_PHASE_NORMAL;
            update.clockRound = clockParameters.clockRound + 1;
            elapsedTime = 0;
            update.clockTimeReference = Date.now();
            this.playAudioBuffer(this.startAudioBuffer, 'start');
            update.clockLastSoundPlayedAt = 0;
          }
          else if(clockParameters.clock_rest_period - elapsedTime < 3) {
            if(elapsedTime - clockParameters.clockLastSoundPlayedAt > 1) {
              update.clockLastSoundPlayedAt = Math.floor(elapsedTime);
              this.playAudioBuffer(this.startCountAudioBuffer, 'startCount');
            }
          }
        }
      }

      if (this.state.clockMusicMap.has(group.id)) {
        const musicData = this.state.clockMusicMap.get(group.id);

        if (this.activeSoundIdSet.size <= 0) {
          musicData.gainNode.gain.value = 1;
        }
        else {
          musicData.gainNode.gain.value = 0.3;
        }
      }

      update.clockTimeElaped = elapsedTime;

      this.state.clockParametersMap.set(group.id, {...clockParameters, ...update});

      this.setState({clockParametersMap: new Map(this.state.clockParametersMap)});

      requestAnimationFrame(this.updateClock.bind(this, group));
    }
  }

  getClockTime(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = this.state.clockParametersMap.get(group.id);

    if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING && clockParameters.clock_preparation_period) {
      const secondsLeft = Math.ceil(clockParameters.clock_preparation_period - clockParameters.clockTimeElaped);
      return Math.max(secondsLeft, 0);
    }
    else if(clockParameters.clockPhase === CLOCK_PHASE_RESTING) {
      const secondsLeft = Math.ceil(clockParameters.clock_rest_period - clockParameters.clockTimeElaped);
      return Math.max(secondsLeft, 0);
    }

    if(clockParameters.clock_method === CLOCK_METHOD_TIMER) {
      const timeLeft = clockParameters.clock_time_limit*60 - clockParameters.clockTimeElaped;

      let minutesLeft = Math.floor(timeLeft/60);
      let secondsLeft = Math.ceil(timeLeft % 60);

      if(secondsLeft > 59) {
        secondsLeft = 0;
        minutesLeft += 1;
      }

      secondsLeft = Math.max(secondsLeft, 0);
      minutesLeft = Math.max(minutesLeft, 0);

      return `${minutesLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}:${secondsLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}`;
    }

    const minutesLeft = Math.floor(clockParameters.clockTimeElaped/60);
    const secondsLeft = Math.floor(clockParameters.clockTimeElaped % 60);

    return `${minutesLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}:${secondsLeft.toLocaleString('en-IN', {minimumIntegerDigits: 2})}`;
  }

  getClockCountLabel(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = this.state.clockParametersMap.get(group.id);

    if (clockParameters.clock_method !== CLOCK_METHOD_TABATA &&
        clockParameters.clock_method !== CLOCK_METHOD_SERIES &&
        clockParameters.clock_method !== CLOCK_METHOD_CUSTOM) {
      return null;
    }

    if(clockParameters.clock_method === CLOCK_METHOD_CUSTOM) {
      const clock_custom_array = clockParameters.clock_custom_array;
      const roundIndex = Math.min(clockParameters.clockRound-1, clock_custom_array.length);
      const roundData = clock_custom_array[roundIndex];

      if(roundData.count_label && roundData.count_label !== null) {
        return parseTextForIcons(roundData.count_label, 'classboard:clock-label', 'class-board__clock__lap__parsed-text');
      }
    }

    return clockParameters.clockRound;
  }

  getClockStyle(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = this.state.clockParametersMap.get(group.id);

    if(clockParameters.clockState === CLOCK_STATE_PLAYING) {
      if(clockParameters.clock_method === CLOCK_METHOD_SERIES) {
        if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || (clockParameters.clock_round_period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return {};
        }
        else if(clockParameters.active_color) {
          return {color: clockParameters.active_color};
        }
      }
      else if(clockParameters.clock_method === CLOCK_METHOD_CUSTOM) {
        const clock_custom_array = clockParameters.clock_custom_array;
        const roundIndex = Math.min(clockParameters.clockRound-1, clock_custom_array.length);
        const roundData = clock_custom_array[roundIndex];

        if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || (roundData.period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return {};
        }
        else {
          return {color: roundData.color};
        }
      }
    }

    return {};
  }

  getClockSpecifier(group) {
    if (!this.state.clockParametersMap.has(group.id)) {
      return;
    }

    const clockParameters = this.state.clockParametersMap.get(group.id);

    if(clockParameters.clockState === CLOCK_STATE_PLAYING) {
      if(clockParameters.clock_method === CLOCK_METHOD_SERIES) {
        if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || (clockParameters.clock_round_period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else if(clockParameters.clock_method === CLOCK_METHOD_CUSTOM) {
        const clock_custom_array = clockParameters.clock_custom_array;
        const roundIndex = Math.min(clockParameters.clockRound-1, clock_custom_array.length);
        const roundData = clock_custom_array[roundIndex];

        if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || (roundData.period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else if(clockParameters.clock_method === CLOCK_METHOD_TABATA) {
        if(clockParameters.clockPhase === CLOCK_PHASE_RESTING) {
          if((clockParameters.clock_rest_period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
            return '--yellow';
          }

          return '--blue';
        }
        else if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || (clockParameters.clock_round_period - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
      else {
        if(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || ((clockParameters.clock_time_limit*60) - clockParameters.clockTimeElaped < FINAL_SECOND_COUNT)) {
          return '--yellow';
        }

        return '--green';
      }
    }

    return '';
  }

  toggleFullscreen() {
    if(!this.state.clockExpanded) {
      this.mainRef.current.requestFullscreen();
    }
    else if(document.fullscreenElement) {
      document.exitFullscreen();
    }

    this.setClockToRescale();

    this.setState({clockExpanded: !this.state.clockExpanded});
  }

  onSelectGymPeriod(period) {
    if(this.state.gymStudentSelected === null && this.state.gymExperimentalSelected === null) {
      return;
    }

    const update = {};

    update.trainingPeriodSelected = period;
    update.trainingDaySelected = null;
    update.trainingGroupSelected = null;
    update.overviewSelectedGroup = null;

    if(this.state.gymStudentSelected !== null) {
      this.state.gymStudentNavigationMap.set(this.state.gymStudentSelected.id, {...update});
      update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
    }
    else {
      this.state.gymExperimentalNavigationMap.set(this.state.gymExperimentalSelected.id, {...update});
      update.gymExperimentalNavigationMap = new Map(this.state.gymExperimentalNavigationMap);
    }

    this.setState(update);
  }

  async onSelectGymTraining(training, setAsActiveTraining=false) {
    if(this.state.gymStudentSelected === null && this.state.gymExperimentalSelected === null) {
      return;
    }

    const update = {};

    update.trainingDaySelected = training;
    update.trainingGroupSelected = null;

    if(training.group_associations.length > 0) {
      update.overviewSelectedGroup = training.group_associations[0];
    }

    if(this.state.gymStudentSelected !== null) {
      this.state.gymStudentNavigationMap.set(this.state.gymStudentSelected.id, {...update});
      update.gymStudentNavigationMap = new Map(this.state.gymStudentNavigationMap);
    }
    else {
      this.state.gymExperimentalNavigationMap.set(this.state.gymExperimentalSelected.id, {...update});
      update.gymExperimentalNavigationMap = new Map(this.state.gymExperimentalNavigationMap);
    }

    if (setAsActiveTraining && this.state.gymStudentSelected !== null) {
      const active_personal_training = this.state.gymStudentActivePersonalPeriodMap.has(this.state.gymStudentSelected.id) ? this.state.gymStudentActivePersonalPeriodMap.get(this.state.gymStudentSelected.id) : null;

      if (active_personal_training !== null && active_personal_training.training_period) {
        this.setState({
          loading: true
        });

        update.loading = false;

        const data = {training_day_id: training.id};

        try{
          await postModel(`${routes.STUDENT_BASE_API}${this.state.gymStudentSelected.id}${routes.STUDENT_ACTIVE_GYM_TRAINING_DAY_POST_API}`, data);
        }
        catch(errors) {
          let errorDescription = "Falha durante comunicação com o sistema. Alguns serviços podem não estar funcionando corretamente.";

          // if(errors instanceof Array) {
          //   for(let error of errors) {
          //     switch (error.code) {
          //       case 104:
          //         for(let parameter of error.parameters) {
          //           switch (parameter.name) {
          //             case 'contracts':
          //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
          //
          //               break;
          //             default:
          //           }
          //         }
          //
          //         break;
          //       default:
          //     }
          //   }
          // }

          update.onSelectActiveGymTraining = true;
          update.confirmFailed = true;
          update.confirmFailDescription = errorDescription;
        }
      }
    }

    this.setState(update);
  }

  getMainContent() {
    if(this.state.trainingGroupSelected !== null) {
      if (!this.props.isGymService && this.mayShowUnifiedGroupSelection()) {
        const groupSelection = this.getCurrentGroupSelection();

        const groupClocks = groupSelection.filter((entry) => entry.has_clock).map((group) => {
          const clockParameters = this.state.clockParametersMap.get(group.id);

          return (
            <div
              key={`training_clock:${group.id}`}
              className="class-board__unified-group-view__clock-container"
              onClick={() => this.setState({overlayTrainingGroupSelected: group})}
            >

              <h3 className="class-board__unified-group-view__clock__title">
                {parseTextForIcons(group.name, `training_clock:${group.id}:name`, 'class-board__parsed-text')}
              </h3>

              <TrainingClock
                className="class-board__unified-group-view__clock"
                showControlButtons={false}
                showCompressButton={false}
                clockLabel={this.getClockCountLabel(group)}
                clockLabelStyle={this.getClockStyle(group)}
                clockTimeStyle={this.getClockStyle(group)}
                clockTime={this.getClockTime(group)}
                timeTextAlignment={(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || clockParameters.clockPhase === CLOCK_PHASE_RESTING) ? 'center' : null}
                color={this.getClockSpecifier(group)}
              />

            </div>
          );
        });

        return (
          <div className="class-board__unified-group-view">

            {groupClocks}

          </div>
        );
      }

      const mainClockStyle = {};
      mainClockStyle.transform = `scale(${this.state.currentClockScale}, ${this.state.currentClockScale})`;

      const clockParameters = this.state.clockParametersMap.get(this.state.trainingGroupSelected.id);

      return (
        <React.Fragment>

          <div className={`class-board__clock-container${!this.state.trainingGroupSelected.has_clock ? '--hidden' : ''}`}>

            <TrainingClock
              elementRef={this.clockRef}
              className={`class-board__clock${this.isFullscreen() ? '--super-expanded' : ''}`}
              sizeScale={this.state.currentClockScale}
              showControlButtons={!this.isFullscreen()}
              showResetButton={clockParameters.clockState !== CLOCK_STATE_STOPPED && clockParameters.clockState !== CLOCK_STATE_PLAYING}
              showPauseButton={clockParameters.clockState === CLOCK_STATE_PLAYING || (clockParameters.clock_method === CLOCK_METHOD_STOPWATCH && clockParameters.clockState === CLOCK_STATE_FINISHED)}
              showPlayButton={clockParameters.clockState !== CLOCK_STATE_PLAYING && clockParameters.clockState !== CLOCK_STATE_FINISHED}
              onResetClock={() => this.resetClock(this.state.trainingGroupSelected)}
              onPauseClock={() => this.pauseClock(this.state.trainingGroupSelected)}
              onPlayClock={() => this.playClock(this.state.trainingGroupSelected)}
              onClickTime={() => this.isFullscreen(true) ? null : this.toggleFullscreen()}
              onClickCompressButton={() => this.toggleFullscreen()}
              showCompressButton={this.isFullscreen()}
              clockLabel={this.getClockCountLabel(this.state.trainingGroupSelected)}
              clockLabelStyle={this.getClockStyle(this.state.trainingGroupSelected)}
              clockTimeStyle={this.getClockStyle(this.state.trainingGroupSelected)}
              clockTime={this.getClockTime(this.state.trainingGroupSelected)}
              timeTextAlignment={(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || clockParameters.clockPhase === CLOCK_PHASE_RESTING) ? 'center' : null}
              color={this.getClockSpecifier(this.state.trainingGroupSelected)}
            />

          </div>

          {/* <div className={`class-board__clock-container${!this.state.trainingGroupSelected.has_clock ? '--hidden' : ''}`}>

            <div
              className={`class-board__clock${this.isFullscreen() ? '--super-expanded' : this.state.clockState === CLOCK_STATE_PLAYING ? '--expanded' : ''}`}
              ref={this.clockRef}
              style={mainClockStyle}
            >

              {!this.isFullscreen() &&
                <div className="class-board__clock__controls">

                  {(this.state.clockState !== CLOCK_STATE_STOPPED && this.state.clockState !== CLOCK_STATE_PLAYING) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.resetClock()}
                      text={(<i className="fas fa-undo-alt"></i>)}
                    />
                  }

                  {(this.state.clockState === CLOCK_STATE_PLAYING || (this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_STOPWATCH && this.state.clockState === CLOCK_STATE_FINISHED)) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.pauseClock()}
                      text={(<i className="fas fa-pause"></i>)}
                    />
                  }

                  {(this.state.clockState !== CLOCK_STATE_PLAYING && this.state.clockState !== CLOCK_STATE_FINISHED) &&
                    <DefaultMenuButton
                      className="class-board__clock__control-button"
                      onClick={() => this.playClock()}
                      text={(<i className="fas fa-play"></i>)}
                    />
                  }

                </div>
              }

              {(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) &&
                <div className={`class-board__clock__lap-wrapper${this.getClockSpecifier()}`}>

                  <p
                    className="class-board__clock__lap"
                    style={this.getClockStyle()}
                  >

                    {this.getClockCountLabel()}

                  </p>

                </div>
              }

              <div
                className={`class-board__clock__time-wrapper${this.getClockSpecifier()}`}
                onClick={() => this.isFullscreen(true) ? null : this.toggleFullscreen()}
              >

                <p
                  className={`class-board__clock__time${(this.state.clockPhase === CLOCK_PHASE_PREPARING || this.state.clockPhase === CLOCK_PHASE_RESTING) ? '--centered' : ''} clock-timer`}
                  style={this.getClockStyle()}
                >

                  {this.getClockTime()}

                </p>

                <button
                  className="class-board__clock__compress-button"
                  onClick={() => this.toggleFullscreen()}
                >
                  <i className="fas fa-compress"></i>
                </button>

              </div>

            </div>

          </div> */}

          {this.getTrainingGroup(this.state.trainingGroupSelected, (this.state.trainingGroupSelected && this.state.trainingGroupSelected.group_is_phase_constant) ? 1 : this.state.training.repetition_index)}

        </React.Fragment>
      );
    }
    else if(this.props.isGymService && this.state.trainingDaySelected === null) {
      if(this.state.gymStudentSelected !== null) {
        const personal_training = this.state.gymStudentActivePersonalPeriodMap.has(this.state.gymStudentSelected.id) ? this.state.gymStudentActivePersonalPeriodMap.get(this.state.gymStudentSelected.id) : null;

        const trainingPeriod = personal_training !== null ? (personal_training.training_period || this.state.trainingPeriodSelected) : this.state.trainingPeriodSelected;

        let trainingOptions;
        let selectorTitle;

        if(trainingPeriod === null) {
          selectorTitle = 'Selecione uma periodização';

          const periodIds = this.state.experimentalPeriods.map((entry) => entry.id);

          const lastTraining = {date: null, id: null};

          for(const data of personal_training.training_data) {
            if(!periodIds.includes(data.training_period_id)) {
              continue;
            }

            if(lastTraining.date === null || lastTraining.date < data.date) {
              lastTraining.date = data.date;
              lastTraining.id = data.training_period_id;
            }
          }

          trainingOptions = this.state.experimentalPeriods.map((period) => {
            const executedLast = (lastTraining.date !== null && lastTraining.id === period.id);
            let lastExecutionDate = null;
            let daysCount = 0;

            if(executedLast) {
              lastExecutionDate = getAsLocalDate(lastTraining.date);
              const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

              const timeDiff = Math.abs(today.getTime() - lastExecutionDate.getTime());
              daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));
            }

            return (
              <div
                className={`class-board__personal-training__training-option${executedLast ? '--highlighted' : ''}`}
                key={`training_period:${period.id}`}
              >

                <div className="class-board__personal-training__training-option__label">

                  <p className="class-board__personal-training__training-option__label__text">{period.name}</p>

                  {executedLast &&
                    <p className="class-board__personal-training__training-option__label__date">{`${lastExecutionDate.toLocaleDateString()} - ${daysCount} dia(s)`}</p>
                  }

                </div>

                <div className="class-board__personal-training__training-option__text-wrapper">

                  {period.description !== null &&
                    <p className="class-board__personal-training__training-option__text">{period.description}</p>
                  }

                </div>

                <div className="class-board__personal-training__training-option__action-container">

                  <DefaultMenuButton
                    className="class-board__personal-training__training-option__action"
                    onClick={() => this.onSelectGymPeriod(period)}
                    text="Selecionar"
                    color="purple"
                  />

                </div>

              </div>
            );
          });
        }
        else if(trainingPeriod.training_days.length <= 0) {
          selectorTitle = trainingPeriod.name;
          trainingOptions = 'Periodização não configurada';
        }
        else {
          selectorTitle = trainingPeriod.name;

          const periodIds = trainingPeriod.training_days.map((entry) => entry.id);

          const lastTraining = {date: null, id: null};

          for(const data of personal_training.training_data) {
            if(!periodIds.includes(data.training_day_id)) {
              continue;
            }

            if(lastTraining.date === null || lastTraining.date < data.date) {
              lastTraining.date = data.date;
              lastTraining.id = data.training_day_id;
            }
          }

          trainingOptions = trainingPeriod.training_days.map((training) => {
            const executedLast = (lastTraining.date !== null && lastTraining.id === training.id);
            let lastExecutionDate = null;
            let daysCount = 0;

            if(executedLast) {
              lastExecutionDate = getAsLocalDate(lastTraining.date);
              const today = getAsLocalDate((new Date()).toISOString().slice(0, 10));

              const timeDiff = Math.abs(today.getTime() - lastExecutionDate.getTime());
              daysCount = Math.ceil(timeDiff / (1000 * 3600 * 24));
            }

            return (
              <div
                className={`class-board__personal-training__training-option${executedLast ? '--highlighted' : ''}`}
                key={`training_day:${training.id}`}
              >

                <div className="class-board__personal-training__training-option__label">

                  <p className="class-board__personal-training__training-option__label__text">{training.name}</p>

                  {executedLast &&
                    <p className="class-board__personal-training__training-option__label__date">{`${lastExecutionDate.toLocaleDateString()} - ${daysCount} dia(s)`}</p>
                  }

                </div>

                <div className="class-board__personal-training__training-option__text-wrapper">

                  {training.note !== null &&
                    <p className="class-board__personal-training__training-option__text"> <span className="class-board__personal-training__training-option__note-label">OBS:</span> {training.note}</p>
                  }

                </div>

                {!training.is_placeholder &&
                  <div className="class-board__personal-training__training-option__action-container">

                    <DefaultMenuButton
                      className="class-board__personal-training__training-option__action"
                      onClick={() => this.onSelectGymTraining(training, true)}
                      text="Iniciar"
                      color="green"
                    />

                  </div>
                }

              </div>
            );
          });
        }

        let totalProgress = null;

        if(personal_training.initial_date !== null) {
          const cycleCount = trainingPeriod.repetition_count;
          const initialDate = getAsLocalDate(personal_training.initial_date);

          let trainingsExecuted = 0;

          for(const trainingData of personal_training.training_data) {
            const executionDate = getAsLocalDate(trainingData.date);

            if(executionDate >= initialDate) {
              trainingsExecuted += 1;
            }
          }

          totalProgress = 100 * trainingsExecuted / (trainingPeriod.training_days.length * cycleCount);
        }

        return (
          <section className="class-board__personal-training__period-overview">

            <header className="class-board__personal-training__period-overview__header">

              <div className="class-board__personal-training__period-overview__header__title-wrapper">

                <h3 className="class-board__personal-training__period-overview__header__title">{selectorTitle}</h3>
                <h2 className="class-board__personal-training__period-overview__header__sub-title">{this.state.gymStudentSelected.name}</h2>

              </div>

              {personal_training.initial_date !== null &&
                <div className="class-board__personal-training__period-overview__header__indicators-wrapper">

                  <div className="class-board__personal-training__period-overview__header__end-date">

                    <p className="class-board__personal-training__period-overview__header__end-date__label">DATA LIMITE</p>

                    <p className="class-board__personal-training__period-overview__header__end-date__text">{getAsLocalDate(personal_training.final_date).toLocaleDateString()}</p>

                  </div>

                  <div className="class-board__personal-training__progress-indicator">

                    <p className="class-board__personal-training__progress-indicator__text">

                      {totalProgress.toFixed(0)}%

                    </p>

                  </div>

                </div>
              }

            </header>

            <div className="class-board__personal-training__period-overview__content">

              {(trainingPeriod !== null && trainingPeriod.note !== null) &&
                <section
                  className="class-board__personal-training__student-note"
                >

                  <header
                    className="class-board__personal-training__student-note__header"
                    onClick={() => this.setState({gymPeriodNoteVisible: !this.state.gymPeriodNoteVisible})}
                  >

                    <h3 className="class-board__personal-training__student-note__header__text">
                      <i className="fas fa-info-circle class-board__personal-training__student-note__header__text-icon"></i>
                      Nota aos alunos
                    </h3>

                    {this.state.gymPeriodNoteVisible ?
                      <i className="fas fa-chevron-down class-board__personal-training__student-note__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up class-board__personal-training__student-note__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container class-board__personal-training__student-note__content"
                    pose={this.state.gymPeriodNoteVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container class-board__personal-training__student-note__content-wrapper">

                      <p className="class-board__personal-training__student-note__text">

                        {trainingPeriod.note}

                      </p>

                    </div>

                  </VerticalAccordionContainer>

                </section>
              }

              {trainingOptions}

            </div>

          </section>
        );
      }
      else if(this.state.gymExperimentalSelected !== null && this.state.experimentalPeriods.length > 0) {
        let trainingOptions;
        let selectorTitle;

        if(this.state.trainingPeriodSelected === null) {
          selectorTitle = 'Selecione uma periodização';

          trainingOptions = this.state.experimentalPeriods.map((period) => (
            <div
              className="class-board__personal-training__training-option"
              key={`experimental_period:${period.id}`}
            >

              <div className="class-board__personal-training__training-option__label">

                <p className="class-board__personal-training__training-option__label__text">{period.name}</p>

              </div>

              <div className="class-board__personal-training__training-option__text-wrapper">

                {period.description !== null &&
                  <p className="class-board__personal-training__training-option__text">{period.description}</p>
                }

              </div>

              <div className="class-board__personal-training__training-option__action-container">

                <DefaultMenuButton
                  className="class-board__personal-training__training-option__action"
                  onClick={() => this.onSelectGymPeriod(period)}
                  text="Selecionar"
                  color="purple"
                />

              </div>

            </div>
          ));
        }
        else if(this.state.trainingPeriodSelected.training_days.length <= 0) {
          selectorTitle = this.state.trainingPeriodSelected.name;
          trainingOptions = 'Periodização não configurada';
        }
        else {
          selectorTitle = this.state.trainingPeriodSelected.name;

          trainingOptions = this.state.trainingPeriodSelected.training_days.map((training) => (
            <div
              className="class-board__personal-training__training-option"
              key={`experimental_period:training_day:${training.id}`}
            >

              <div className="class-board__personal-training__training-option__label">

                <p className="class-board__personal-training__training-option__label__text">{training.name}</p>

              </div>

              <div className="class-board__personal-training__training-option__text-wrapper">

                {training.note !== null &&
                  <p className="class-board__personal-training__training-option__text"> <span className="class-board__personal-training__training-option__note-label">OBS:</span> {training.note}</p>
                }

              </div>

              {!training.is_placeholder &&
                <div className="class-board__personal-training__training-option__action-container">

                  <DefaultMenuButton
                    className="class-board__personal-training__training-option__action"
                    onClick={() => this.onSelectGymTraining(training)}
                    text="Iniciar"
                    color="green"
                  />

                </div>
              }

            </div>
          ));
        }

        return (
          <section className="class-board__personal-training__period-overview">

            <header className="class-board__personal-training__period-overview__header">

              <div className="class-board__personal-training__period-overview__header__title-wrapper">

                <h3 className="class-board__personal-training__period-overview__header__title">{selectorTitle}</h3>
                <h2 className="class-board__personal-training__period-overview__header__sub-title">{this.state.gymExperimentalSelected.name}</h2>

              </div>

            </header>

            <div className="class-board__personal-training__period-overview__content">

              {(this.state.trainingPeriodSelected !== null && this.state.trainingPeriodSelected.note !== null) &&
                <section
                  className="class-board__personal-training__student-note"
                >

                  <header
                    className="class-board__personal-training__student-note__header"
                    onClick={() => this.setState({gymPeriodNoteVisible: !this.state.gymPeriodNoteVisible})}
                  >

                    <h3 className="class-board__personal-training__student-note__header__text">
                      <i className="fas fa-info-circle class-board__personal-training__student-note__header__text-icon"></i>
                      Nota aos alunos
                    </h3>

                    {this.state.gymPeriodNoteVisible ?
                      <i className="fas fa-chevron-down class-board__personal-training__student-note__header__visible-icon"></i>:
                      <i className="fas fa-chevron-up class-board__personal-training__student-note__header__visible-icon"></i>
                    }

                  </header>

                  <VerticalAccordionContainer
                    className="vertical-accordion-container class-board__personal-training__student-note__content"
                    pose={this.state.gymPeriodNoteVisible ? 'verticalOpen' : 'verticalClosed'}>

                    <div className="vertical-accordion-container class-board__personal-training__student-note__content-wrapper">

                      <p className="class-board__personal-training__student-note__text">

                        {this.state.trainingPeriodSelected.note}

                      </p>

                    </div>

                  </VerticalAccordionContainer>

                </section>
              }

              {trainingOptions}

            </div>

          </section>
        );
      }

      return null;
    }

    return (
      <React.Fragment>

        {(this.state.screenWidth > 510 && this.state.trainingDaySelected !== null && this.state.trainingDaySelected.access_qr_code) &&
          <div
            className={`class-board__access-qr-code${this.state.qrCodeExpanded ? '--expanded' : ''}`}
            onClick={() => this.setState({qrCodeExpanded: !this.state.qrCodeExpanded})}
          >

            <img
              className="class-board__access-qr-code__img"
              src={`data:image/svg+xml;base64,${this.state.trainingDaySelected.access_qr_code}`}
              alt="QR Code para acesso ao treino"
            />

          </div>
        }

        <nav className="class-board__overview-navigation">

          <div className="class-board__overview-navigation__buttons-wrapper">

            {this.getOverviewNavButtons()}

          </div>

        </nav>

        {this.getTrainingGroup(this.state.overviewSelectedGroup, (this.state.overviewSelectedGroup && this.state.overviewSelectedGroup.group_is_phase_constant) ? 1 : this.state.training.repetition_index)}

      </React.Fragment>
    );
  }

  onFinishGymClass() {
    const exclude_ids = this.state.students.map((entry) => entry.id);
    const checked_students = [...this.state.students, ...this.state.early_checkins.filter((entry) => !exclude_ids.includes(entry.id))];

    if(checked_students.every((student) => this.state.gymStudentNavigationMap.has(student.id) && this.state.gymStudentNavigationMap.get(student.id).trainingDaySelected !== null)) {
      this.setState({
        onFinishClass: true
      });
    }
    else {
      this.setState({
        showGenericWarning: 'Todos os alunos precisam ter um treino selecionado para finalizar a aula.',
        studentListVisible: true
      });
    }
  }

  getHashedClassTimes() {
    const now = new Date();

    now.setHours(now.getHours() - 1);

    const timeFormat = new Intl.DateTimeFormat('pt-BR', {hour: '2-digit', minute: '2-digit'});

    const minTime = timeFormat.format(now);

    now.setHours(now.getHours() + 2);

    const maxTime = timeFormat.format(now);

    let classTimes = [];

    if (this.state.trainingDaySelected !== null) {
      classTimes = this.state.training_times.filter((entry) => entry.target_service === this.state.trainingDaySelected.target_service && entry.time >= minTime && entry.time <= maxTime).map((entry) => (
        <DefaultMenuButton
          className="class-board__class-time-button"
          key={`training_time_button:${entry.id}`}
          onClick={() => this.setState({
            hashedClassTime: entry.time
          })}
          text={entry.time}
          color={this.state.hashedClassTime === entry.time ? 'red' : 'black'}
        />
      ));
    }

    classTimes.push(
      <DefaultMenuButton
        className="class-board__class-time-button"
        key="training_time_button:no_time"
        onClick={() => this.setState({
          hashedClassTime: false
        })}
        text="Não vinculado"
        color={this.state.hashedClassTime === false ? 'red' : 'black'}
      />
    );

    return (
      <div className="class-board__class-time-container">

        {classTimes}

      </div>
    );
  }

  onSelectExerciseLink(exercise) {
    this.setState({selectedExercise: exercise});
  }

  parseVideoUrl(url) {
    const info = {mayEmbed: false};

    let suffix = 'youtube.com/watch?v=';

    let suffixPosition = url.indexOf(suffix);

    if(suffixPosition >= 0) {
      let videoId = url.slice(suffixPosition + suffix.length);
      const endPosition = videoId.indexOf('&');

      if(endPosition >= 0) {
        videoId = videoId.slice(0, endPosition);
      }

      info.mayEmbed = true;
      info.embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
    else {
      suffix = 'youtu.be/';
      suffixPosition = url.indexOf(suffix);

      if(suffixPosition >= 0) {
        info.mayEmbed = true;
        info.embedUrl = `https://www.youtube.com/embed/${url.slice(suffixPosition + suffix.length)}`;
      }
    }

    return info;
  }

  getExerciseOverlayTitle() {
    if(this.state.selectedExercise !== null) {
      return this.state.selectedExercise.name;
    }

    return 'Não selecionado';
  }

  getExerciseOverlayVideo() {
    if(this.state.selectedExercise !== null && this.state.exercisevideoContainerSize !== null) {
      const urlInfo = this.parseVideoUrl(this.state.selectedExercise.reference_url);

      return (
        <iframe
          key={`exercise_video:${this.state.selectedExercise.id}`} //:size:${this.state.exercisevideoContainerSize[0]}x${this.state.exercisevideoContainerSize[1]}
          className="class-board__description-video"
          title={`Descrição do exercício ${this.state.selectedExercise.name}`}
          width={this.state.exercisevideoContainerSize[0]}
          height={this.state.exercisevideoContainerSize[1]}
          src={urlInfo.embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }

    return null;
  }

  parseHeartRate(value) {
    value = value.buffer ? value : new DataView(value);

    let flags = value.getUint8(0);
    let rate16Bits = flags & 0x1;
    let index = 1;

    let heartRate = null;

    if (rate16Bits) {
      heartRate = value.getUint16(index, /*littleEndian=*/true);
      index += 2;
    } else {
      heartRate = value.getUint8(index);
      index += 1;
    }

    return heartRate;
  }

  getHrViews() {
    const views = [];

    let count = 1;

    // for(let i=0;i<1;++i) {
    //   views.push(<article
    //     className="class-board__hr-view__view"
    //     key={`hr_view:user:${i}`}
    //     style={{order: count}}
    //   >
    //
    //     <h4 className={`class-board__hr-view__view__student-name--d`}>
    //
    //       <div>
    //
    //         <span className="class-board__hr-view__view__student-name__first-letter">
    //           T
    //         </span>
    //         emp name
    //
    //       </div>
    //
    //     </h4>
    //
    //     <p className={`class-board__hr-view__view__percentage-text--3`}>50%</p>
    //
    //     <div className="class-board__hr-view__view__row-container">
    //
    //       <div className={`class-board__hr-view__view__hr-container--3`}>
    //
    //         <p className="class-board__hr-view__view__hr-text">90</p>
    //         <i className="fas fa-heart class-board__hr-view__view__hr-icon"></i>
    //
    //       </div>
    //
    //       <p className="class-board__hr-view__view__energy">{`${50} kcal`}</p>
    //
    //     </div>
    //
    //
    //   </article>);
    //
    //   count += 1;
    //
    //   if(count === 3) {
    //     count += 1;
    //   }
    // }

    for(const entry of this.state.hrDeviceMap.values()) {
      const studentHrEntry = this.state.studentHrMap.get(entry.id);
      const data = this.state.studentDataMap.get(entry.id);

      let hrZone = '';
      let percentage = null;
      let percentageText = '-';

      if(entry.max_hr && studentHrEntry.connected) {
        hrZone = `--${this.getHrZone(studentHrEntry.hr, entry.max_hr)}`;
        percentage = 100 * studentHrEntry.hr / entry.max_hr;
        percentageText = `${percentage.toFixed(0)}%`;
      }

      const hasRange = this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.hr_range;

      const firstNameLetter = entry.name[0];
      let remainingName = entry.name.slice(1).split(' ');
      remainingName.splice(1, remainingName.length - 2);
      remainingName = `${remainingName[0]} ${remainingName[1][0]}`;

      views.push(
        <article
          className={`class-board__hr-view__view${hrZone}`}
          key={`hr_view:user:${entry.id}`}
          style={{order: count}}
        >

          <h4 className={`class-board__hr-view__view__student-name--${this.getStudentDisc(entry)}`}>

            <div>

              <span className="class-board__hr-view__view__student-name__first-letter">
                {firstNameLetter}
              </span>
              {remainingName}

            </div>

          </h4>

          <div className="class-board__hr-view__view__main-container">

            {/* {hasRange &&
              <div className="class-board__hr-view__view__hr-range">

                <p className="class-board__hr-view__view__hr-range__max">{`${this.state.trainingGroupSelected.hr_range[1].toFixed(0)}%`}</p>

                <p className="class-board__hr-view__view__hr-range__min">{`${this.state.trainingGroupSelected.hr_range[0].toFixed(0)}%`}</p>

              </div>
            } */}

            <p className={`class-board__hr-view__view__percentage-text${hrZone}`}>{percentageText}</p>

            {(hasRange && percentage !== null) &&
              <div className="class-board__hr-view__view__range-indicator">

                {percentage < this.state.trainingGroupSelected.hr_range[0] ? (
                  <i className="fas fa-arrow-alt-circle-up class-board__hr-view__view__range-indicator__text--up"></i>
                ): percentage <= this.state.trainingGroupSelected.hr_range[1] ? (
                  <i className="fas fa-check-circle class-board__hr-view__view__range-indicator__text--ok"></i>
                ): (
                  <i className="fas fa-arrow-alt-circle-down class-board__hr-view__view__range-indicator__text--down"></i>
                )}

              </div>
            }

          </div>

          <div className="class-board__hr-view__view__row-container">

            <div className={`class-board__hr-view__view__hr-container${hrZone}`}>

              <p className="class-board__hr-view__view__hr-text">{studentHrEntry.hr || '-'}</p>
              <i className="fas fa-heart class-board__hr-view__view__hr-icon"></i>

            </div>

            <p className="class-board__hr-view__view__energy">{`${data.energy_expended.toFixed(0)} kcal`}</p>

          </div>

          <div className="class-board__hr-view__view__picture-container">

            {entry.avatar_image_url ? (
              <img className="class-board__hr-view__view__picture" src={entry.avatar_image_url} alt={`Foto de ${entry.name}`} />
            ) : (
              <i className="fas fa-user class-board__hr-view__view__picture-icon"></i>
            )}

          </div>

        </article>
      );

      count += 1;

      if(this.state.screenWidth > 860) {
        if(count === 1) {
          count += 1;
        }
        else if(count === 2) {
          count += 1;
        }
      }
    }

    return views;
  }

  toggleStudentListOrderPriority(orderPriority) {
    if (this.state.studentListOrderPriority !== orderPriority) {
      this.setState({studentListOrderPriority: orderPriority})
    }
    else {
      this.setState({studentListOrderPriority: null})
    }
  }

  async onUpdateSelectedCoaches() {
    if(!this.mayUpdateSelectedCoaches()) {
      return;
    }

    this.setState({
      loading: true
    });

    const data = {coach_ids: this.state.newSelectedCoaches.map((entry) => entry.id)};

    try{
      await postModel(routes.CURRENT_TRAINING_CLASS_UPDATE_COACHES_POST_API.replace('{service}', this.props.target_service), data);

      this.setState({
        loading: false,
        selectedCoaches: [...this.state.newSelectedCoaches]
      });
    }
    catch(errors) {
      let errorDescription = "Falha durante comunicação com o sistema. Alguns serviços podem não estar funcionando corretamente.";

      window.alert(errorDescription);

      // if(errors instanceof Array) {
      //   for(let error of errors) {
      //     switch (error.code) {
      //       case 104:
      //         for(let parameter of error.parameters) {
      //           switch (parameter.name) {
      //             case 'contracts':
      //               errorDescription = 'Serviço vinculado à um contrato de aluno. Estes contratos devem ser excluídos antes de excluir este serviço.';
      //
      //               break;
      //             default:
      //           }
      //         }
      //
      //         break;
      //       default:
      //     }
      //   }
      // }

      this.setState({
        loading: false
      });
    }
  }

  async onOpenCoachSelector() {
    const update = {};

    if (this.state.coaches.length <= 0) {
      this.setState({loading: true});

      update.coaches = await this.getCoaches();

      update.loading = false;
    }

    update.showCoachSelector = true;
    update.newSelectedCoaches = [...this.state.selectedCoaches];

    this.setState(update);
  }

  onAddCoach(coach) {
    const newSelectedCoaches = [...this.state.newSelectedCoaches];
    newSelectedCoaches.push(coach);

    this.setState({
      newSelectedCoaches
    });
  }

  onRemoveSelectedCoach(coachId) {
    const newSelectedCoaches = this.state.newSelectedCoaches.filter((entry) => entry.id !== coachId);

    this.setState({
      newSelectedCoaches
    });
  }

  getCoachOptions() {
    const selectedIds = this.state.newSelectedCoaches.map((entry) => entry.id);
    const filteredCoaches = this.state.coaches.filter((coach) => coach.name.toLocaleLowerCase().includes(this.state.coachNameFilter.toLocaleLowerCase()));

    if(filteredCoaches.length <= 0) {
      return (
        <p className="class-board__coach-selector__not-found-text">Nenhum professor encontrado que contenha este nome</p>
      );
    }

    return filteredCoaches.map((coach) => (
      <UserItem
        key={`coach:${coach.id}`}
        name={coach.name}
      >

        {selectedIds.includes(coach.id) ? (
          <DefaultMenuButton
            className="class-board__coach-selector__user-action-button"
            text="Selecionado"
            color="blue"
            disabled={true}
          />
        ) : (
          <DefaultMenuButton
            className="class-board__coach-selector__user-action-button"
            onClick={() => this.onAddCoach(coach)}
            text="Selecionar"
            color="blue"
          />
        )}

      </UserItem>
    ));
  }

  getSelectedCoaches() {
    return this.state.newSelectedCoaches.map((entry) => {
      return (
        <div
          key={`clasboard:coach_selector:selected:${entry.id}`}
          className="class-board__coach-selector__selected-coach"
        >
          <i className="fas fa-circle class-board__coach-selector__selected-coach__bullet-icon"></i>

          <p className="class-board__coach-selector__selected-coach__name">{entry.name}</p>

          {this.state.newSelectedCoaches.length > 1 &&
            <DefaultMenuButton
              className="class-board__coach-selector__user-action-button"
              text="Remover"
              color="red"
              onClick={() => this.onRemoveSelectedCoach(entry.id)}
            />
          }

        </div>
      );
    });
  }

  mayUpdateSelectedCoaches() {
    return this.state.newSelectedCoaches.length !== this.state.selectedCoaches.length ||
           this.state.newSelectedCoaches.some((coach) => !this.state.selectedCoaches.some((entry) => entry.id === coach.id));
  }

  mayShowUnifiedGroupSelection() {
    if (this.state.trainingGroupSelected !== null && !this.props.isGymService) {
      const nextGroupIndex = this.state.trainingGroupSelected.order;

      return nextGroupIndex < this.state.trainingDaySelected.group_associations.length && this.state.trainingDaySelected.group_associations[nextGroupIndex].merge_with_previous_group;
    }

    return false;
  }

  getCurrentGroupSelection() {
    const groupSelection = [];

    if (this.state.trainingGroupSelected !== null) {
      groupSelection.push(this.state.trainingGroupSelected);

      if (!this.props.isGymService) {
        const nextGroupIndex = this.state.trainingGroupSelected.order;

        for (let i=nextGroupIndex; i < this.state.trainingDaySelected.group_associations.length; ++i) {
          const group = this.state.trainingDaySelected.group_associations[i];

          if (!group.merge_with_previous_group) {
            break;
          }

          groupSelection.push(group);
        }
      }
    }

    return groupSelection;
  }

  getUnifiedViewClockState() {
    const groupSelection = this.getCurrentGroupSelection();

    if (groupSelection.length > 0) {
      const filteredGroups = groupSelection.filter((entry) => entry.has_clock);

      if (filteredGroups.some((group) => {
        const clockParameters = this.state.clockParametersMap.get(group.id);

        return clockParameters.clockState === CLOCK_STATE_PLAYING || (clockParameters.clock_method === CLOCK_METHOD_STOPWATCH && clockParameters.clockState === CLOCK_STATE_FINISHED);
      })) {
        return CLOCK_STATE_PLAYING;
      }
      else if (filteredGroups.every((group) => this.state.clockParametersMap.get(group.id).clockState === CLOCK_STATE_FINISHED)) {
        return CLOCK_STATE_FINISHED;
      }
      else if (filteredGroups.every((group) => this.state.clockParametersMap.get(group.id).clockState === CLOCK_STATE_PAUSED)) {
        return CLOCK_STATE_PAUSED;
      }

      return CLOCK_STATE_STOPPED;
    }

    return null;
  }

  getUnifiedViewClockControls() {
    const state = this.getUnifiedViewClockState();

    return (
      <TrainingClock
        className="class-board__unified-group-view__clock-control"
        showControlButtons={true}
        showResetButton={state !== CLOCK_STATE_STOPPED && state !== CLOCK_STATE_PLAYING}
        showPauseButton={state === CLOCK_STATE_PLAYING}
        showPlayButton={state !== CLOCK_STATE_PLAYING && state !== CLOCK_STATE_FINISHED}
        onResetClock={() => this.resetUnifiedViewClocks()}
        onPauseClock={() => this.pauseUnifiedViewClocks()}
        onPlayClock={() => this.playUnifiedViewClocks()}
      />
    );
  }

  playUnifiedViewClocks() {
    const groupSelection = this.getCurrentGroupSelection();

    groupSelection.forEach((entry) => {
      const group = entry;

      if (group.has_clock) {
        this.playClock(group);
      }
    });
  }

  pauseUnifiedViewClocks() {
    const groupSelection = this.getCurrentGroupSelection();

    for (const group of groupSelection) {
      if (group.has_clock) {
        this.pauseClock(group);
      }
    }
  }

  resetUnifiedViewClocks() {
    const groupSelection = this.getCurrentGroupSelection();

    for (const group of groupSelection) {
      if (group.has_clock) {
        this.resetClock(group);
      }
    }
  }

  getHrViewClocks() {
    if (!this.props.isGymService && this.mayShowUnifiedGroupSelection()) {
      const groupSelection = this.getCurrentGroupSelection();

      const filteredSelection = groupSelection.filter((entry) => entry.has_clock);

      const maxWidth = this.state.screenWidth / filteredSelection.length;

      const groupClocks = filteredSelection.map((group) => {
        // const clockParameters = this.state.clockParametersMap.get(group.id);

        return (
          <div
            key={`hr_view:training_clock:${group.id}`}
            className="class-board__hr-view class-board__unified-group-view__clock-container"
            onClick={() => this.setState({overlayTrainingGroupSelected: group})}
          >

            <h3
              className="class-board__hr-view class-board__unified-group-view__clock__title"
              style={{maxWidth: `${maxWidth}px`}}
            >
              {parseTextForIcons(group.name, `hr_view:training_clock:${group.id}:name`, 'class-board__parsed-text')}
            </h3>

            <TrainingClock
              className="class-board__clock class-board__hr-view__unified-view-clock"
              showControlButtons={false}
              showCompressButton={false}
              clockLabel={this.getClockCountLabel(group)}
              clockLabelStyle={this.getClockStyle(group)}
              clockTimeStyle={this.getClockStyle(group)}
              clockTime={this.getClockTime(group)}
              timeTextAlignment="center"
              color={this.getClockSpecifier(group)}
            />

          </div>
        );
      });

      return groupClocks;
    }

    const clockParameters = this.state.clockParametersMap.get(this.state.trainingGroupSelected.id);

    return (
      <TrainingClock
        className="class-board__clock class-board__hr-view__clock"
        showControlButtons={false}
        showCompressButton={false}
        clockLabel={this.getClockCountLabel(this.state.trainingGroupSelected)}
        clockLabelStyle={this.getClockStyle(this.state.trainingGroupSelected)}
        clockTimeStyle={this.getClockStyle(this.state.trainingGroupSelected)}
        clockTime={this.getClockTime(this.state.trainingGroupSelected)}
        onClick={() => this.setState({overlayTrainingGroupSelected: this.state.trainingGroupSelected})}
        timeTextAlignment={(clockParameters.clockPhase === CLOCK_PHASE_PREPARING || clockParameters.clockPhase === CLOCK_PHASE_RESTING) ? 'center' : null}
        color={this.getClockSpecifier(this.state.trainingGroupSelected)}
      />
    );
  }

  getHrRange() {
    const hasRange = this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.hr_range;

    if (!hasRange) {
      return null;
    }

    const maxCoverHeight = (100 - this.state.trainingGroupSelected.hr_range[1]);

    return (
      <div className="class-board__hr-view__hr-range">

        <div
          className="class-board__hr-view__hr-range__max"
          style={{height: `${maxCoverHeight}%`}}
        >

          <p className="class-board__hr-view__hr-range__text">{`${this.state.trainingGroupSelected.hr_range[1].toFixed(0)}%`}</p>

        </div>

        <div
          className="class-board__hr-view__hr-range__min"
          style={{height: `${this.state.trainingGroupSelected.hr_range[0]}%`}}
        >

          <p className="class-board__hr-view__hr-range__text">{`${this.state.trainingGroupSelected.hr_range[0].toFixed(0)}%`}</p>

        </div>

      </div>
    );
  }

  updateUnifiedClockSizes() {
    if (!this.state.showHrView) {
      return;
    }

    const clockContainers = this.unifiedHrClockContainerElement.getElementsByClassName('class-board__unified-group-view__clock-container');

    if (clockContainers.length > 0) {
      let totalWidth = 0;

      for(const element of clockContainers) {
        totalWidth += element.getBoundingClientRect().width;
      }

      if (totalWidth > 0) {
        const hrClockContainerSizeMultiplier = Math.min(1, this.state.hrClockContainerSizeMultiplier * (this.state.screenWidth / totalWidth));

        this.setState({hrClockContainerSizeMultiplier});
      }
    }

    setTimeout(() => this.updateUnifiedClockSizes(), 1000);
  }

  getCurrentClockTime() {
    let clockStyle = '';

    if (typeof this.state.training.class_time !== 'undefined' && this.state.training.class_time !== null && this.state.training.class_time.length > 0) {
      let parsedClassTime = this.state.training.class_time.split(':');
      let parsedCurrentTime = this.state.currentDateTimeText.split(':');

      const classTimeMinutes = parseInt(parsedClassTime[0])*60 + parseInt(parsedClassTime[1]);
      const currentTimeMinutes = parseInt(parsedCurrentTime[0])*60 + parseInt(parsedCurrentTime[1]);

      if (currentTimeMinutes >= classTimeMinutes && currentTimeMinutes <= (classTimeMinutes + this.state.training.duration)) {
        clockStyle = '--green';
      }
      else {
        clockStyle = '--yellow';
      }
    }

    return (
      <p className={`class-board__students-panel__current-time${clockStyle}`}>{this.state.currentDateTimeText}</p>
    );
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <div className="class-board">

          <header className="class-board__header">

            <div className="class-board__header__logo-wrapper">

              <img className="class-board__header__logo" src={logo} alt="Logo da FYD" />

            </div>

            <div className="class-board__header__main-container">

              <div className="class-board__header__buttons-container">

                {!this.props.accessedByHash &&
                  <DefaultMenuButton
                    className="class-board__header__control-button"
                    onClick={() => this.setState({onBackClicked: true})}
                    text={(
                      <React.Fragment>

                        <i className="fas fa-angle-left default-header__back-button__icon"></i>
                        {this.state.screenWidth > 420 &&
                          <span>{this.state.classIsCompleted ? 'Fechar' : 'Voltar'}</span>
                        }

                      </React.Fragment>
                    )}
                  />
                }

                {(!this.state.classIsCompleted && !this.props.accessedByHash) &&
                  <DefaultMenuButton
                    className="class-board__header__control-button"
                    onClick={() => this.setState({onAbortClicked: true})}
                    text="Abortar"
                    color="red"
                  />
                }

                {(!this.state.classIsCompleted && this.props.isGymService) &&
                  <DefaultMenuButton
                    className="class-board__header__control-button"
                    onClick={() => this.onFinishGymClass()}
                    text={this.state.screenWidth <= 500 ? 'Finalizar' : 'Finalizar aula'}
                    color="green"
                  />
                }

                {(this.state.studentHrMap.size > 0) &&
                  <DefaultMenuButton
                    className="class-board__header__control-button"
                    onClick={() => this.setState({showHrView: true})}
                    text={<i className="fas fa-heartbeat"></i>}
                    color="purple"
                  />
                }

                {(!this.state.classIsCompleted && !this.props.basicAccess && !this.props.accessedByHash) &&
                  <DefaultMenuButton
                    className="class-board__header__control-button"
                    onClick={() => this.onOpenCoachSelector()}
                    text={<i className="fas fa-chalkboard-teacher"></i>}
                    color="blue"
                  />
                }

              </div>

              <div className="class-board__header__title-wrapper">

                {this.mayShowUnifiedGroupSelection() ? (
                  this.getUnifiedViewClockControls()
                ) : (
                  <h1 className="class-board__header__title">

                    {this.getHeaderTitle()}

                  </h1>
                )}

              </div>

            </div>

          </header>

          <main
            ref={this.mainRef}
            className="class-board__main"
          >

            {this.getMainContent()}

          </main>

          {this.getMainNavigation()}

          {!this.props.basicAccess &&
            <aside className={`class-board__students-panel${this.state.studentListVisible ? '--visible' : ''}`}>

              {(!this.props.isGymService || this.state.gymStudentSelected !== null || this.state.gymExperimentalSelected !== null) &&
                <div className="class-board__students-panel__controls">

                  <DefaultMenuButton
                    className="class-board__students-panel__toggle-button"
                    onClick={() => {
                      if (this.state.studentListVisible) {
                        this.studentBirthdayHatSelection.clear();
                      }

                      this.setState({studentListVisible: !this.state.studentListVisible});
                    }}
                    text={(<i className="fas fa-chevron-down"></i>)}
                    color="red"
                  />

                </div>
              }

              <header className="class-board__students-panel__header">

                <div className="class-board__students-panel__header__title-wrapper">

                  <h2 className="class-board__students-panel__header__title">

                    Alunos

                  </h2>

                </div>

                {!this.state.classIsCompleted &&
                  <React.Fragment>

                    <DefaultMenuButton
                      className="class-board__students-panel__header__update-button"
                      onClick={() => this.reloadStudents()}
                      text={(<i className="fas fa-sync-alt"></i>)}
                    />

                    <DefaultMenuButton
                      className="class-board__students-panel__header__add-button"
                      linkTo={this.props.accessedByHash ? `${routes.HASHED_CLASS_STUDENT_CHECKIN_PATH}${this.props.match.params.authHash}` : routes.STUDENT_CHECKIN_PATH}
                      target="_blank"
                      rel="noopener noreferrer"
                      text={(<i className="fas fa-plus"></i>)}
                      color="green"
                    />

                  </React.Fragment>
                }

              </header>

              <div className="class-board__students-panel__line-wrapper">

                {this.state.screenWidth >= 600 &&
                  this.getCurrentClockTime()
                }

                <div className="class-board__students-panel__order-control">

                  <p className="class-board__students-panel__order-control__label">Ordem:</p>

                  <div className="class-board__students-panel__order-control__buttons-group">
                    <DefaultMenuButton
                      className="class-board__students-panel__order-control__button"
                      onClick={() => this.toggleStudentListOrderPriority(STUDENT_LIST_ORDER_PRIORITY.checkin)}
                      text="Checkin"
                      color={this.state.studentListOrderPriority === STUDENT_LIST_ORDER_PRIORITY.checkin ? 'green' : null}
                    />
                    <DefaultMenuButton
                      className="class-board__students-panel__order-control__button"
                      onClick={() => this.toggleStudentListOrderPriority(STUDENT_LIST_ORDER_PRIORITY.rac)}
                      text="RAC"
                      color={this.state.studentListOrderPriority === STUDENT_LIST_ORDER_PRIORITY.rac ? 'red' : null}
                    />
                    <DefaultMenuButton
                      className="class-board__students-panel__order-control__button"
                      onClick={() => this.toggleStudentListOrderPriority(STUDENT_LIST_ORDER_PRIORITY.disc)}
                      text="DISC"
                      color={this.state.studentListOrderPriority === STUDENT_LIST_ORDER_PRIORITY.disc ? 'purple' : null}
                    />
                  </div>

                </div>

              </div>

              <div className="class-board__students-panel__list">

                {this.getCheckedInStudents()}

              </div>

            </aside>
          }

        </div>

        <OverlayWindow
          className="class-board__exercise-video-overlay"
          visible={this.state.selectedExercise !== null}
          actions={(
            <div className="class-board__exercise-video-overlay__action-container">

              <DefaultMenuButton
                className="class-board__exercise-video-overlay__action-button"
                onClick={() => this.setState({selectedExercise: null})}
                text="Fechar"
                color="black"
              />

            </div>
          )}
        >

          <header className="class-board__exercise-video-overlay__header">

            <h3 className="class-board__exercise-video-overlay__header__title">
              {this.getExerciseOverlayTitle()}
            </h3>

          </header>

          <hr className="class-board__horizontal-rule" />

          <div
            ref={this.exerciseOverlayContainerRef}
            className="class-board__exercise-video-overlay__reference-container"
          >

            {this.getExerciseOverlayVideo()}

          </div>

        </OverlayWindow>

        {(this.props.accessedByHash && !this.props.basicAccess && this.state.hashedClassTimeSelected === false) &&
          <OverlayWindow
            className="class-board__overlay"
            visible={true}
            actions={(
              <div className="class-board__overlay__action-container">

                <DefaultMenuButton
                  className="class-board__overlay__action-button"
                  onClick={() => {
                    this.setState({hashedClassTimeSelected: true});
                    this.reloadStudents();
                  }}
                  text="Confirmar"
                  disabled={this.state.hashedClassTime === null}
                  color="green"
                />

              </div>
            )}
          >

            <header className="class-board__overlay__header">

              <h3 className="class-board__overlay__header__title">
                Selecione o horário da aula
              </h3>

            </header>

            <hr className="class-board__horizontal-rule" />

            <div className="class-board__overlay__reference-container">

              {this.getHashedClassTimes()}

            </div>

          </OverlayWindow>
        }

        <OverlayWindow
          className="class-board__overlay"
          visible={this.state.studentOverlaySelected !== null}
          loading={this.state.studentOverlayLoading}
          actions={(
            <div className="class-board__overlay__action-container">

              {this.getStudentOverlayActions()}

            </div>
          )}
        >

          <header className="class-board__overlay__header">

            <h3 className="class-board__overlay__header__title">
              {this.state.studentOverlayShowInputs ? 'Definição de PR' : 'Sugestões de peso'}
            </h3>

            <DefaultMenuButton
              className="class-board__overlay__header__toggle-button"
              onClick={() => this.setState({
                studentOverlayShowInputs: !this.state.studentOverlayShowInputs
              })}
              text={(<i className="fas fa-exchange-alt"></i>)}
            />

          </header>

          <hr className="class-board__horizontal-rule" />

          <div className="class-board__overlay__reference-container">

            <p className="class-board__overlay__generic-note">

              <strong className="class-board__overlay__generic-note__label">OBS:</strong>
              Lembre-se de incluir o peso da barra.

            </p>

            {this.getStudentOverlayData()}

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="class-board__overlay"
          visible={this.state.hrSyncStudentSelected !== null}
          loading={this.state.hrSyncInProgress}
          actions={(
            <div className="class-board__overlay__action-container">

              <DefaultMenuButton
                className="class-board__overlay__action-button"
                onClick={() => this.setState({
                  hrSyncStudentSelected: null,
                  hrSyncInProgress: false,
                  showDeviceErrorMessage: false,
                })}
                text="Cancelar"
                disabled={this.state.hrSyncInProgress}
              />

            </div>
          )}
        >

          <header className="class-board__overlay__header">

            <h3 className="class-board__overlay__header__title">
              Selecione o dispositivo
            </h3>

          </header>

          <hr className="class-board__horizontal-rule" />

          <WarningMessage
            message={this.state.deviceErrorMessage}
            onClose={() => {this.setState({showDeviceErrorMessage: false})}}
            visible={this.state.showDeviceErrorMessage}
          />

          <div className="class-board__overlay__reference-container">

            {this.getHrSyncDeviceOptions()}

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="class-board__hr-view"
          visible={this.state.showHrView}
        >

          <header className="class-board__hr-view__header">

            <DefaultMenuButton
              className="class-board__header__control-button"
              onClick={() => this.setState({showHrView: false})}
              text={<i className="fas fa-angle-left default-header__back-button__icon"></i>}
            />

            <div className="class-board__hr-view__header__wrapper">

              <h3 className="class-board__hr-view__header__title">Dispositivos ativos</h3>

              {this.getUnifiedViewClockControls()}

            </div>


          </header>

          <div className="class-board__hr-view__main">

            {(this.state.trainingGroupSelected !== null && this.state.trainingGroupSelected.has_clock) &&
              <div
                ref={this.unifiedHrClockContainerRef}
                style={{fontSize: `${this.state.hrClockContainerSizeMultiplier}em`}}
                className="class-board__hr-view__clock-section"
              >

                {this.getHrViewClocks()}

                {/* <div className="class-board__clock class-board__hr-view__clock">

                  {(this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_TABATA || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_SERIES || this.state.trainingGroupSelected.clock_method === CLOCK_METHOD_CUSTOM) &&
                    <div className={`class-board__clock__lap-wrapper${this.getClockSpecifier(this.state.trainingGroupSelected)}`}>

                      <p
                        className="class-board__clock__lap--centered"
                        style={this.getClockStyle(this.state.trainingGroupSelected)}
                      >

                        {this.getClockCountLabel(this.state.trainingGroupSelected)}

                      </p>

                    </div>
                  }

                  <div
                    className={`class-board__clock__time-wrapper${this.getClockSpecifier(this.state.trainingGroupSelected)}`}
                  >

                    <p
                      className={`class-board__clock__time${(this.state.clockParametersMap.get(this.state.trainingGroupSelected.id).clockPhase === CLOCK_PHASE_PREPARING || this.state.clockParametersMap.get(this.state.trainingGroupSelected.id).clockPhase === CLOCK_PHASE_RESTING) ? '--centered' : ''}`}
                      style={this.getClockStyle(this.state.trainingGroupSelected)}
                    >

                      {this.getClockTime(this.state.trainingGroupSelected)}

                    </p>

                  </div>

                </div> */}

              </div>
            }

            <div className="class-board__hr-view__sub-main-wrapper">

              <div className="class-board__hr-view__views-wrapper">

                {this.getHrViews()}

              </div>

              {this.getHrRange()}

            </div>

          </div>

        </OverlayWindow>

        <OverlayWindow
          className="class-board__coach-selector"
          visible={this.state.showCoachSelector}
        >

          <header className="class-board__coach-selector__header">

            <DefaultMenuButton
              className="class-board__header__control-button"
              onClick={() => this.setState({showCoachSelector: false})}
              text={<i className="fas fa-angle-left default-header__back-button__icon"></i>}
            />

            <h3 className="class-board__coach-selector__header__title">Professores responsáveis</h3>

          </header>

          <div className="class-board__coach-selector__main">

            <h1 className="class-board__coach-selector__instructions">

            <span className="class-board__coach-selector__instructions--underline">Responsáveis</span><span className="class-board__coach-selector__instructions--highlight">{(typeof this.state.training.class_time !== 'undefined' && this.state.training.class_time !== null && this.state.training.class_time.length > 0) ? ` (${this.state.training.class_time})` : ''}</span>:

            </h1>

            <hr className="class-board__coach-selector__horizontal-rule" />

            {this.getSelectedCoaches()}

            {this.mayUpdateSelectedCoaches() &&
              <DefaultMenuButton
                className="class-board__coach-selector__user-action-button"
                onClick={() => this.onUpdateSelectedCoaches()}
                text="Confirmar alteração"
                color="green"
              />
            }

            <hr className="class-board__coach-selector__horizontal-rule" />

            <h1 className="class-board__coach-selector__instructions">

              {this.state.newSelectedCoaches.length > 0 ? 'Selecione outro professor resposável pela aula:' : 'Selecione o professor resposável pela aula:'}

            </h1>

            <div className="class-board__coach-selector__input-wrapper">

              <DefaultFilterInput
                name="name"
                label={this.state.screenWidth > 510 ? 'Filtrar professor:' : 'Filtrar:'}
                placeholder="Nome do professor"
                autoComplete="off"
                value={this.state.coachNameFilter}
                handleInputChange={(event) => this.setState({coachNameFilter: event.target.value})}
              />

            </div>

            <UserList className="class-board__coach-selector__coach-list">

              {this.getCoachOptions()}

            </UserList>

          </div>


        </OverlayWindow>

        <OverlayWindow
          className="class-board__overlay"
          visible={this.state.overlayTrainingGroupSelected !== null}
          actions={(
            <div className="class-board__overlay__action-container">

              <DefaultMenuButton
                className="class-board__overlay__action-button"
                onClick={() => this.setState({
                  overlayTrainingGroupSelected: null
                })}
                text="Fechar"
              />

            </div>
          )}
        >

          {this.state.overlayTrainingGroupSelected !== null &&
            <React.Fragment>

              <header className="class-board__overlay__header">

                <h3 className="class-board__overlay__header__title">
                  {parseTextForIcons(this.state.overlayTrainingGroupSelected.name, 'overlay:group:name', 'class-board__parsed-text')}
                </h3>

              </header>

              <hr className="class-board__horizontal-rule" />

              <div className="class-board__overlay__reference-container">

                {this.getTrainingGroup(this.state.overlayTrainingGroupSelected, (this.state.overlayTrainingGroupSelected.group_is_phase_constant) ? 1 : this.state.training !== null ? this.state.training.repetition_index : 1)}

              </div>

            </React.Fragment>
          }

        </OverlayWindow>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={(this.state.confirmFailed || this.state.showGenericWarning !== null) ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed || this.state.showGenericWarning !== null}
        />

      </React.Fragment>
    );
  }
}

export default ClassBoard;
