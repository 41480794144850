import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { PoseGroup } from 'react-pose';
import './libs/fontawesome-6.5.1-web/scss/fontawesome.scss';
import './libs/fontawesome-6.5.1-web/scss/solid.scss';
import './libs/fontawesome-6.5.1-web/scss/brands.scss';
import './init.scss';
import './general.scss';
import * as serviceWorker from './serviceWorker';
import Login from './views/login';
import {FadeContainer} from './utils/pose_containers';
import PreLoader from './utils/preloader';
import * as routes from './constants';
import {DEFAULT_ERROR_MESSAGE,
        SERVICE_DEFAULT_TRAINING,
        LOCAL_CACHE_SELECTED_SERVICE_KEY,
        SERVICE_GYM,
        SERVICE_CARDIO,
        SERVICE_BOOTCAMP} from './constants';
import MainMenu from './views/main_menu';
import StudentCheckin from './views/student_checkin';
import StartClass from './views/start_class';
import ClassBoard from './views/class_board/class_board';
import LastClass from './views/last_class';
import StudentReport from './views/student_report';
import DailyReport from './views/daily_report';
import EarlyCheckin from './views/early_checkin';
import SwitchUnit from './views/switch_unit';
import SwitchService from './views/switch_service';
import BluetoothDevices from './utils/bluetooth_devices';
import InitiateDeviceConnection from './views/initiate_device_connection';
import OperationalTasks from './views/operational_tasks';

class App extends React.Component {
  constructor(props) {
    super(props);

    let target_service = SERVICE_DEFAULT_TRAINING;
    let localCache = localStorage.getItem(LOCAL_CACHE_SELECTED_SERVICE_KEY);

    if(localCache) {
      target_service = localCache;
    }

    this.state = {
      pageLoaded: false,
      identified: false,
      requires_login: false,
      device_name: '',
      authorized: false,
      token: null,
      target_service: target_service,
      allServicesEnabled: false
    };

    sessionStorage.clear();

    this.bluetoothDevices = new BluetoothDevices();
  }

  onSwitchService(target_service) {
    if(!target_service) {
      return;
    }

    localStorage.setItem(LOCAL_CACHE_SELECTED_SERVICE_KEY, target_service);

    this.setState({target_service});
  }

  async isAuthenticated() {
    try {
      let response = await fetch(routes.IS_AUTHENTICATED_API, {cache: 'no-store', credentials: 'same-origin'});

      if(!response.ok) {
        throw Error(DEFAULT_ERROR_MESSAGE);
      }

      let data = await response.json();

      return data;
    }
    catch(error) {
      console.log(error);
      return null;
    }
  }

  async componentDidMount() {
    const updates = {};

    let data = this.isAuthenticated();

    data = await data;

    updates.identified = false;
    updates.requires_login = true;
    updates.authorized = false;
    updates.pageLoaded = true;
    updates.device_name = '';

    if(data) {
      updates.device_name = data.device_name || '';
      updates.identified = data.identified;
      updates.requires_login = data.requires_login;
      updates.authorized = data.authorized;

      if(data.token) {
        updates.token = data.token;
      }

      if(data.target_service) {
        updates.target_service = data.target_service;
        updates.allServicesEnabled = false;
      }
      else {
        updates.allServicesEnabled = true;
      }
    }

    this.setState(updates);
  }

  async onAuthenticate(data) {
    const update = {
      device_name: data.device_name || '',
      authorized: data.authorized,
      identified: data.identified,
      requires_login: data.requires_login
    };

    if(data.token) {
      update.token = data.token;
    }

    if(data.target_service) {
      update.target_service = data.target_service;
      update.allServicesEnabled = false;
    }
    else {
      update.allServicesEnabled = true;
    }

    this.setState(update);
  }

  isGymService() {
    return this.state.target_service === SERVICE_GYM;
  }

  isCardioOrFunctionalService() {
    return this.state.target_service === SERVICE_CARDIO ||
           this.state.target_service === SERVICE_DEFAULT_TRAINING ||
           this.state.target_service === SERVICE_BOOTCAMP;
  }

  render() {
    return !this.state.pageLoaded ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <BrowserRouter>

        <React.Fragment>

          {(this.state.identified && this.state.authorized) ?
            (
              <Switch>

                <Route
                  path={routes.MAIN_PATH}
                  render={(props) => (
                    <MainMenu
                      key="route:main_menu"
                      token={this.state.token}
                      target_service={this.state.target_service}
                      isGymService={this.isGymService()}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                  exact
                />

                <Route
                  path={`${routes.HASHED_CLASS_STUDENT_CHECKIN_PATH}:authHash`}
                  render={(props) => (
                    <StudentCheckin
                      key="route:student_checkin"
                      accessedByHash={true}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.STUDENT_CHECKIN_PATH}
                  render={(props) => (
                    <StudentCheckin
                      key="route:student_checkin"
                      accessedByHash={false}
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.START_NEW_CLASS_PATH}
                  render={(props) => (
                    <StartClass
                      key="route:start_class"
                      target_service={this.state.target_service}
                      isGymService={this.isGymService()}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.CURRENT_CLASS_PATH}
                  render={(props) => (
                    <ClassBoard
                      key="route:classroom_board"
                      accessedByHash={false}
                      target_service={this.state.target_service}
                      isGymService={this.isGymService()}
                      isCardioOrFunctionalService={this.isCardioOrFunctionalService()}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.LAST_CLASS_PATH}
                  render={(props) => (
                    <LastClass
                      key="route:training_class_results"
                      useClassId={false}
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.CLASS_RESULTS_PATH}:trainingClassId`}
                  render={(props) => (
                    <LastClass
                      key="route:training_class_results"
                      useClassId={true}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.STUDENT_BASE_PATH}:studentId${routes.STUDENT_TRAINING_DAY_REPORT}:trainingDayId`}
                  render={(props) => (
                    <StudentReport
                      key="route:student_report"
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      isGymService={this.isGymService()}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.DAILY_REPORT_PATH}
                  render={(props) => (
                    <DailyReport
                      key="route:daily_report"
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      isGymService={this.isGymService()}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.EARLY_CHECKIN_PATH}
                  render={(props) => (
                    <EarlyCheckin
                      key="route:early_checkin"
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.TRAINING_DAY_CLASS_BY_HASH_PATH}:authHash`}
                  render={(props) => (
                    <ClassBoard
                      key="route:classroom_board"
                      accessedByHash={true}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={`${routes.EXPERIMENTAL_TRAINING_DAY_BY_HASH_PATH}:authHash`}
                  render={(props) => (
                    <ClassBoard
                      key="route:classroom_board"
                      accessedByHash={true}
                      basicAccess={true}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.NEW_ACCESS_REQUEST_PATH}
                  render={(props) => (
                    <Login
                      {...props}
                      key="route:request_access"
                      onAuthenticate={(data) => this.onAuthenticate(data)}
                      device_name={this.state.device_name}
                      identified={false}
                      requires_login={true}
                      should_redirect={true}
                    />
                  )}
                />

                <Route
                  path={routes.SWITCH_UNIT_PATH}
                  render={(props) => (
                    <SwitchUnit
                      key="route:switch_unit"
                      token={this.state.token}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.SWITCH_SERVICE_PATH}
                  render={(props) => (
                    <SwitchService
                      key="route:switch_service"
                      target_service={this.state.target_service}
                      allServicesEnabled={this.state.allServicesEnabled}
                      onSwitchService={(target_service) => this.onSwitchService(target_service)}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.INITIATE_DEVICE_CONNECTION_PATH}
                  render={(props) => (
                    <InitiateDeviceConnection
                      key="route:initiate_device_connection"
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.CLASSROOM_OPERATIONAL_TASKS_PAGE}
                  render={(props) => (
                    <OperationalTasks
                      key="route:operational_tasks"
                      target_service={this.state.target_service}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Redirect from="/" to={routes.MAIN_PATH} />

              </Switch>
            ):
            (
              <Switch>

                <Route
                  path={`${routes.EXPERIMENTAL_TRAINING_DAY_BY_HASH_PATH}:authHash`}
                  render={(props) => (
                    <ClassBoard
                      key="route:classroom_board"
                      accessedByHash={true}
                      basicAccess={true}
                      bluetoothDevices={this.bluetoothDevices}
                      {...props}
                    />
                  )}
                />

                <Route
                  path={routes.NEW_ACCESS_REQUEST_PATH}
                  render={(props) => (
                    <Login
                      {...props}
                      key="route:request_access"
                      onAuthenticate={(data) => this.onAuthenticate(data)}
                      device_name={this.state.device_name}
                      identified={false}
                      requires_login={true}
                      should_redirect={true}
                    />
                  )}
                />

                <Route
                  path={routes.MAIN_PATH}
                  render={(props) => (
                    <Login
                      {...props}
                      key="route:request_access"
                      onAuthenticate={(data) => this.onAuthenticate(data)}
                      device_name={this.state.device_name}
                      identified={this.state.identified}
                      requires_login={this.state.requires_login}
                    />
                  )}
                />

              </Switch>
            )
          }

        </React.Fragment>

      </BrowserRouter>
    );
  }
}

ReactDOM.render((
  <App />
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
