import React from 'react';
import { PoseGroup } from 'react-pose';
import './switch_service.scss';
import {FadeContainer} from '../utils/pose_containers';
import PreLoader from '../utils/preloader';
import * as routes from '../constants';
import DefaultMenuLayout from '../components/default_menu_layout';
import UserList, {UserItem} from '../components/user_list';
import {getModels} from '../utils/functions';
import ConfirmationWindow from '../components/confirmation_window';

class SwitchService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      loading: true,
      serviceToSelect: null,
      confirmInProgress: false,
      confirmFailed: false,
      confirmFailDescription: "",
      screenWidth: window.innerWidth,
    };
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  async componentDidMount() {
    let services = getModels(routes.TRAINING_PERIOD_SERVICES_GET_API);

    const update = {
      loading: false
    }

    services = await services;

    if(services) {
      update.services = services;
    }

    this.setState(update);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
  }

  getServiceOptions() {
    let serviceOptions = this.state.services;

    if(!this.props.allServicesEnabled) {
      serviceOptions = [this.props.target_service];
    }

    return serviceOptions.map((entry) => (
      <UserItem
        key={`service:${entry}`}
        className={`switch-service__service-option${this.props.target_service === entry ? '--selected' : ''}`}
        onClick={(event) => this.setState({serviceToSelect: this.props.target_service === entry ? null : entry})}
        hideImage={true}
        name={`${entry}${this.props.target_service === entry ? ' (SELECIONADO)' : ''}`}
      >

      </UserItem>
    ));
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmFailed) {
      if(this.state.serviceToSelect) {
        return 'Falha ao selecionar o serviço';
      }
    }
    else if(this.state.confirmInProgress) {
      if(this.state.serviceToSelect) {
        return 'Alterando serviço';
      }
    }
    else {
      if(this.state.serviceToSelect) {
        return 'Alterar serviço selecionado';
      }
    }

    return 'Não implementado';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return this.state.confirmFailDescription;
    }
    else if(this.state.serviceToSelect) {
      return 'Deseja realmente alterar o serviço vinculado com a lousa?';
    }

    return 'Não implementado';
  }

  getConfirmationWindowConfirmButtonText() {
    if(this.state.serviceToSelect) {
      return 'Alterar';
    }

    return 'Não implementado';
  }

  confirmationWindowIsVisible() {
    return this.state.serviceToSelect !== null;
  }

  resetConfirmationWindow() {
    this.setState({
      serviceToSelect: null,
      confirmFailed: false,
      confirmInProgress: false,
    });
  }

  async proceedConfirmationWindow() {
    if(this.state.serviceToSelect) {
      this.props.onSwitchService(this.state.serviceToSelect);

      this.setState({
        serviceToSelect: null
      });
    }

    return;
  }

  render() {
    return this.state.loading ? (
      <PoseGroup>
        <FadeContainer key="preloader">
          <PreLoader />
        </FadeContainer>
      </PoseGroup>
    ):
    (
      <React.Fragment>

        <DefaultMenuLayout
          showBackButton={true}
          history={this.props.history}
          bluetoothDevices={this.props.bluetoothDevices}
        >

          <div className="switch-service">

            <React.Fragment>

              <h1 className="switch-service__instructions">

                Selecione o serviço desejada:

              </h1>

              <UserList className="switch-service__service-list">

                {this.getServiceOptions()}

              </UserList>

            </React.Fragment>

          </div>

        </DefaultMenuLayout>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          confirmText={this.getConfirmationWindowConfirmButtonText()}
          cancelText={this.state.confirmFailed ? 'Ok' : 'Cancelar'}
          visible={this.confirmationWindowIsVisible()}
          onCancel={() => this.resetConfirmationWindow()}
          onConfirm={() => this.proceedConfirmationWindow()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={this.state.confirmFailed}
        />

      </React.Fragment>
    );
  }
}

export default SwitchService;
