import React from 'react';
import DefaultMenuButton from './default_menu_button';
import './training_clock.scss';


class TrainingClock extends React.Component {
  resetClock() {
    if (this.props.onResetClock) {
      this.props.onResetClock();
    }
  }

  pauseClock() {
    if (this.props.onPauseClock) {
      this.props.onPauseClock();
    }
  }

  playClock() {
    if (this.props.onPlayClock) {
      this.props.onPlayClock();
    }
  }

  getTimeTextAlignment() {
    switch (this.props.timeTextAlignment) {
      case 'center':
        return '--centered';
      default:
        return '';
    }
  }

  getClockColorSpecifier() {
    switch (this.props.color) {
      case '--red':
        return '--red';
      case '--green':
        return '--green';
      case '--yellow':
        return '--yellow';
      case '--blue':
        return '--blue';
      default:
        return '';
    }
  }

  render() {
    const mainClockStyle = {};

    if (this.props.sizeScale) {
      mainClockStyle.transform = `scale(${this.props.sizeScale}, ${this.props.sizeScale})`
    }

    return (
      <div
        className={`training_clock${this.props.className ? ` ${this.props.className}` : ''}`}
        ref={this.props.elementRef}
        style={mainClockStyle}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
      >

        {(this.props.showControlButtons && (this.props.showResetButton || this.props.showPauseButton || this.props.showPlayButton)) &&
          <div className="training_clock__controls">

            {this.props.showResetButton &&
              <DefaultMenuButton
                className="training_clock__control-button"
                onClick={() => this.resetClock()}
                text={(<i className="fas fa-undo-alt"></i>)}
              />
            }

            {this.props.showPauseButton &&
              <DefaultMenuButton
                className="training_clock__control-button"
                onClick={() => this.pauseClock()}
                text={(<i className="fas fa-pause"></i>)}
              />
            }

            {this.props.showPlayButton &&
              <DefaultMenuButton
                className="training_clock__control-button"
                onClick={() => this.playClock()}
                text={(<i className="fas fa-play"></i>)}
              />
            }

          </div>
        }

        {this.props.clockLabel &&
          <div className={`training_clock__lap-wrapper${this.getClockColorSpecifier()} ${!(this.props.showControlButtons && (this.props.showResetButton || this.props.showPauseButton || this.props.showPlayButton)) ? 'rounded' : ''}`}>

            <p
              className="training_clock__lap"
              style={this.props.clockLabelStyle}
            >

              {this.props.clockLabel}

            </p>

          </div>
        }

        {this.props.clockTime &&
          <div
            className={`training_clock__time-wrapper${this.getClockColorSpecifier()} ${!(this.props.showControlButtons && (this.props.showResetButton || this.props.showPauseButton || this.props.showPlayButton)) ? 'rounded' : ''}`}
            onClick={() => {
              if (this.props.onClickTime) {
                this.props.onClickTime();
              }
            }}
          >

            <p
              className={`training_clock__time${this.getTimeTextAlignment()} clock-timer`}
              style={this.props.clockTimeStyle}
            >

              {this.props.clockTime}

            </p>

            {this.props.showCompressButton &&
              <button
                className="training_clock__compress-button"
                onClick={() => {
                  if (this.props.onClickCompressButton) {
                    this.props.onClickCompressButton();
                  }
                }}
                style={this.props.clockTimeStyle}
              >
                <i className="fas fa-compress"></i>
              </button>
            }

          </div>
        }

      </div>
    );
  }
}

export default TrainingClock;
